import React from "react";

export default function HelpText({ children }) {
  return (
    <span
      style={{
        fontSize: 11,
        color: "rgb(143 143 143)",
        fontStyle: "italic",
      }}
    >
      {children}
    </span>
  );
}
