import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../services/api';
import DisplayFiles from '../Common/displayFiles';
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import SizedBox from '../SizedBox';
import { Formik } from 'formik';
import HelpText from '../../Common/helpText';

const Esop_API_URL = process.env.REACT_APP_ESOP_CONTROLLER;

export default function Esops({ setSelectedTab, client, showToast,isDisable }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['ESOP_Data', id],
    async () => {
      return API.get(Esop_API_URL + `GetEsopDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  const EsopData = data
    ? {
        ...data,
      }
    : {
        fileList: [],
        EsopId: '00000000-0000-0000-0000-000000000000',
        comment: '',
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <Formik
      initialValues={EsopData}
      onSubmit={async (values, actions) => {
        API.post(Esop_API_URL + 'AddEsopDetails', {
          ...values,
          taxProfileId: id,
          //fileList: fileList.concat(yearEndList),
        }).then((response) => {
          showToast({
            message: 'Foreign investments submitted successfully!',
            severity: 'success',
          });
          setSelectedTab(6);
          navigate(`/income/filing/${id}/deductions`);
        });
      }}
    >
      {(formikProps) => (
        <>
          <SizedBox height={10} width={10}></SizedBox>
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>
                  Upload your foreign brokerage/investment account details.
                  <SizedBox height={10}></SizedBox>
                  Include your foreign RSU/ESOP/ESPP accounts as well.
                  {/*<SizedBox height={10}></SizedBox>
                   Steps to download your foreign brokerage statment.
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: 10 }}>
                      1.{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${process.env.PUBLIC_URL}/documents/Process_Flow_Document_to_generate_Capital_Gains_Dividend_Statements_from_AXIS_DIRECT_Platform.pdf`}
                      >
                        Fidelity
                      </a>
                    </div>
                    <div>
                      2.{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${process.env.PUBLIC_URL}/documents/Process_Flow_Document_to_generate_Capital_Gains_Dividend_Statements_from_AXIS_DIRECT_Platform.pdf`}
                      >
                        Etrade
                      </a>
                    </div>
                  </div> */}
                </AlertTitle>
              </Alert>
            </Grid>
          </Grid>
          <SizedBox height={40} width={10}></SizedBox>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <DisplayFiles
                key="Fidelity"
                {...{
                  formikProps,
                  type: 'Fidelity',
                  buttonText:
                    ((formikProps.values.fileList || [])?.filter(
                      (t) => t.fileType === 'Fidelity'
                    )?.length || 0) === 0
                      ? 'Transaction Statement'
                      : 'Transaction Statement',
                }}
              ></DisplayFiles>
              <HelpText>
                Note : Please upload for 1st April 2022 to 31st March 2023
              </HelpText>
              <SizedBox height={30}></SizedBox>
              <DisplayFiles
                key="yearEnd"
                {...{
                  formikProps,
                  type: 'YearEnd',
                  buttonText:
                    (formikProps.values.fileList?.length || 0) === 0
                      ? 'Capital Gains Statement'
                      : 'Capital Gains Statement',
                }}
              ></DisplayFiles>
              <HelpText>
                Note : Please upload for 1st April 2022 to 31st March 2023
              </HelpText>

              <SizedBox height={30}></SizedBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="US citizen/GC holder/US resident – Upload your Form 1099 for 2022. In case of, US non-resident – Upload 1042S"
                    type="text"
                    name="comment"
                    placeholder="If you are a US citizen/GC holder/US resident, please upload your Form 1099 for 2022. If you are a US non-resident, please provide 1042S"
                    minRows={11}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.comment}
                    error={
                      formikProps.touched.comment &&
                      !!formikProps.errors.comment
                    }
                    helperText={
                      formikProps.touched.comment && formikProps.errors.comment
                    }
                    disabled={isDisable || formikProps.isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}></Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disabled={isDisable || formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
