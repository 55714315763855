import React from "react";
import { useParams } from "react-router-dom";
//import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Tabs, Tab, CircularProgress } from "@material-ui/core";
import ForeignAsset from "./Disclosures/ForeignAsset";
import DirectorShip from "./Disclosures/DirectorShip";
import Immovable from "./Disclosures/Immovable";
import OtherAsset from "./Disclosures/OtherAsset";
import UnlistedShares from "./Disclosures/UnlistedShares";
import TabPanel from "../Common/TabPanel";
import { useState } from "react";
import API from "../../services/api";
import { useQuery } from "react-query";
import { useAuth } from "../../provider/auth";
import useStyles from "../Common/styles";

// const useStyles = makeStyles((theme) => ({
//   menu: {
//     color: "#fff",
//     borderRadius: 5,
//     textDecoration: "none",
//     height: 39,
//     margin: 10,
//     textAlign: "center",
//     backgroundColor: "#8e99d5",
//     "&:hover": {
//       cursor: "pointer",
//       backgroundColor: "#c5c8d9",
//     },
//     padding: theme.spacing(1),
//   },
// }));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const JSON_API_URL = process.env.REACT_APP_JSON_READ_CONTROLLER;

export default function Disclosures({ setSelectedTab, client, showToast,isDisable }) {
  const { id } = useParams();
  let auth = useAuth();
  var classes = useStyles();

  const { data, isLoading, refetch } = useQuery(
    ["IsJsonAlreadyImported_1", id],
    async () => {
      return API.get(JSON_API_URL + `IsJsonAlreadyImported/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const isImportJsonVisible = data === false;

  const tabList = [
    { id: 1, name: "Foreign Assets", url: `/income/filing/${id}/disclosures/` },
    {
      id: 2,
      name: "Unlisted Shares",
      url: `/income/filing/${id}/disclosures/unlisted`,
    },
    {
      id: 3,
      name: "Indian Assets- Immovable Properties",
      url: `/income/filing/${id}/disclosures/immovable`,
    },
    {
      id: 4,
      name: "Indian Assets- Other",
      url: `/income/filing/${id}/disclosures/other`,
    },
    {
      id: 5,
      name: "Directorship",
      url: `/income/filing/${id}/disclosures/directorship`,
    },
  ];

  const [selectedSubTab, setSelectedSubTab] = React.useState(0);
  const [isFileLoading, setIsFileLoading] = useState(false);

  function handleChange(event, newValue) {
    console.log("event", event, newValue);
    setSelectedSubTab(newValue);
  }

  const handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;
    setIsFileLoading(true);
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = (event) => {
        console.log("event", event.target);
      };

      reader.readAsDataURL(files[0]);

      const formData = new FormData();
      formData.append("file", files[0]);

      API.post(JSON_API_URL + `ImportJson/${id}`, formData)
        .then((res) => {
          console.log("Json imported successfully", res);
          if (res.data.isSuccess) {
            showToast({
              message: "Json imported successfully",
              severity: "success",
            });
          } else {
            showToast({
              message: res.data.msg || "Error occured while importing json",
              severity: "error",
            });
          }
          setIsFileLoading(false);
          refetch();
        })
        .catch((err) => {
          showToast({
            message: "Error occured while importing json",
            severity: "error",
          });
          setIsFileLoading(false);
        });
    }
  };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            value={selectedSubTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabList &&
              tabList.map((t, index) => {
                return (
                  <Tab
                    style={{ fontSize: 12, textTransform: "none" }}
                    label={t.name}
                    {...a11yProps(index)}
                  />
                );
              })}
          </Tabs>
          {isImportJsonVisible && auth.user.role !== "Client" && (
            <div>
              <input
                id={`importJson`}
                type="file"
                accept={".json"}
                onChange={handleFileChange}
                className={classes.input}
              />

              <div>
                <div variant="contained">
                  <label
                    htmlFor={`importJson`}
                    style={{
                      padding: 10,
                      backgroundColor: "#334f64",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      fontSize: 12,
                      width: 300,
                      alignSelf: "center",
                    }}
                  >
                    Import Json
                  </label>
                </div>

                {isFileLoading && <span>Uploading...</span>}
              </div>
            </div>
          )}
        </Box>
        <TabPanel value={selectedSubTab} index={0}>
          <ForeignAsset {...{ setSelectedSubTab, client, showToast,isDisable }} />
        </TabPanel>
        <TabPanel value={selectedSubTab} index={1}>
          <UnlistedShares {...{ setSelectedSubTab, client, showToast,isDisable }} />
        </TabPanel>
        <TabPanel value={selectedSubTab} index={2}>
          <Immovable {...{ setSelectedSubTab, client, showToast,isDisable }} />
        </TabPanel>
        <TabPanel value={selectedSubTab} index={3}>
          <OtherAsset {...{ setSelectedSubTab, client, showToast,isDisable }} />
        </TabPanel>
        <TabPanel value={selectedSubTab} index={4}>
          <DirectorShip {...{ setSelectedTab, client, showToast,isDisable }} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
