import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../provider/auth';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Link, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useToastDisplay } from '../Common/useToastDisplay';

const useStyles = makeStyles((theme) => ({
  gridClassName: {
    boxShadow: '0px 2px 11px 6px #d7c6c6',
    padding: 20,
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  // other classes here
}));
export default function LoginForm({ setIsLogin, isLogin }) {
  let navigate = useNavigate();
  var classes = useStyles();
  let auth = useAuth();
  const showToast = useToastDisplay();

  let from = '/dashboard';
  return (
    <Grid>
      <div className={classes.gridClassName}>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={async (values, actions) => {
            //setError(null);
            auth.signin(values.email, values.password, (res) => {
              if (res.isSuccess === true) {
                    navigate(from, { replace: true });
              } else {
                showToast({
                  message: res?.msg || 'Error occured while login',
                  severity: 'error',
                });
              }
            });
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Please enter a valid email ID')
              .required('Email ID is a required Field'),
            password: Yup.string().required('Password is a required Field'),
          })}
        >
          {(formikProps) => (
            <Grid container size="sm">
              <div className="form-group">
                <div
                  style={{
                    fontSize: 24,
                    fontWeight: 600,
                    color: '#262626',
                    paddingBottom:20
                  }}
                >
                  Sign In to Your Account
                </div>
               
              </div>
            
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email ID"
                variant="outlined"
                size="small"
                style={{ width: '100%' }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.email}
                error={formikProps.touched.email && !!formikProps.errors.email}
                helperText={
                  formikProps.touched.email && formikProps.errors.email
                }
                disabled={formikProps.isSubmitting}
              />

              <TextField
                id="password"
                name="password"
                label="Password"
                variant="outlined"
                size="small"
                type="password"
                style={{ width: '100%', marginTop: 30 }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.password}
                error={
                  formikProps.touched.password && !!formikProps.errors.password
                }
                helperText={
                  formikProps.touched.password && formikProps.errors.password
                }
                disabled={formikProps.isSubmitting}
              />
              {/* <div style={{ width: '100%', marginTop: 10 }}>{errorDiv}</div> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  marginTop: 19,
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    marginTop: 19,
                    justifyContent: 'space-between',
                  }}
                >
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate('/forgotPassword')}
                    style={{ color: '#0090FF', textDecoration: 'none' }}
                  >
                    Forgot Password
                  </Link>
                                  {/*<div className="form-group"></div>*/}
                  <div className="form-group">
                    <Button
                      variant="contained"
                      onClick={formikProps.handleSubmit}
                      style={{ width: '100%' }}
                      disabled={formikProps.isSubmitting}
                      color="primary"
                    >
                      Login
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    marginTop: 19,
                    justifyContent: 'flex-start',
                  }}
                >
                  <div style={{ color: '#FF000', textDecoration: 'none' }}>
                    Don't have an account?&nbsp;
                  </div>

                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setIsLogin(false);
                    }}
                    style={{ color: '#0090FF', textDecoration: 'none' }}
                  >
                    Signup
                  </Link>
                </div>
              </div>
            </Grid>
          )}
        </Formik>
      </div>
    </Grid>
  );
}
