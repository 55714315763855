import React from "react";
import CurrencyTextField from "../CurrencyTextField";
const CustomNumberField = ({ field, form: { touched }, ...props }) => {
  return (
    <div>
      <CurrencyTextField {...field} {...props} />
      {touched[field.name]}
    </div>
  );
};

export default CustomNumberField;
