import React from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_LOGIN_CONTROLLER; //.concat(process.env.REACT_APP_LOGIN_CONTROLLER); //"https://localhost:44337/api/Login/";

const authProvider = {
  isAuthenticated: Boolean(localStorage.getItem("isAuthenticated")),
  token: localStorage.getItem("token"),
  user: localStorage.getItem("user"),
  signin(username, password, callback) {
    axios
      .post(API_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          authProvider.isAuthenticated = true;
          authProvider.token = response.data.token;
          authProvider.user = {
            loginId: response.data.loginId,
            userProfileId: response.data.profileId,
            role: response.data.role,
          };

          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "user",
            JSON.stringify({
              loginId: response.data.loginId,
              userProfileId: response.data.profileId,
              role: response.data.role,
            })
          );
        }
        setTimeout(callback(response.data), 100);
      });
  },
  //signUp(username, password, firstName, lastName, confirmPassword, callback) {
  //    axios
  //        .post(API_URL + "CreateAccount", {
  //            emailId:username,
  //            password,
  //            firstName,
  //            lastName,
  //            confirmPassword
  //        })
  //        .then(response => {
  //            if (response.data.token) {
  //                authProvider.isAuthenticated = true;
  //                authProvider.token = response.data.token;
  //                authProvider.id = response.data.userId;

  //                localStorage.setItem("isAuthenticated", true);
  //                localStorage.setItem("token", response.data.token);
  //                localStorage.setItem("userId", response.data.userId);

  //                setTimeout(callback, 100);
  //                //localStorage.setItem("user", JSON.stringify(response.data));
  //            }

  //        });

  //},
  setToken(token, user, callback) {
    authProvider.isAuthenticated = true;
    authProvider.token = token;
    authProvider.user = user;

    localStorage.setItem("isAuthenticated", true);
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));

    setTimeout(callback, 100);
  },
  signout(callback) {
    authProvider.isAuthenticated = false;
    authProvider.token = null;
    authProvider.user = null;
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setTimeout(callback, 100);
  },
};

let AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  let [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("user"))
  );

  let signin = (userName, password, callback) => {
    return authProvider.signin(userName, password, (res) => {
      if (res.isSuccess === true)
        setUser(JSON.parse(localStorage.getItem("user")));
      callback(res);
    });
  };
  //let signUp = (username, password, firstName, lastName, confirmPassword, callback) => {
  //    return authProvider.signUp(username, password,firstName,lastName,confirmPassword, () => {
  //        setUser(localStorage.getItem("userId"));
  //        callback();
  //    });
  //};
  let setToken = (token, user, callback) => {
    return authProvider.setToken(token, user, () => {
      setUser(JSON.parse(localStorage.getItem("user")));
      callback();
    });
  };

  let signout = (callback) => {
    return authProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, setToken, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
