// import React from "react";
import React, { useRef } from "react";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import API from "../../services/api";
import DisplayDocumentList from "./DisplayDocumentList";
import DocumentTabType from "../../Common/DocumentTabType";
import { useSelectedClient } from "../../Common/useSelectedClient";
import { useAuth } from "../../provider/auth";
import { ArrowBack } from "@material-ui/icons";
import SizedBox from "../SizedBox";
const CURRENT_FY = process.env.REACT_APP_FY;

export default function DisplayTabWiseDocuments({ fy, isClient }) {
  const tabRefs = useRef([]);
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_FILE_CONTROLLER;
  const selectedFy = fy ? fy : CURRENT_FY;
  const client = useSelectedClient();
  const navigate = useNavigate();
  const auth = useAuth();

  const { data, isLoading, refetch } = useQuery(
    ["DOCUMENT_LIST", id],
    async () => {
      return API.get(API_URL + `DocumentList/${id}/${selectedFy}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );

  return (
    <Grid container spacing={1}>
      <Grid item md={1}></Grid>
      <Grid item xs={11} sm={11} md={10}>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              onClick={(e) => navigate("/income")}
            >
              <ArrowBack />
            </IconButton>
            <h2>Financial Year {fy} </h2>
            <SizedBox width={10}></SizedBox>
            {auth.user.role !== "Client" && client && (
              <h2 style={{ textAlign: "center" }}>
                {client.firstName} {client.lastName}
              </h2>
            )}
          </div>
          {data && data.length > 0 && (
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => {
                navigate(`/income/filing/${data[0].taxProfileId}/summary`);
              }}
            >
              View Tax Summary
            </Button>
          )}
        </div>
        <Box sx={{ flexGrow: 1, marginBottom: 20 }}>
          <Box sx={{ marginTop: 20 }}>
            <SizedBox height={20}></SizedBox>
            <Typography
              variant="h5"
              align="center"
              color="black"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Document Management Window
            </Typography>

            <Box sx={{ pt: 2 }}>
              <div
                style={{ display: "flex", float: "right", paddingRight: 20 }}
              >
                <Button
                  style={{ border: "1px solid black", fontWeight: "bold" }}
                >
                  {fy}
                </Button>
              </div>

              <Tabs
                value={value}
                onChange={handleChange}
                centered
                sx={{ width: "100%", flexGrow: 1 }}
                TabIndicatorProps={{ style: { background: "#000000" } }} // Highlight color
              >
                <Tab
                  label="Uploaded by you"
                  ref={(ref) => (tabRefs.current[0] = ref)}
                  style={{
                    fontWeight: "bold",
                    backgroundColor: value === 0 ? "skyBlue" : "lightgrey",
                    border: "1px solid black",
                  }}
                  sx={{ flexGrow: 1 }}
                />
                <Tab
                  label="Uploaded by BBC"
                  ref={(ref) => (tabRefs.current[1] = ref)}
                  style={{
                    fontWeight: "bold",
                    backgroundColor: value === 1 ? "skyBlue" : "lightgrey",
                    border: "1px solid black",
                  }}
                  sx={{ flexGrow: 1 }}
                />
                {!isClient && (
                  <Tab
                    label="Workings"
                    ref={(ref) => (tabRefs.current[2] = ref)}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: value === 2 ? "skyBlue" : "lightgrey",
                      border: "1px solid black",
                    }}
                    sx={{ flexGrow: 1 }}
                  />
                )}
                {!isClient && (
                  <Tab
                    label="Not in Use"
                    ref={(ref) => (tabRefs.current[3] = ref)}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: value === 3 ? "skyBlue" : "lightgrey",
                      border: "1px solid black",
                    }}
                    sx={{ flexGrow: 1 }}
                  />
                )}
              </Tabs>
              <Box sx={{ pl: 2, pr: 2 }}>
                {value === 0 && (
                  <DisplayDocumentList
                    {...{
                      data,
                      documentTab: DocumentTabType.UploadedByClient,
                      fy,
                      isClient,
                      refetch,
                    }}
                  ></DisplayDocumentList>
                )}
                {value === 1 && (
                  <DisplayDocumentList
                    {...{
                      data,
                      documentTab: DocumentTabType.UploadedByBBC,
                      fy,
                      isClient,
                      refetch,
                    }}
                  ></DisplayDocumentList>
                )}
                {value === 2 && !isClient && (
                  <DisplayDocumentList
                    {...{
                      data,
                      documentTab: DocumentTabType.Working,
                      fy,
                      isClient,
                      refetch,
                    }}
                  ></DisplayDocumentList>
                )}
                {value === 3 && !isClient && (
                  <DisplayDocumentList
                    {...{
                      data,
                      documentTab: DocumentTabType.NotInUse,
                      fy,
                      isClient,
                      refetch,
                    }}
                  ></DisplayDocumentList>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
}
