import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Button,
  Divider,
  TextField,
  Grid,
  CircularProgress,
  Card,
  Typography,
  MenuItem,
  Badge,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useFormik, Field, Form, FieldArray, Formik } from 'formik';
import useStyles from './styles';
import SizedBox from './../SizedBox';
import CurrencyTextField from '../CurrencyTextField';
import { IconButton } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import API from '../../services/api';
import { getInteger } from '../../Common/helperMethods';

const RENTAL_API_URL = process.env.REACT_APP_RENTAL_CONTROLLER;

//const useStyles = makeStyles((theme) => ({
//    deleteButton: {
//        padding: 6
//    },
//}));
const PROPERTY_TYPE = ['Self Occupied', 'Let Out'];
function Rental({ activeQuarter, setSelectedTab, client, showToast,isOtherTabVisible }) {
  var classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ['Rental_Data', id],
    async () => {
      return API.get(RENTAL_API_URL + `GetRentalDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const CustomInputComponent = ({ field, form: { touched }, ...props }) => {
    return (
      <div>
        <CurrencyTextField {...field} {...props} />
        {touched[field.name]}
      </div>
    );
  };
  const CustomTextComponent = ({ field, form: { touched }, ...props }) => (
    <div>
      <TextField {...field} {...props} />
      {touched[field.name]}
    </div>
  );

  const CustomDropDownField = ({ field, form: { touched }, ...props }) => (
    <div>
      <TextField select {...field} {...props}>
        <MenuItem value="">
          <em>Select</em>
        </MenuItem>
        {props.menuItems.map((s) => {
          return (
            <MenuItem key={s.code} value={s.code}>
              <em>{s.name}</em>
            </MenuItem>
          );
        })}
      </TextField>
      {touched[field.name]}
    </div>
  );

  function getTaxableIncome(
    annualRent,
    propertyTax,
    ownership,
    housingLoanInt
  ) {
    const nav =
      (getInteger(annualRent) - getInteger(propertyTax)) *
      (getInteger(ownership) / 100);
    const taxableValue = Math.round(
      nav - (nav < 0 ? 0 : nav * 0.3) - getInteger(housingLoanInt)
    );
    return taxableValue;
  }

  const initialValues = {
    rentals:
      data && data.length > 0
        ? data
        : [
            {
              propertyName: '',
              annualRent: 0,
              propertyTax: 0,
              propertyType: 0,
              housingLoanInt: 0,
              tdsOnRent: 0,
              propertyNumber: 1,
              shareInAnnualRent: 0,
              standardDeduction: 0,
              taxableRentalIncome: 0,
              ownership: 100,
              rentalPropertyId: '00000000-0000-0000-0000-000000000000',
            },
          ],
  };

  const isDisabled = client?.isDisabled;

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const mappedData = values.rentals.map((t, index) => {
            let nav = getInteger(
              (getInteger(t.annualRent) - getInteger(t.propertyTax)) *
                (getInteger(t.ownership) / 100)
            );
            return {
              annualRent: getInteger(t.annualRent),
              propertyTax: getInteger(t.propertyTax),
              housingLoanInt: getInteger(t.housingLoanInt),
              tdsOnRent: getInteger(t.tdsOnRent),
              ownership: getInteger(t.ownership),
              shareInAnnualRent: nav,
              standardDeduction: Math.round(nav < 0 ? 0 : nav * 0.3),
              taxableRentalIncome: Math.round(
                nav - (nav < 0 ? 0 : nav * 0.3) - getInteger(t.housingLoanInt)
              ),
              propertyName: t.propertyName,
              taxQtrProfileId: id,
              rentalPropertyId: t.rentalPropertyId,
              propertyNumber: index + 1,
              id: index,
              comment: '',
              propertyType: getInteger(t.propertyType),
            };
          });
          if (mappedData.filter((s) => s.propertyName === 1).length > 2) {
            showToast({
              message:
                'You can not add more than 2 self occupied house property',
              severity: 'error',
            });
            return;
          }
          if (mappedData.length > 25) {
            showToast({
              message: 'You can add max 25 house property',
              severity: 'error',
            });
            return;
          }

          const input = { qtrId: id, rentalDetailsVM: mappedData };
          API.post(RENTAL_API_URL + `AddRentalDetails`, {
            ...input,
          })
            .then((response) => {
              showToast({
                message: 'Rental submitted successfully!',
                severity: 'success',
              });
              setSelectedTab(4);
              if (isOtherTabVisible === true)
              navigate(`/advance-tax/quarter/${id}/cg`);
            else navigate(`/advance-tax/quarter/${id}/others`);
            })
            .catch((err) => console.log('err', err));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="rentals">
              {({ insert, remove, push }) => (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                  }}
                >
                  {values.rentals.length > 0 &&
                    values.rentals.map((rental, index) => (
                      <Grid key={index}>
                        <Badge
                          key={index}
                          badgeContent={index + 1}
                          style={{ top: '14px' }}
                          color="primary"
                        ></Badge>
                        <Card
                          style={{ marginTop: '5px', marginBottom: '5px' }}
                          variant="outlined"
                        >
                          <Grid
                            className={classes.Row}
                            style={{
                              padding: 15,
                              borderColor: 'red',
                              borderWidth: 1,
                            }}
                            key={index}
                          >
                            <Grid container spacing={3} columns={11}>
                              <Grid item xs={12}>
                                <Grid
                                  columns={12}
                                  spacing={3}
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item xs={11} md={10} lg={8}>
                                    <Grid
                                      columns={12}
                                      spacing={1}
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Grid item xs={11} md={6} lg={6}>
                                        <Field
                                          id={`rentals.${index}.propertyName`}
                                          variant="filled"
                                          label="Property Name"
                                          component={CustomTextComponent}
                                          name={`rentals.${index}.propertyName`}
                                          type="text"
                                          fullWidth
                                          size="small"
                                          disabled={isDisabled}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `rentals.${index}.propertyName`,
                                              e.target.value
                                            );
                                          }}
                                          value={rental.propertyName}
                                        />
                                      </Grid>
                                      <Grid item xs={11} md={6} lg={6}>
                                        <Field
                                          variant="outlined"
                                          label="Property Type"
                                          component={CustomDropDownField}
                                          name={`rentals.${index}.propertyType`}
                                          type="text"
                                          fullWidth
                                          menuItems={PROPERTY_TYPE.map(
                                            (s, index) => {
                                              return {
                                                code: index + 1,
                                                name: s,
                                              };
                                            }
                                          )}
                                          size="small"
                                          onChange={(e) => {
                                            setFieldValue(
                                              `rentals.${index}.propertyType`,
                                              e.target.value
                                            );
                                            setFieldValue(
                                              `rentals.${index}.annualRent`,
                                              0
                                            );
                                            setFieldValue(
                                              `rentals.${index}.propertyTax`,
                                              0
                                            );
                                            setFieldValue(
                                              `rentals.${index}.tdsOnRent`,
                                              0
                                            );
                                          }}
                                          value={rental.propertyType}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={1}
                                    md={2}
                                    style={{ textAlign: 'end' }}
                                  >
                                    <IconButton
                                      aria-haspopup="true"
                                      color="inherit"
                                      disabled={isDisabled}
                                      aria-controls="profile-menu"
                                      style={{ padding: 6 }}
                                      onClick={() => remove(index)}
                                    >
                                      <CloseOutlined />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={6} md={3} lg={2}>
                                <Field
                                  variant="outlined"
                                  label="Annual Rent"
                                  disabled={
                                    isDisabled || rental.propertyType === 1
                                  }
                                  component={CustomInputComponent}
                                  name={`rentals.${index}.annualRent`}
                                  maximumValue="100000000"
                                  type="text"
                                  size="small"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.annualRent`,
                                      e.target.value
                                    );
                                  }}
                                  value={getInteger(rental.annualRent)}
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <Field
                                  component={CustomInputComponent}
                                  size="small"
                                  variant="outlined"
                                  label="Property Tax"
                                  disabled={
                                    isDisabled || rental.propertyType === 1
                                  }
                                  maximumValue="10000000"
                                  name={`rentals.${index}.propertyTax`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.propertyTax`,
                                      e.target.value
                                    );
                                  }}
                                  value={getInteger(rental.propertyTax)}
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <Field
                                  component={CustomInputComponent}
                                  size="small"
                                  variant="outlined"
                                  disabled={isDisabled}
                                  label="Ownership %"
                                  maximumValue="100"
                                  minimumValue="0"
                                  currencySymbol=""
                                  name={`rentals.${index}.ownership`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.ownership`,
                                      e.target.value
                                    );
                                  }}
                                  value={getInteger(rental.ownership)}
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <Field
                                  component={CustomInputComponent}
                                  size="small"
                                  variant="outlined"
                                  label="NAV"
                                  minimumValue="-999999999"
                                  disabled={true}
                                  value={
                                    (getInteger(rental.annualRent) -
                                      getInteger(rental.propertyTax)) *
                                    (getInteger(rental.ownership) / 100)
                                  }
                                  name={`rentals.${index}.shareInAnnualRent`}
                                  placeholder=""
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <Field
                                  component={CustomInputComponent}
                                  size="small"
                                  variant="outlined"
                                  label="Std. Ded."
                                  disabled={true}
                                  value={
                                    (getInteger(rental.annualRent) -
                                      getInteger(rental.propertyTax)) *
                                      (getInteger(rental.ownership) / 100) <
                                    0
                                      ? 0
                                      : Math.round(
                                          (getInteger(rental.annualRent) -
                                            getInteger(rental.propertyTax)) *
                                            (getInteger(rental.ownership) /
                                              100) *
                                            0.3
                                        )
                                  }
                                  name={`rentals.${index}.standardDeduction`}
                                  placeholder=""
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <Field
                                  component={CustomInputComponent}
                                  size="small"
                                  variant="outlined"
                                  disabled={isDisabled}
                                  maximumValue="100000000"
                                  label="Housing Loan Interest"
                                  name={`rentals.${index}.housingLoanInt`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.housingLoanInt`,
                                      e.target.value
                                    );
                                  }}
                                  value={getInteger(rental.housingLoanInt)}
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <Field
                                  component={CustomInputComponent}
                                  size="small"
                                  variant="outlined"
                                  disabled={true}
                                  maximumValue="100000000"
                                  minimumValue="-100000000"
                                  label="Taxable rental income"
                                  name={`rentals.${index}.taxableRentalIncome`}
                                  placeholder=""
                                  type="text"
                                  value={getTaxableIncome(
                                    rental.annualRent,
                                    rental.propertyTax,
                                    rental.ownership,
                                    rental.housingLoanInt
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} md={3} lg={2}>
                                <Field
                                  component={CustomInputComponent}
                                  size="small"
                                  variant="outlined"
                                  maximumValue="100000000"
                                  label="TDS On Rent"
                                  disabled={
                                    isDisabled || rental.propertyType === 1
                                  }
                                  name={`rentals.${index}.tdsOnRent`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.tdsOnRent`,
                                      e.target.value
                                    );
                                  }}
                                  value={getInteger(rental.tdsOnRent)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))}
                  <Card
                    style={{
                      marginTop: '5px',
                      marginBottom: '5px',
                    }}
                    variant="outlined"
                    spacing={1}
                  >
                    <Table size="small">
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell>
                            <b>Self Occupied - Total Taxable Rental Income</b>
                          </TableCell>
                          <TableCell align="right">
                            {Math.max(
                              values.rentals
                                .filter((s) => s.propertyType === 1)
                                .reduce((acc, b) => {
                                  return (
                                    acc +
                                    getTaxableIncome(
                                      b.annualRent,
                                      b.propertyTax,
                                      b.ownership,
                                      b.housingLoanInt
                                    )
                                  );
                                }, 0),
                              -200000
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell>
                            <b>Letout - Total Taxable Rental Income</b>
                          </TableCell>
                          <TableCell align="right">
                            {values.rentals
                              .filter((s) => s.propertyType === 2)
                              .reduce((acc, b) => {
                                return (
                                  acc +
                                  getTaxableIncome(
                                    b.annualRent,
                                    b.propertyTax,
                                    b.ownership,
                                    b.housingLoanInt
                                  )
                                );
                              }, 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow key={3}>
                          <TableCell>
                            <b>Total TDS on Rent</b>
                          </TableCell>
                          <TableCell align="right">
                            {values.rentals.reduce((acc, b) => {
                              return acc + getInteger(b.tdsOnRent);
                            }, 0)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                  <SizedBox height={30} width={20}></SizedBox>
                  <Grid
                    container
                    spacing={1}
                    columns={12}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginBottom: '30px' }}
                  >
                    <Grid item xs={6} sm={6} md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={isDisabled}
                        onClick={() => {
                          if (values.rentals.length > 25) {
                            showToast({
                              message: 'You can add max 25 house property',
                              severity: 'error',
                            });
                            return;
                          }
                          push({
                            annualRent: 0,
                            propertyTax: 0,
                            ownership: 100,
                            shareInAnnualRent: 0,
                            housingLoanInt: 0,
                            tdsOnRent: 0,
                            propertyName: '',
                            standardDeduction: 0,
                            taxableRentalIncome: 0,
                          });
                        }}
                      >
                        Add Property
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      style={{ textAlign: 'end' }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={values.rentals.length === 0 || isDisabled}
                        color="primary"
                      >
                        Save and Next
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>

      {/* </form> */}
    </div>
  );
}
export default Rental;
