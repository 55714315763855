import { Button, Card, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { CircularProgress } from "@material-ui/core";

import API from "../../services/api";
import SizedBox from "../SizedBox";
import { useToastDisplay } from "../../Common/useToastDisplay";

const Message_API_URL = process.env.REACT_APP_MESSAGE_CONTROLLER;

export default function Messages({isDisable}) {
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const showToast = useToastDisplay();
  // const messageList = [
  //   { message: "Hello", messageBy: "Advisor", messageDate: "25-06-2012" },
  //   { message: "Hi Amit", messageBy: "Client", messageDate: "25-06-2012" },
  //   { message: "How are you", messageBy: "Advisor", messageDate: "25-06-2012" },
  //   { message: "I am fine", messageBy: "Client", messageDate: "25-06-2012" },
  //   { message: "Thanks", messageBy: "Advisor", messageDate: "25-06-2012" },
  //   { message: "Bye", messageBy: "Advisor", messageDate: "25-06-2012" },
  //   { message: "Bye", messageBy: "Client", messageDate: "25-06-2012" },
  // ];
  const { data, isLoading, refetch } = useQuery(
    ["ALL_MESSAGE", id],
    async () => {
      return API.get(Message_API_URL + `GetMessageDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  console.log("data", data);
  const messageList = data ? data : [];

  const addComment = () => {
    if(comment && comment!=""){
      API.post(Message_API_URL + `AddMessageDetails`, {
        taxProfileId: id,
        messageId: "00000000-0000-0000-0000-000000000000",
        comment,
      }).then((response) => {
        showToast({
          message: "Message submitted successfully!",
          severity: "success",
        });
        refetch();
      }).catch((error)=>console.log("error",error));
    }
    else {
      showToast({
        message: "Please add comment!",
        severity: "error",
      });
    }
  };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ width: "80%", maxHeight: 300, overflow: "auto" }}>
        {messageList.map((s) => {
          return (
            <div>
              <Card
                style={{
                  width: "60%",
                  heigth: 30,
                  margin: 10,
                  paddingTop: 15,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingBottom: 5,
                  float: s.isClient? "left" : "right",
                  backgroundColor:
                    s.isClient  ? "lightgray" : "azure",
                }}
              >
                <div>{s.comment} </div>
                <div
                  style={{ float: "right", fontWeight: "100", fontSize: 10 }}
                >
                 {s.displayCreatedDate}
                </div>
              </Card>
            </div>
          );
        })}
      </div>
      <SizedBox height={20}></SizedBox>
      <div>
        <TextField
          id="comment"
          variant="outlined"
          multiline={true}
          label="Enter your comments/notes here"
          type="text"
          name="comment"
          style={{ width: "80%" }}
          minRows={5}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          disabled={isDisable}
          fullWidth
        />
        <SizedBox height={20}></SizedBox>
        <Button
          variant="contained"
          //disabled={formikProps.isSubmitting}
          color="primary"
          onClick={() => {
            addComment();
          }}
          disabled={isDisable}
        >
          Submit Comment
        </Button>
      </div>
    </div>
  );
}
