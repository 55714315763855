import { AppContext } from '../App';
import { useContext } from 'react';

//{
//    severity: 'info' | 'error' | 'warning' | 'success';
//    message: string;
//}

export function useToastDisplay() {
    const { setShowToast } = useContext(AppContext);

    function showToast (toast)
    {
        setShowToast(toast);
    };

    return showToast;
}
