import React, { useState } from "react";
import { useQuery } from "react-query";
import { Routes, Route, useParams } from "react-router-dom";
import QuarterData from "../QuarterData";
import API from "../../services/api";
import SizedBox from "../../components/SizedBox";

import { useAuth } from "../../provider/auth";

import { Button, CircularProgress, Grid, Modal } from "@material-ui/core";
import DisplayClient from "../../components/Dashboard/DisplayClient";
import LoadAdvanceTaxClientForAdvisor from "../../components/Dashboard/LoadAdvanceTaxClientForAdvisor";
import { useSelectedFY } from "../../Common/useSelectedClient";
import { Alert, AlertTitle } from "@material-ui/lab";
import AddFamilyProfile from "../../components/Profile/AddFamilyProfile";
import ServiceType from "../../Common/ServiceType";

const API_URL = process.env.REACT_APP_USERPROFILE_CONTROLLER;
const SETTING_URL = process.env.REACT_APP_ADMINSETTINGS_CONTROLLER;
const CURRENT_AY = process.env.REACT_APP_AY;

export default function AdvanceTax() {
  let auth = useAuth();
  const selectedFY = useSelectedFY();
  const [openAddMember, setAddMemberOpen] = useState(false);

  const { data } = useQuery(
    ["FETCH_ACTIVE_QUARTER", selectedFY ?? CURRENT_AY],
    async () => {
      return API.get(
        SETTING_URL + `GetQtrMasterDetails?fy=${selectedFY ?? CURRENT_AY}`
      )
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  const activeQuarter = data
    ? data.isQtr1Active
      ? 1
      : data.isQtr2Active
      ? 2
      : data.isQtr3Active
      ? 3
      : data.isQtr4Active
      ? 4
      : -1
    : -1;

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            auth.user.role === "Client" ? (
              <UserList
                {...{
                  activeQuarter,
                  fy: data?.fy,
                  openAddMember,
                  setAddMemberOpen,
                }}
              />
            ) : (
              <LoadAdvanceTaxClientForAdvisor
                {...{ activeQuarter, fy: data?.fy, role: auth.user.role }}
              />
            )
          }
        />
        <Route
          path="/quarter/:id/*"
          element={<QuarterData {...{ activeQuarter, fy: data?.fy }} />}
        />
      </Routes>
    </div>
  );
}

function UserList({ activeQuarter, fy, openAddMember, setAddMemberOpen }) {
  const { id } = useParams();

  const { data, isLoading, refetch } = useQuery(
    "ALL_USER_ADVANCE_PROFILE",
    async () => {
      return API.get(
        API_URL + `GetAllAdvanceUsers/${"00000000-0000-0000-0000-000000000000"}`
      )
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  const selfRecord = data ? data.find((t) => t.accountType == "1") : [];
  const firstName = selfRecord && selfRecord?.firstName;

  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid item md={1}></Grid>
      <Grid item xs={12} sm={12} md={10}>
        <Grid style={{ marginBottom: "30px" }}>
          <h1>Welcome {firstName},</h1>
          {fy !== CURRENT_AY && (
            <Alert severity="warning">
              <AlertTitle>
                You are reviewing prior year's details. Please select FY{" "}
                {CURRENT_AY} to provide the information for this year's advance
                tax.
              </AlertTitle>
            </Alert>
          )}
          {fy === CURRENT_AY && (
            <span style={{ marginTop: 2 }}>
              Please select Q{activeQuarter} below to calculate your advance tax
              for the{" "}
              {activeQuarter === 1
                ? "first"
                : activeQuarter === 2
                ? "second"
                : activeQuarter === 3
                ? "third"
                : activeQuarter === 1
                ? "fourth"
                : ""}{" "}
              quarter of the year.
            </span>
          )}
        </Grid>
        <hr style={{ color: "#eee" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Current FY: {fy}</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAddMemberOpen(true);
            }}
          >
            Add Family Member
          </Button>
        </div>

        <div className="row" style={{ marginTop: 16, border: 1 }}>
          {data &&
            data
              .filter((s) => s.fy === fy)
              .map((t, index) => {
                return (
                  <Grid key={index} container>
                    <SizedBox height={10} width={10}></SizedBox>
                    {
                      <DisplayClient
                        {...{
                          index,
                          t,
                          activeQuarter,
                          fy,
                        }}
                      ></DisplayClient>
                    }
                  </Grid>
                );
              })}
        </div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={openAddMember}
          onClose={() => {
            setAddMemberOpen(false);
          }}
        >
          <AddFamilyProfile
            {...{
              row: { userProfileId: id, firstName: "", lastName: "" },
              service: ServiceType.ADVANCETAX,
              setAddMemberOpen,
              refetch,
            }}
          ></AddFamilyProfile>
        </Modal>
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
}
