import React, { useState, useEffect } from "react";
import { Button, TextField, Modal, Select, MenuItem } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import API from "../services/api";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

const AdvisorList = () => {
  const [advisorData, setAdvisorData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [newAdvisorData, setNewAdvisorData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    advisorType: "",
    emailId: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const showToast = (message) => {
    console.log(message);
  };

  const QTR_API_URL = process.env.REACT_APP_ADVISOR_CONTROLLER;

  // useEffect(() => {
  //   const fetchAdvisorData = async () => {
  //     try {
  //       const response = await API.get(QTR_API_URL + `GetAllAdvisorDetails`);
  //       setAdvisorData(response.data);
  //     } catch (error) {
  //       console.error("Error fetching advisor data:", error);
  //     }
  //   };

  //   fetchAdvisorData();
  // }, []);

  // const addAdvisor = async (values, resetForm) => {
  //   try {
  //     const response = await API.post(QTR_API_URL + `AddAdvisor`, values);
  //     const newAdvisor = response.data;
  //     setAdvisorData((prevData) => [...prevData, newAdvisor]);
  //     handleAddClose();
  //     resetForm();
  //     showToast("Advisor added successfully!");
  //   } catch (error) {
  //     console.error("Error adding advisor:", error);
  //     // showToast("Failed to add advisor.");
  //   }
  // };

  const addAdvisor = async (values, resetForm) => {
    try {
      const response = await API.post(QTR_API_URL + `AddAdvisor`, {FirstName: "test", LastName: "test", Gender: 1, AdvisorType: 1, EmailId: "test@test.com"});
      const newAdvisor = response.data;
      setAdvisorData((prevData) => [...prevData, newAdvisor]);
      handleAddClose();
      resetForm();
      showToast("Advisor added successfully!");
    } catch (error) {
      console.error("Error adding advisor:", error);
      showToast("Failed to add advisor.");
    }
  };

  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [updatedAdvisorData, setUpdatedAdvisorData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    advisorType: "",
    emailId: "",
  });

  const handleUpdateOpen = (advisor) => {
    setSelectedAdvisor(advisor);
    setUpdatedAdvisorData(advisor);
    setOpenUpdate(true);
  };

  const handleUpdateClose = () => {
    setSelectedAdvisor(null);
    setUpdatedAdvisorData({
      firstName: "",
      lastName: "",
      gender: "",
      advisorType: "",
      emailId: "",
    });
    setOpenUpdate(false);
  };

  const updateAdvisor = async (values) => {
    try {
      const response = await API.post(
        QTR_API_URL + `UpdateAdvisor/${selectedAdvisor.id}`,
        values
      );
      const updatedAdvisor = response.data;
      setAdvisorData((prevData) =>
        prevData.map((advisor) =>
          advisor.id === updatedAdvisor.id ? updatedAdvisor : advisor
        )
      );
      handleUpdateClose();
      showToast("Advisor updated successfully!");
    } catch (error) {
      console.error("Error updating advisor:", error);
      showToast("Failed to update advisor.");
    }
  };

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <div
          style={{
            backgroundColor: "lightgray",
            width: "50%",
            height: 50,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p>Advisor List</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 20,
          marginBottom: 20,
        }}
      >
        <Button variant="contained" color="primary" onClick={handleAddOpen}>
          Add Advisor
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#0059A9" }}>
              <TableCell style={{ color: "#ffffff" }}>Advisor Id</TableCell>
              <TableCell style={{ color: "#ffffff" }} align="left">
                First Name
              </TableCell>
              <TableCell style={{ color: "#ffffff" }} align="left">
                Last Name
              </TableCell>
              <TableCell style={{ color: "#ffffff" }} align="left">
                Gender
              </TableCell>
              <TableCell style={{ color: "#ffffff" }} align="left">
                Advisor Type
              </TableCell>
              <TableCell style={{ color: "#ffffff" }} align="left">
                EmailId
              </TableCell>
              <TableCell style={{ color: "#ffffff" }} align="left">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {advisorData.map((row, index) => (
              <TableRow
                height={50}
                key={row?.id}
                style={{ marginTop: 10 }}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.firstName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.lastName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.gender}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.advisorType}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.emailId}
                </TableCell>

                <TableCell component="th" scope="row">
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Button
                      style={{ backgroundColor: "#EE9A4D" }}
                      onClick={handleOpen}
                      variant="contained"
                    >
                      Change Password
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdateOpen(row)}
                    >
                      Update
                    </Button>
                  </div>

                  <Modal
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <Formik
                      initialValues={{
                        emailId: row?.emailId,
                        password: "",
                        ConfirmPassword: "",
                      }}
                      onSubmit={async (values, actions) => {
                        try {
                          await axios.post(
                            "/api/advisors/ChangeAdvisorPassword",
                            values
                          );
                          showToast({
                            message:
                              "Your password has been reset successfully!",
                            severity: "success",
                          });
                          setOpen(false);
                        } catch (error) {
                          console.error("Error changing password:", error);
                        }
                      }}
                      validationSchema={Yup.object({
                        password: Yup.string()
                          .required("Password is a required Field")
                          .min(6, "Minimum 6 Characters required"),
                        ConfirmPassword: Yup.string()
                          .required("Confirm Password is a required Field")
                          .oneOf(
                            [Yup.ref("password"), null],
                            "Passwords must match"
                          ),
                      })}
                    >
                      {(formikProps) => (
                        <div
                          style={{
                            height: 450,
                            width: 400,
                            backgroundColor: "#fff",
                            elevation: 0.5,
                            borderRadius: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 20,
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#0059A9",
                                width: "50%",
                                height: 50,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p style={{ color: "#fff" }}>
                                Advisor Password Reset
                              </p>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 10,
                            }}
                          >
                            <div>
                              <p>
                                Name: {row?.firstName} {row?.lastName}
                              </p>
                              <p>EmailId: {row?.emailId}</p>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: 30,
                              marginRight: 30,
                            }}
                          >
                            <p>Password*</p>
                            <TextField
                              id="password"
                              name="password"
                              label="Password"
                              variant="outlined"
                              size="small"
                              type="password"
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              value={formikProps.values.password}
                              error={
                                formikProps.touched.password &&
                                !!formikProps.errors.password
                              }
                              helperText={
                                formikProps.touched.password &&
                                formikProps.errors.password
                              }
                              disabled={formikProps.isSubmitting}
                            />
                            <p>Confirm Password *</p>
                            <TextField
                              id="ConfirmPassword"
                              name="ConfirmPassword"
                              label="Confirm Password"
                              variant="outlined"
                              size="small"
                              type="password"
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              value={formikProps.values.ConfirmPassword}
                              error={
                                formikProps.touched.ConfirmPassword &&
                                !!formikProps.errors.ConfirmPassword
                              }
                              helperText={
                                formikProps.touched.ConfirmPassword &&
                                formikProps.errors.ConfirmPassword
                              }
                              disabled={formikProps.isSubmitting}
                            />
                          </div>

                          <div
                            style={{
                              marginTop: 20,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              style={{ backgroundColor: "#EE9A4D" }}
                              variant="contained"
                              onClick={formikProps.handleSubmit}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      )}
                    </Formik>
                  </Modal>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openAdd} onClose={handleAddClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Paper style={{ padding: 20 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <h2>Add Advisor</h2>{" "}
            </div>
            <Formik
              initialValues={newAdvisorData}
              validationSchema={Yup.object({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                gender: Yup.string()
                  .required("Required")
                  .oneOf(["1", "2", "3"], "Please select a gender"),
                advisorType: Yup.string()
                  .required("Required")
                  .oneOf(["1", "2"], "Please select an advisor type"),
                emailId: Yup.string()
                  .email("Invalid email")
                  .required("Required"),
              })}
              onSubmit={(values, { resetForm }) => {
                addAdvisor(values, resetForm);
              }}
            >
              {(formikProps) => (
                <form onSubmit={formikProps.handleSubmit}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formikProps.values.firstName}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.firstName &&
                      formikProps.errors.firstName
                    }
                    helperText={
                      formikProps.touched.firstName &&
                      formikProps.errors.firstName
                    }
                  />

                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formikProps.values.lastName}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.lastName &&
                      formikProps.errors.lastName
                    }
                    helperText={
                      formikProps.touched.lastName &&
                      formikProps.errors.lastName
                    }
                    style={{ marginTop: 10 }}
                  />

                  <Select
                    id="gender"
                    name="gender"
                    variant="outlined"
                    size="small"
                    placeholder="gender"
                    fullWidth
                    value={formikProps.values.gender}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.gender && formikProps.errors.gender
                    }
                    helperText={
                      formikProps.touched.gender && formikProps.errors.gender
                    }
                    style={{ marginTop: 10 }}
                  >
                    <MenuItem value="0">Select Gender</MenuItem>
                    <MenuItem value="1">Male</MenuItem>
                    <MenuItem value="2">Female</MenuItem>
                    <MenuItem value="3">Other</MenuItem>
                  </Select>

                  <Select
                    id="advisorType"
                    name="advisorType"
                    variant="outlined"
                    size="small"
                    placeholder="advisorType"
                    fullWidth
                    value={formikProps.values.advisorType}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.advisorType &&
                      formikProps.errors.advisorType
                    }
                    helperText={
                      formikProps.touched.advisorType &&
                      formikProps.errors.advisorType
                    }
                    style={{ marginTop: 10 }}
                  >
                    <MenuItem value="0">Select Advisor Type</MenuItem>
                    <MenuItem value="1">WealthPlanner</MenuItem>
                    <MenuItem value="2">TaxAdvisor</MenuItem>
                  </Select>

                  <TextField
                    id="emailId"
                    name="emailId"
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formikProps.values.emailId}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.emailId && formikProps.errors.emailId
                    }
                    helperText={
                      formikProps.touched.emailId && formikProps.errors.emailId
                    }
                    style={{ marginTop: 10 }}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 10 }}
                    >
                      Add
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </Paper>
        </div>
      </Modal>

      <Modal open={openUpdate} onClose={handleUpdateClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Paper style={{ padding: 20 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <h2>Update Advisor</h2>{" "}
            </div>
            <Formik
              initialValues={updatedAdvisorData}
              validationSchema={Yup.object({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                gender: Yup.string().required("Required"),
                advisorType: Yup.string().required("Required"),
                emailId: Yup.string()
                  .email("Invalid email")
                  .required("Required"),
              })}
              onSubmit={(values) => {
                updateAdvisor(values);
              }}
            >
              {(formikProps) => (
                <form onSubmit={formikProps.handleSubmit}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formikProps.values.firstName}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.firstName &&
                      formikProps.errors.firstName
                    }
                    helperText={
                      formikProps.touched.firstName &&
                      formikProps.errors.firstName
                    }
                    style={{ marginTop: 10 }}
                  />

                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formikProps.values.lastName}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.lastName &&
                      formikProps.errors.lastName
                    }
                    helperText={
                      formikProps.touched.lastName &&
                      formikProps.errors.lastName
                    }
                    style={{ marginTop: 10 }}
                  />

                  <Select
                    id="gender"
                    name="gender"
                    variant="outlined"
                    size="small"
                    placeholder="gender"
                    fullWidth
                    value={formikProps.values.gender}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.gender && formikProps.errors.gender
                    }
                    helperText={
                      formikProps.touched.gender && formikProps.errors.gender
                    }
                    style={{ marginTop: 10 }}
                  >
                    <MenuItem value="">Select Gender</MenuItem>
                    <MenuItem value="1">Male</MenuItem>
                    <MenuItem value="2">Female</MenuItem>
                    <MenuItem value="3">Other</MenuItem>
                  </Select>

                  <Select
                    id="advisorType"
                    name="advisorType"
                    variant="outlined"
                    size="small"
                    placeholder="advisorType"
                    fullWidth
                    value={formikProps.values.advisorType}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.advisorType &&
                      formikProps.errors.advisorType
                    }
                    helperText={
                      formikProps.touched.advisorType &&
                      formikProps.errors.advisorType
                    }
                    style={{ marginTop: 10 }}
                  >
                    <MenuItem value="0">Select Advisor Type</MenuItem>
                    <MenuItem value="1">WealthPlanner</MenuItem>
                    <MenuItem value="2">TaxAdvisor</MenuItem>
                  </Select>

                  <TextField
                    id="emailId"
                    name="emailId"
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formikProps.values.emailId}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.emailId && formikProps.errors.emailId
                    }
                    helperText={
                      formikProps.touched.emailId && formikProps.errors.emailId
                    }
                    style={{ marginTop: 10 }}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 10 }}
                    >
                      Update
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

export default AdvisorList;

