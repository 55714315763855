import React, { Component, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../provider/auth";
import { makeStyles } from "@material-ui/core/styles";
import Email from "../components/ForgotPassWord/Email";
import Otp from "../components/ForgotPassWord/Otp";
import PassWord from "../components/ForgotPassWord/PassWord";
const useStyles = makeStyles((theme) => ({
  gridClassName: {
    boxShadow: "0px 2px 11px 6px #d7c6c6",
    padding: 20,
    borderRadius: "5px",
    backgroundColor: "#fff",
    width: "50%",
    marginLeft: "25%",
    marginTop: "10%",
  },
  otpName: {
    width: "100%",
  },

  // other classes here
}));



export default function ForgotPassword({props }) {
  let navigate = useNavigate();
  var classes = useStyles();
  let auth = useAuth();

  const [isOtpReceived, setIsOtpReceived] = useState(false);
  const [isPasswordVisible, setIsPassWordVisible]= useState(false);

  const [text, setText] = useState("");
  const [userLoginId,setUserLoginId]=useState("");
 
  return(
      <div  className={classes.gridClassName}>
          <Email {...{setIsOtpReceived,isOtpReceived,isPasswordVisible,text,setText}}/>
          {isOtpReceived && !isPasswordVisible ?(<Otp {...{setIsPassWordVisible,isPasswordVisible,text,userLoginId,setUserLoginId}}/>):(<></>)}
          {isOtpReceived && isPasswordVisible ?(<PassWord {...{text,userLoginId}}/>):(<></>)}
      </div>

  )

}
