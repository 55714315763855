import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  TextField,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import SizedBox from "../SizedBox";
import CurrencyTextField from "../CurrencyTextField";
import { Formik } from "formik";
import * as Yup from "yup";
import API from "../../services/api";
import { getInteger } from "../../Common/helperMethods";
import { Alert, AlertTitle } from "@material-ui/lab";

const QTR_API_URL = process.env.REACT_APP_INTERESTDETAILS_CONTROLLER;

function Interest({ activeQuarter, setSelectedTab, client, showToast }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ["Interest_Data", id],
    async () => {
      return API.get(QTR_API_URL + `GetInterestDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  const isDisabled = client?.isDisabled; 

  const interestData = data
    ? data
    : {
        fd: 0,
        fD_TDS: 0,
        saving: 0,
        savingTDS: 0,
        others: 0,
        othersTDS: 0,
        comment: "",
        foreignInterest: 0,
        ftc: 0,
        taxQtrProfileId: id,
        epfInterestTDS: 0,
        epfInterest: 0,
        passThroughIncome: 0,
        passThroughIncomeTDS: 0,
        interestId: "00000000-0000-0000-0000-000000000000",
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={interestData}
        validationSchema={Yup.object({
          fD_TDS: Yup.string().test({
            name: "max",
            exclusive: true,
            params: {},
            message: "TDS should not exceed 10% of interest",
            test: function (value) {
              // You can access the price field with `this.parent`.
              console.log("vald", value, this.parent.fd);
              return getInteger(value) <= getInteger(this.parent.fd) * 0.1;
            },
          }),
          savingTDS: Yup.string().test({
            name: "max",
            exclusive: true,
            params: {},
            message: "TDS should not exceed 30% of interest",
            test: function (value) {
              // You can access the price field with `this.parent`.
              console.log("savingTDS", value, this.parent.fd);
              return getInteger(value) <= getInteger(this.parent.saving) * 0.3;
            },
          }),
          othersTDS: Yup.string().test({
            name: "max",
            exclusive: true,
            params: {},
            message: "TDS should not exceed 30% of interest",
            test: function (value) {
              // You can access the price field with `this.parent`.
              console.log("others", value, this.parent.others);
              return getInteger(value) <= getInteger(this.parent.others) * 0.3;
            },
          }),
          epfInterestTDS: Yup.string().test({
            name: "max",
            exclusive: true,
            params: {},
            message: "TDS should not exceed 10% of interest",
            test: function (value) {
              return (
                getInteger(value) <= getInteger(this.parent.epfInterest) * 0.1
              );
            },
          }),
          passThroughIncomeTDS: Yup.string().test({
            name: "max",
            exclusive: true,
            params: {},
            message: "TDS should not exceed 10% of interest",
            test: function (value) {
              return (
                getInteger(value) <=
                getInteger(this.parent.passThroughIncome) * 0.1
              );
            },
          }),
          // ftc: Yup.string().test({
          //   name: 'max',
          //   exclusive: true,
          //   params: {},
          //   message: 'FTC should not exceed 43% of foreign interest',
          //   test: function (value) {
          //     // You can access the price field with `this.parent`.
          //     return (
          //       getInteger(value) <=
          //       getInteger(this.parent.foreignInterest) * 0.43
          //     );
          //   },
          // }),
        })}
        onSubmit={async (values, actions) => {
          API.post(QTR_API_URL + "AddInterestDetails", {
            ...values,
            fd: getInteger(values.fd),
            fD_TDS: getInteger(values.fD_TDS),
            saving: getInteger(values.saving),
            savingTDS: getInteger(values.savingTDS),
            others: getInteger(values.others),
            othersTDS: getInteger(values.othersTDS),
            foreignInterest: getInteger(values.foreignInterest),
            ftc: getInteger(values.ftc),
            epfInterest: getInteger(values.epfInterest),
            epfInterestTDS: getInteger(values.epfInterestTDS),
            passThroughIncome: getInteger(values.passThroughIncome),
            passThroughIncomeTDS: getInteger(values.passThroughIncomeTDS),
          }).then((response) => {
            showToast({
              message: "Interest submitted successfully!",
              severity: "success",
            });
            setSelectedTab(2);
            navigate(`/advance-tax/quarter/${id}/dividends`);
          });
        }}
      >
        {(formikProps) => (
          <>
            <SizedBox height={10} width={10}></SizedBox>
            <Grid container>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>
                    Please enter the estimated annual income amounts.
                  </AlertTitle>
                </Alert>
              </Grid>
            </Grid>
            <SizedBox height={40} width={10}></SizedBox>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="fd"
                      label="FD interest"
                      fullWidth
                      onChange={(e) => {
                        formikProps.setFieldValue("fd", e.target.value);
                        formikProps.setFieldValue(
                          "fD_TDS",
                          (getInteger(e.target.value) * 10) / 100
                        );
                      }}
                      onBlur={(e) => {
                        formikProps.setFieldValue("fd", e.target.value);
                        formikProps.setFieldValue(
                          "fD_TDS",
                          (getInteger(e.target.value) * 10) / 100
                        );
                      }}
                      value={formikProps.values.fd}
                      error={formikProps.touched.fd && !!formikProps.errors.fd}
                      helperText={
                        formikProps.touched.fd && formikProps.errors.fd
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="fD_TDS"
                      label="TDS"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.fD_TDS}
                      error={
                        formikProps.touched.fD_TDS &&
                        !!formikProps.errors.fD_TDS
                      }
                      helperText={
                        formikProps.touched.fD_TDS && formikProps.errors.fD_TDS
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="saving"
                      label="Saving interest"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.saving}
                      error={
                        formikProps.touched.saving &&
                        !!formikProps.errors.saving
                      }
                      helperText={
                        formikProps.touched.saving && formikProps.errors.saving
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="savingTDS"
                      label="TDS"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.savingTDS}
                      error={
                        formikProps.touched.savingTDS &&
                        !!formikProps.errors.savingTDS
                      }
                      helperText={
                        formikProps.touched.savingTDS &&
                        formikProps.errors.savingTDS
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="others"
                      label="Other interest"
                      fullWidth
                      onChange={(e) => {
                        formikProps.setFieldValue("others", e.target.value);
                        formikProps.setFieldValue(
                          "othersTDS",
                          (getInteger(e.target.value) * 10) / 100
                        );
                      }}
                      onBlur={(e) => {
                        formikProps.setFieldValue("others", e.target.value);
                        formikProps.setFieldValue(
                          "othersTDS",
                          (getInteger(e.target.value) * 10) / 100
                        );
                      }}
                      value={formikProps.values.others}
                      error={
                        formikProps.touched.others &&
                        !!formikProps.errors.others
                      }
                      helperText={
                        formikProps.touched.others && formikProps.errors.others
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="othersTDS"
                      label="TDS"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.othersTDS}
                      error={
                        formikProps.touched.othersTDS &&
                        !!formikProps.errors.othersTDS
                      }
                      helperText={
                        formikProps.touched.othersTDS &&
                        formikProps.errors.othersTDS
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                {/* {client &&
                (client.residentialStatus == '1' ||
                  client.residentialStatus == 'OR') ? (
                  <>
                    <SizedBox height={30} width={10}></SizedBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <CurrencyTextField
                          id="foreignInterest"
                          label="Foreign Interest"
                          fullWidth
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.foreignInterest}
                          error={
                            formikProps.touched.foreignInterest &&
                            !!formikProps.errors.foreignInterest
                          }
                          helperText={
                            formikProps.touched.foreignInterest &&
                            formikProps.errors.foreignInterest
                          }
                          disabled={isDisabled || formikProps.isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <CurrencyTextField
                          id="ftc"
                          label="FTC"
                          fullWidth
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.ftc}
                          error={
                            formikProps.touched.ftc && !!formikProps.errors.ftc
                          }
                          helperText={
                            formikProps.touched.ftc && formikProps.errors.ftc
                          }
                          disabled={isDisabled || formikProps.isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )} */}
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="epfInterest"
                      label="Epf Interest"
                      fullWidth
                      onChange={(e) => {
                        formikProps.setFieldValue("epfInterest", e.target.value);
                        formikProps.setFieldValue(
                          "epfInterestTDS",
                          (getInteger(e.target.value) * 10) / 100
                        );
                      }}
                      onBlur={(e) => {
                        formikProps.setFieldValue("epfInterest", e.target.value);
                        formikProps.setFieldValue(
                          "epfInterestTDS",
                          (getInteger(e.target.value) * 10) / 100
                        );
                      }}
                      value={formikProps.values.epfInterest}
                      error={
                        formikProps.touched.epfInterest &&
                        !!formikProps.errors.epfInterest
                      }
                      helperText={
                        formikProps.touched.epfInterest &&
                        formikProps.errors.epfInterest
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="epfInterestTDS"
                      label="TDS"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.epfInterestTDS}
                      error={
                        formikProps.touched.epfInterestTDS &&
                        !!formikProps.errors.epfInterestTDS
                      }
                      helperText={
                        formikProps.touched.epfInterestTDS &&
                        formikProps.errors.epfInterestTDS
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="passThroughIncome"
                      label="Pass Through Income"
                      fullWidth
                      onChange={(e) => {
                        formikProps.setFieldValue("passThroughIncome", e.target.value);
                        formikProps.setFieldValue(
                          "passThroughIncomeTDS",
                          (getInteger(e.target.value) * 10) / 100
                        );
                      }}
                      onBlur={(e) => {
                        formikProps.setFieldValue("passThroughIncome", e.target.value);
                        formikProps.setFieldValue(
                          "passThroughIncomeTDS",
                          (getInteger(e.target.value) * 10) / 100
                        );
                      }}
                      value={formikProps.values.passThroughIncome}
                      error={
                        formikProps.touched.passThroughIncome &&
                        !!formikProps.errors.passThroughIncome
                      }
                      helperText={
                        formikProps.touched.passThroughIncome &&
                        formikProps.errors.passThroughIncome
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="passThroughIncomeTDS"
                      label="TDS"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.passThroughIncomeTDS}
                      error={
                        formikProps.touched.passThroughIncomeTDS &&
                        !!formikProps.errors.passThroughIncomeTDS
                      }
                      helperText={
                        formikProps.touched.passThroughIncomeTDS &&
                        formikProps.errors.passThroughIncomeTDS
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      disabled={true}
                      maximumValue="1000000000000"
                      selectTextOnFocus="false"
                      label="Total interest"
                      fullWidth
                      onBlur={formikProps.handleBlur}
                      value={
                        getInteger(formikProps.values.fd) +
                        getInteger(formikProps.values.saving) +
                        getInteger(formikProps.values.others) +
                        getInteger(formikProps.values.epfInterest) +
                        getInteger(formikProps.values.passThroughIncome) +
                        (client &&
                        (client.residentialStatus == "1" ||
                          client.residentialStatus == "OR")
                          ? getInteger(formikProps.values.foreignInterest)
                          : 0)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      editable="false"
                      disabled={true}
                      selectTextOnFocus="false"
                      maximumValue="1000000000000"
                      label="Total TDS"
                      fullWidth
                      onBlur={formikProps.handleBlur}
                      value={
                        getInteger(formikProps.values.fD_TDS) +
                        getInteger(formikProps.values.savingTDS) +
                        getInteger(formikProps.values.othersTDS) +
                        getInteger(formikProps.values.epfInterestTDS) +
                        getInteger(formikProps.values.passThroughIncomeTDS) +
                        (client &&
                        (client.residentialStatus == "1" ||
                          client.residentialStatus == "OR")
                          ? getInteger(formikProps.values.ftc)
                          : 0)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="comment"
                      variant="outlined"
                      multiline={true}
                      label="Enter your comments/notes here"
                      type="text"
                      name="comment"
                      minRows={8}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.comment}
                      error={
                        formikProps.touched.comment &&
                        !!formikProps.errors.comment
                      }
                      helperText={
                        formikProps.touched.comment &&
                        formikProps.errors.comment
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}></Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        disabled={isDisabled || formikProps.isSubmitting}
                        color="primary"
                        onClick={formikProps.handleSubmit}
                      >
                        Save and Next
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
}
export default Interest;
