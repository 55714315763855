import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  useSelectedClient,
  useUpdateSelectedClient,
} from "../../Common/useSelectedClient";
import API from "../../services/api";
import DisplayClient from "./DisplayClient";

import { CircularProgress, Grid, TextField } from "@material-ui/core";
import SizedBox from "../SizedBox";
import useDebounce from "../../Common/useDebounce";

const API_URL = process.env.REACT_APP_ADVISOR_CONTROLLER;

export default function LoadAdvanceTaxClientForAdvisor({
  activeQuarter,
  fy,
  role,
}) {
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 2000);

  const { data, isLoading } = useQuery(
    ["ALL_CLIENT_PROFILE_FOR_ADVISOR", debounceSearch],
    async () => {
      return API.post(API_URL + "GetAssignedAdavanceTaxClients", {
        searchText: debounceSearch,
      })
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    }
  );

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       style={{
  //         position: 'absolute',
  //         top: '50%',
  //         left: '50%',
  //       }}
  //       size={60}
  //     />
  //   );
  // }

  /*<div style={{ paddingLeft: 80, marginTop: 32 }}>*/
  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid item md={2}></Grid>
      <Grid item xs={12} sm={12} md={8}>
        <SizedBox height={10} width={10}></SizedBox>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <h3>Current FY: {fy}</h3>
          </Grid>
          <Grid item xs={role === "Admin" ? 4 : 6}>
            <TextField
              id="search"
              name="search"
              label="Search Client"
              variant="outlined"
              size="small"
              type="text"
              style={{ width: "100%" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
        </Grid>

        {isLoading && (
          <CircularProgress
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
            size={60}
          />
        )}
        <div className="row" style={{ marginTop: 16, border: 1 }}>
          {!isLoading &&
            data &&
            data.length > 0 &&
            data
              .filter((s) => s.fy === fy)
              .map((t, index) => {
                return (
                  <Grid key={index} container>
                    <SizedBox height={10} width={10}></SizedBox>
                    <DisplayClient
                      {...{
                        index,
                        t,
                        activeQuarter,
                        fy,
                      }}
                    ></DisplayClient>
                  </Grid>
                );
              })}

          {data && data.length == 0 && (
            <div>
              <SizedBox height={40} width={20}></SizedBox>
              <h2>No Data Found</h2>
            </div>
          )}
        </div>
      </Grid>
      <Grid item md={1}></Grid>

      {/*</div>*/}
    </Grid>
  );
}
