import React from "react";
import { TextField } from "@material-ui/core";

const CustomTextField = ({ field, form: { touched }, ...props }) => {
  return (
    <div>
      <TextField {...field} {...props} />
      {touched[field.name]}
    </div>
  );
};

export default CustomTextField;
