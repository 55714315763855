import React, { useState } from "react";
import LoginForm from "../../components/LoginForm";
import "./login.css";

import { Grid, Typography } from "@material-ui/core";
import SignUpForm from "../../components/SignUpForm";

export default function Login() {
  const [isLogin, setIsLogin] = useState(true);
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{}}
    >
      <Grid
        item
        xs={10}
        md={5}
        lg={4}
        style={{ marginBottom: "30px", marginTop: "20px" }}
      >
              <Grid style={{ textAlign: "center" }}>
                  {/*<div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems:"center" }}>*/}
                  <img
            src="logo advance-tax.png"
            alt="logo"
            style={{
              width: "150px",
              marginBottom: "6%",
              marginTop: "50px",
                      }}
                      />
                      {/*<Typography variant="h2" style={{ marginTop: 20 }}>Billion Base Camp</Typography>*/}
                  {/*</div>*/}
        </Grid>

        {isLogin ? (
          <LoginForm setIsLogin={setIsLogin}></LoginForm>
        ) : (
          <SignUpForm setIsLogin={setIsLogin}></SignUpForm>
        )}
      </Grid>
    </Grid>
  );
}
