import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
 
  bluebutton: {
    width: 140,
    height: 32,
    backgroundColor: "primary",
    border: "1px solid #808080",
    borderRadius: 4,
  },
  button: {
    width: 200,
    height: 32,
    backgroundColor: "primary",
    border: "1px solid #808080",
    borderRadius: 4,
  },
  font14blue: {
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "black",
    fontWeight: "bold",
    margin: 4,
  },
  Row:{
    flexDirection:"row",
    justifyContent:"space-between",
    display:"flex",
    //width: 900
  }
 
}));
