import React, { useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { useAuth } from "../../provider/auth";
import LoadTaxFilingClientForAdvisor from "../../components/Dashboard/LoadTaxFilingClientForAdvisor";
import TaxFiling from "../TaxFiling";
import { useSelectedFY } from "../../Common/useSelectedClient";
import UserList from "./UserList";
import DisplayTabWiseDocuments from "../../components/Documents/DisplayTabWiseDocuments";

export default function Income() {
  let auth = useAuth();
  const selectedFY = useSelectedFY();
  const { id } = useParams();
  const [openAddMember, setAddMemberOpen] = useState(false);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            auth.user.role === "Client" ? (
              <UserList
                {...{ fy: selectedFY, id, openAddMember, setAddMemberOpen }}
              />
            ) : (
              <LoadTaxFilingClientForAdvisor
                {...{ fy: selectedFY, role: auth.user.role }}
              />
            )
          }
        />

        <Route
          path="/filing/:id/*"
          element={<TaxFiling {...{ fy: selectedFY }} />}
        />
        <Route
          path="/documents/:id/*"
          element={
            <DisplayTabWiseDocuments
              {...{ fy: selectedFY, isClient: auth.user.role === "Client" }}
            />
          }
        />
        {/* <Route
          path="/residential/:id"
          element={<ResidentialStatus {...{ fy: selectedFY }} />}
        /> */}
      </Routes>
    </div>
  );
}
