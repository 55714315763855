import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  TextField,
  FormControl,
  Divider,
  MenuItem,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import API from "../../services/api";
import { useQuery } from "react-query";
import SizedBox from "../SizedBox";
import { getInteger } from "../../Common/helperMethods";
import { isValid, parse, format } from "date-fns";
import { useSelectedFY } from "../../Common/useSelectedClient";
import ServiceType from "../../Common/ServiceType";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}));

const CURRENT_FY = process.env.REACT_APP_FY;
const CURRENT_AY = process.env.REACT_APP_AY;
const API_URL = process.env.REACT_APP_USERPROFILE_CONTROLLER;

function MyFixedProfile({ showToast, setSelectedTab, role, service }) {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const selectedFy = useSelectedFY();

  const { data, isLoading } = useQuery(
    ["MyFixedProfile", id],
    async () => {
      return API.get(API_URL + `GetProfileDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  const isValidDOB = data
    ? getInteger(new Date(data.dob).getFullYear()) !== 1
    : false;

  const splitDate =
    data && isValidDOB
      ? format(new Date(data.dob), "yyyy-MM-dd").split("-")
      : [];

  const formValues = data
    ? {
        ...data,
        pan: data.pan ?? "",
        dd: isValidDOB && splitDate.length > 0 ? splitDate[2] : "",
        mm: isValidDOB && splitDate.length > 0 ? splitDate[1] : "",
        yyyy: isValidDOB && splitDate.length > 0 ? splitDate[0] : "",
        accounType: "1",
        relationship: "Self",
        gender: data.gender === 0 || data.gender === "0" ? "" : data.gender,
        service: service,
        fy: selectedFy
          ? selectedFy
          : service === ServiceType.INCOMETAX
          ? CURRENT_FY
          : CURRENT_AY,
      }
    : {
        firstName: "",
        lastName: "",
        gender: "",
        pan: "",
        userProfileId: "",
        relationship: "Self",
        accountType: "1",
        dob: "",
        dd: "",
        mm: "",
        yyyy: "",
        service: service,
        fy: selectedFy
          ? selectedFy
          : service === ServiceType.INCOMETAX
          ? CURRENT_FY
          : CURRENT_AY,
      };

  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: 18, color: "#363636" }}>My Profile Fixed</p>
      </div>
      <Divider
        style={{ color: "#EAEAEC", borderRadius: 2 }}
        variant="fullWidth"
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Formik
          initialValues={formValues}
          onSubmit={async (values, actions) => {
            const dobText = `${values.yyyy}.${
              getInteger(values.mm) < 10
                ? "0" + getInteger(values.mm)
                : getInteger(values.mm)
            }.${getInteger(values.dd)}`;

            const dateOfBirth = parse(dobText, "yyyy.MM.dd", new Date());
            if (!isValid(dateOfBirth)) {
              showToast({
                message: "Please enter correct date",
                severity: "error",
              });
              return;
            }
            //return;
            API.post(API_URL + "AddUserProfile", {
              ...values,
              dob: format(dateOfBirth, "yyyy-MM-dd"),
            }).then((response) => {
              showToast({
                message: "Fixed profile submitted successfully!",
                severity: "success",
              });

              try {
                setSelectedTab(2);
                
                if (location.pathname.indexOf("incomeprofile") > -1)
                  navigate(`/incomeprofile/${id}/residential?type=income`);
                else navigate(`/profile/${id}/annual?type=advance`);
              } catch {
                navigate(`/`);
              }
            });
          }}
          validationSchema={Yup.object({
            lastName: Yup.string().required("Last Name is a required field"),
            dd: Yup.string()
              .required("DD is a required Field")
              .test({
                name: "max",
                exclusive: true,
                params: {},
                message: "Date should be between 1 and 31",
                test: function (value) {
                  return getInteger(value) >= 1 && getInteger(value) <= 31;
                },
              }),
            mm: Yup.string()
              .required("Month is a required field")
              .test({
                name: "max",
                exclusive: true,
                params: {},
                message: "Month should be between 1 and 12",
                test: function (value) {
                  return getInteger(value) >= 1 && getInteger(value) <= 12;
                },
              }),
            yyyy: Yup.string()
              .required("Year is a required field")
              .test({
                name: "max",
                exclusive: true,
                params: {},
                message: "Year should be between 1912 and 2017",
                test: function (value) {
                  return getInteger(value) >= 1912 && getInteger(value) <= 2017;
                },
              }),
            gender: Yup.string().required("Gender is a required field"),
            pan: Yup.string()
              .required("PAN is a required field")
              .min(10, "Enter correct 10 digit PAN")
              .max(10, "Enter correct 10 digit PAN")
              .matches(
                /^[A-Za-z]{3}[PpHh][A-Za-z][0-9]{4}[a-zA-Z]/,
                "Enter correct PAN"
              ),
            // relationship: Yup.string()
            //     .required('Relationship is a required Field.'),
            // accountType: Yup.string()
            //     .required('Account Type is a required Field'),
          })}
        >
          {(formikProps) => (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="firstName"
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    size="small"
                    value={formikProps.values.firstName}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={
                      formikProps.touched.firstName &&
                      !!formikProps.errors.firstName
                    }
                    helperText={
                      formikProps.touched.firstName &&
                      formikProps.errors.firstName
                    }
                  />
                </Grid>

                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="lastName"
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    value={formikProps.values.lastName}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={
                      formikProps.touched.lastName &&
                      !!formikProps.errors.lastName
                    }
                    helperText={
                      formikProps.touched.lastName &&
                      formikProps.errors.lastName
                    }
                    disabled={formikProps.isSubmitting}
                  />
                </Grid>
                <Margin></Margin>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        fullWidth
                        id="dd"
                        type="number"
                        label="DD"
                        variant="outlined"
                        size="small"
                        disabled={isValidDOB && role === "Client"}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.dd}
                        error={
                          formikProps.touched.dd && !!formikProps.errors.dd
                        }
                        helperText={
                          formikProps.touched.dd && formikProps.errors.dd
                        }
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        fullWidth
                        id="mm"
                        label="MM"
                        type="number"
                        variant="outlined"
                        size="small"
                        disabled={isValidDOB && role === "Client"}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.mm}
                        error={
                          formikProps.touched.mm && !!formikProps.errors.mm
                        }
                        helperText={
                          formikProps.touched.mm && formikProps.errors.mm
                        }
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      {" "}
                      <TextField
                        fullWidth
                        id="yyyy"
                        label="YYYY"
                        type="number"
                        variant="outlined"
                        size="small"
                        disabled={isValidDOB && role === "Client"}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.yyyy}
                        onBlur={formikProps.handleBlur}
                        error={
                          formikProps.touched.yyyy && !!formikProps.errors.yyyy
                        }
                        helperText={
                          formikProps.touched.yyyy && formikProps.errors.yyyy
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      id="gender"
                      select
                      label="Gender"
                      variant="outlined"
                      size="small"
                      error={
                        formikProps.touched.gender &&
                        !!formikProps.errors.gender
                      }
                      helperText={
                        formikProps.touched.gender && formikProps.errors.gender
                      }
                      value={formikProps.values.gender}
                      onChange={(e) => {
                        formikProps.setFieldValue("gender", e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>Select Gender</em>
                      </MenuItem>
                      <MenuItem value="1">
                        <em>Male</em>
                      </MenuItem>
                      <MenuItem value="2">
                        <em>Female</em>
                      </MenuItem>
                      <MenuItem value="3">
                        <em>Other</em>
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    style={{ textTransform: "uppercase" }}
                    fullWidth
                    value={formikProps.values.pan.toUpperCase()}
                    id="pan"
                    label="PAN"
                    variant="outlined"
                    size="small"
                    error={formikProps.touched.pan && !!formikProps.errors.pan}
                    helperText={
                      formikProps.touched.pan && formikProps.errors.pan
                    }
                    disabled={isValidDOB && role === "Client"}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                  />
                </Grid>
                {/* 
                            <Margin></Margin>

                            <FormControl variant="outlined" className={classes.formControl}>

                                <TextField
                                    id="accountType"
                                    select
                                    label="Account Type"
                                    variant="outlined"
                                    size="small"
                                    error={formikProps.touched.accountType && !!formikProps.errors.accountType}
                                    helperText={formikProps.touched.accountType && formikProps.errors.accountType}
                                    value={formikProps.values.accountType}
                                    onChange={(e) => {
                                        formikProps.setFieldValue('accountType', e.target.value);
                                        if(e.target.value=="1")
                                            formikProps.setFieldValue('relationship',"Self");
                                        else 
                                            formikProps.setFieldValue('relationship',"");
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Select Account Type</em>
                                    </MenuItem>
                                    <MenuItem value="1">
                                        <em>Self</em>
                                    </MenuItem>
                                    <MenuItem value="2">
                                        <em>Family Member</em>
                                    </MenuItem>
                                </TextField>
                            </FormControl>
                            <Margin></Margin>

                            <TextField
                                style={{ width: "40%" }}
                                value={formikProps.values.relationship}
                                id="relationship"
                                name="relationship"
                                label="Relationship"
                                variant="outlined"
                                size="small"
                                onChange={formikProps.handleChange}
                                error={formikProps.touched.relationship && !!formikProps.errors.relationship}
                                helperText={formikProps.touched.relationship && formikProps.errors.relationship}
                            /> */}

                <SizedBox height={40} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    disabled={formikProps.isSubmitting}
                    color="primary"
                    onClick={formikProps.handleSubmit}
                  >
                    Save And Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </div>
    </div>
  );
}

function Margin() {
  return <SizedBox height={25} width={25}></SizedBox>;
}

export default MyFixedProfile;
