import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../services/api';
import DisplayFiles from '../../Common/displayFiles';
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import SizedBox from '../../SizedBox';
import { Formik } from 'formik';
import { Alert, AlertTitle } from '@material-ui/lab';

const CG_API_URL = process.env.REACT_APP_CAPITALGAINS_CONTROLLER;
export default function Asset({
  setSelectedSubTab,
  setSelectedTab,
  client,
  showToast,
  isDisable
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const tabName = 'Asset';
  const { data, isLoading } = useQuery(
    ['CAPITALGAINS_Data', tabName],
    async () => {
      return API.get(CG_API_URL + `GetCGDetails/${id}?tabName=${tabName}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  const EquityData = data
    ? {
        ...data,
      }
    : {
        fileList: [],
        cgId: '00000000-0000-0000-0000-000000000000',
        comment: '',
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <Formik
      initialValues={EquityData}
      onSubmit={async (values, actions) => {
        API.post(CG_API_URL + 'AddCGDetails', {
          ...values,
          taxProfileId: id,

          tabName,
        }).then((response) => {
          showToast({
            message: 'Indian equity investments submitted successfully!',
            severity: 'success',
          });
          setSelectedTab(4);
          navigate(`/income/filing/${id}/interest`);
        });
      }}
    >
      {(formikProps) => (
        <>
          <Alert style={{ flex: 3 }} severity="info">
            <AlertTitle>
              Please let us know if you have capital gains from any other assets
              like real estate, crypto, gold, bonds, debentures etc. and upload
              relevant documents.
              <br />
              <br />
              Steps to download your crypto account statement.
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 10 }}>
                  1.{' '}
                  <a
                    target="_blank"
                    href={`${process.env.PUBLIC_URL}/documents/Process_Flow_Document_to_generate_Trading_Report_from_WazirX_Platform_for_Cryptocurrency.pdf`}
                  >
                    WazirX
                  </a>
                </div>
              </div>
            </AlertTitle>
          </Alert>
          <SizedBox height={20} width={10}></SizedBox>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DisplayFiles
                {...{
                  // fileList,
                  // setFileList,
                  formikProps,
                  type: tabName,
                  buttonText:
                    ((formikProps.values?.fileList || [])?.length || 0) === 0
                      ? 'Upload documents'
                      : 'Upload more documents',
                }}
              ></DisplayFiles>

              <SizedBox height={30}></SizedBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="Enter comments/ notes"
                    placeholder="In case of sale of house property, please upload following documents -
1) Sale deed
2) Purchase deed
3) Sale expenses such as brokerage/travelling with valid receipts
4) Cost of improvement with dates
5) Purchase expenditure (Stamp duty, Brokerage, etc.)"
                    type="text"
                    name="comment"
                    minRows={11}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.comment}
                    error={
                      formikProps.touched.comment &&
                      !!formikProps.errors.comment
                    }
                    helperText={
                      formikProps.touched.comment && formikProps.errors.comment
                    }
                    disabled={isDisable || formikProps.isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6} md={6}></Grid> */}
                <Grid item xs={12} sm={6} md={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disabled={isDisable || formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
