const TaxFilingTabName = {
  Unknown: 0,
  Salary: 1,
  Rental: 2,
  ESOPS: 3,
  CapitalGainsMutual: 4,
  CapitalGainsEquity: 5,
  CapitalGainsAsset: 6,
  Interest: 7,
  Deductions: 8,
  Disclosure: 9,
  // Summary: 10,
  // Residential: 11,
  Other: 12,
};

export default TaxFilingTabName;
