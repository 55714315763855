import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { CloudDownloadOutlined, EditOutlined } from "@material-ui/icons";
import React from "react";
import API from "../../services/api";
import { useState } from "react";
import useStyles from "../Common/styles";
import { useParams } from "react-router";
import SizedBox from "../SizedBox";
import DocumentTabType from "../../Common/DocumentTabType";
import EditDocument from "./EditDocument";
import { FieldArray, Form, Formik } from "formik";
import MoveDocuments from "./MoveDocuments";
import TaxFilingTabName from "../../Common/TaxFilingTabName";
import { useToastDisplay } from "../../Common/useToastDisplay";

const API_URL = process.env.REACT_APP_FILE_CONTROLLER;

export default function DisplayDocumentList({
  data,
  documentTab,
  fy,
  isClient,
  refetch,
}) {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState();
  const showToast = useToastDisplay();

  var classes = useStyles();
  const { id } = useParams();

  const handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;
    if (!selectedFileType || selectedFileType === "") {
      showToast({
        message: "Please select file type to upload",
        severity: "error",
      });
      return;
    }
    setIsFileLoading(true);
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = (event) => {
        console.log("event", event.target);
      };

      reader.readAsDataURL(files[0]);

      const formData = new FormData();
      formData.append("fileData", files[0]);

      API.post(
        API_URL +
          `UploadAndSaveDocument/${id}/${fy}/${documentTab}/${selectedFileType}`,
        formData
      )
        .then((res) => {
          console.log("Uploaded successfully");
          refetch();
          setIsFileLoading(false);
          showToast({
            message: "File uploaded successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          console.log("error", err);
          setIsFileLoading(false);
        });
    }
  };

  const initialValues = {
    fileList:
      data && data.filter((t) => t.documentTabType === documentTab).length > 0
        ? data
            .filter((t) => t.documentTabType === documentTab)
            .map((t) => {
              return { ...t, isSelected: false };
            })
        : [],
  };

  function displayUploadButton() {
    return (
      (documentTab === DocumentTabType.UploadedByClient && isClient) ||
      (documentTab !== DocumentTabType.UploadedByClient && !isClient)
    );
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          //const mappedData = values.fileList;
          //   if (mappedData.length > 25) {
          //     // showToast({
          //     //   message: 'You can add max 25 ',
          //     //   severity: 'error',
          //     // });
          //     return;
          //   }
          //const input = { taxProfileId: id, directorShipVM: mappedData };
          //   API.post(DIRECTOR_API_URL + `AddDirectorShipDetails`, {
          //     ...input,
          //   })
          //     .then((response) => {
          //       showToast({
          //         message: 'Directorship submitted successfully!',
          //         severity: 'success',
          //       });
          //       setSelectedTab(8);
          //       navigate(`/income/filing/${id}/summary`);
          //     })
          //     .catch((err) => console.log('err', err));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="fileList">
              {({ insert, remove, push }) => (
                <Table style={{ border: "1px solid black" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          border: "1px solid black",
                        }}
                      >
                        Date & Time
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          border: "1px solid black",
                        }}
                      >
                        File Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          border: "1px solid black",
                        }}
                      >
                        Tab
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          border: "1px solid black",
                        }}
                      >
                        Remark
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          border: "1px solid black",
                        }}
                      >
                        Password
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          border: "1px solid black",
                        }}
                      >
                        Uploaded By
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          border: "1px solid black",
                        }}
                      >
                        Services
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          border: "1px solid black",
                        }}
                      >
                        Download
                      </TableCell>
                      {(!isClient ||
                        (isClient &&
                          documentTab ===
                            DocumentTabType.UploadedByClient)) && (
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            border: "1px solid black",
                          }}
                        >
                          Edit
                        </TableCell>
                      )}
                      {!isClient && (
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            border: "1px solid black",
                          }}
                        >
                          Tick
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values &&
                      values.fileList &&
                      values.fileList.length > 0 &&
                      values.fileList.map((t, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ border: "1px solid black" }}>
                            {t.createdDate}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {t.fileName}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {t.fileType}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {t.comment}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {t.password}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {t.uploadedBy}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {t.services}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            <IconButton
                              aria-haspopup="true"
                              color="inherit"
                              aria-controls="profile-menu"
                              style={{ padding: 6 }}
                              onClick={() => {
                                API.get(
                                  API_URL +
                                    `GetDownloadFromS3/${t.fileId}/${t.taxProfileId}`,
                                  {
                                    responseType: "blob",
                                  }
                                ).then((response) => {
                                  const url = window.URL.createObjectURL(
                                    new Blob([response.data])
                                  );
                                  const link = document.createElement("a");
                                  link.href = url;
                                  link.setAttribute(
                                    "download",
                                    `${t.fileName}`
                                  );
                                  document.body.appendChild(link);
                                  link.click();

                                  // Clean up and remove the link
                                  link.parentNode.removeChild(link);
                                });
                              }}
                            >
                              <CloudDownloadOutlined />
                            </IconButton>
                          </TableCell>
                          {(!isClient ||
                            (isClient &&
                              documentTab ===
                                DocumentTabType.UploadedByClient)) && (
                            <TableCell style={{ border: "1px solid black" }}>
                              <IconButton
                                aria-haspopup="true"
                                color="inherit"
                                aria-controls="profile-menu"
                                style={{ padding: 6 }}
                                onClick={() => {
                                  setSelectedFile(t);
                                  setEditOpen(true);
                                }}
                              >
                                <EditOutlined />
                              </IconButton>
                            </TableCell>
                          )}
                          {!isClient && (
                            <TableCell style={{ border: "1px solid black" }}>
                              {" "}
                              <Checkbox
                                name={`fileList.${index}.isSelected`}
                                onChange={(e) => {
                                  const updatedSelectedRows = [
                                    ...selectedFileIds,
                                  ];
                                  updatedSelectedRows[index] = e.target.checked
                                    ? t.fileId
                                    : undefined;
                                  setSelectedFileIds(updatedSelectedRows);
                                  setFieldValue(
                                    `fileList.${index}.isSelected`,
                                    e.target.checked
                                  );
                                  setSelectedFile(t);
                                }}
                                checked={t.isSelected}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>

      {initialValues.fileList && initialValues.fileList.length === 0 && (
        <div>
          <SizedBox height={40} width={20}></SizedBox>
          <h2>No Files Found</h2>
        </div>
      )}
      <SizedBox height={20}></SizedBox>
      <Box
        sx={{
          p: 2,
        }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "65%" }}>
          {displayUploadButton() === true && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TextField
                name="documentTabType"
                select
                label="Select File Type"
                variant="outlined"
                size="large"
                fullWidth
                value={selectedFileType}
                onChange={(e) => {
                  setSelectedFileType(e.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {Object.keys(TaxFilingTabName)
                  .filter((t, index) => index !== TaxFilingTabName.Unknown)
                  .map((s) => {
                    return (
                      <MenuItem key={s} value={s}>
                        <em>{s.toString()}</em>
                      </MenuItem>
                    );
                  })}
              </TextField>
              <SizedBox width={10}></SizedBox>
              <>
                <input
                  id={`uploadOther_${documentTab}`}
                  type="file"
                  accept={
                    ".pdf, .xls, .xlsx, .doc, .docx, .csv, .jpeg, .png, .jpg, .bmp"
                  }
                  className={classes.input}
                  onChange={handleFileChange}
                />

                <div>
                  <div variant="contained">
                    <label
                      htmlFor={`uploadOther_${documentTab}`}
                      style={{
                        padding: 10,
                        backgroundColor: "#334f64",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                        fontSize: 12,
                        width: 300,
                        alignSelf: "center",
                      }}
                    >
                      {"Upload"}
                    </label>
                  </div>

                  {isFileLoading && <span>Uploading...</span>}
                </div>
              </>
            </div>
          )}
        </div>
        {!isClient && (
          <Button
            variant="contained"
            color="primary"
            style={{ fontWeight: "bold", marginLeft: "15%" }}
            onClick={(e) => {
              setOpenMoveModal(true);
            }}
          >
            Move OR Copy To
          </Button>
        )}
      </Box>

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openEdit}
        onClose={() => {
          setEditOpen(false);
        }}
      >
        <EditDocument
          {...{
            row: selectedFile,
            setEditOpen,
            refetch,
          }}
        ></EditDocument>
      </Modal>

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openMoveModal}
        onClose={() => {
          setOpenMoveModal(false);
        }}
      >
        <MoveDocuments
          {...{
            row: selectedFile,
            setOpenMoveModal,
            selectedFileIds,
            refetch,
            documentTab,
            fy,
          }}
        ></MoveDocuments>
      </Modal>
    </>
  );
}
