import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Button, Chip } from "@material-ui/core";
import { Person as AccountIcon } from "@material-ui/icons";
import API from "../../services/api";
import { useUpdateSelectedClient } from "../../Common/useSelectedClient";
import { useToastDisplay } from "../../Common/useToastDisplay";
import { Formik } from "formik"; // Add Formik and TextField imports
import * as Yup from "yup"; // Add Yup import
import { Modal, TextField } from "@material-ui/core";
import SizedBox from "../../components/SizedBox";
import lock from "../../Common/assets/lock2.png";
import { useAuth } from "../../provider/auth";

const QTR_API_URL = process.env.REACT_APP_QTRPROFILE_CONTROLLER;
const CURRENT_AY = process.env.REACT_APP_AY;
const Login_URL = process.env.REACT_APP_LOGIN_CONTROLLER;

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    height: 50,
    width: "100%",
    textAlign: "center",
    padding: 15,
    backgroundColor: "#edc274",
    marginRight: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#FFE600",
    },
  },
  disabledButton: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    height: 50,
    width: "100%",
    textAlign: "center",
    padding: 15,
    backgroundColor: "lightgray !important",
    marginRight: 20,
  },
  nameLink: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    marginRight: 20,
    "&:hover": {
      cursor: "pointer",
      fontWeight: "bold",
      textDecoration: "underline",
    },
  },
  // other classes here
}));

export default function DisplayClient({ index, t, activeQuarter, fy }) {
  let navigate = useNavigate();
  const { user } = useAuth();
  var classes = useStyles();
  const [isClicked, setIsClicked] = useState(false);
  const setClient = useUpdateSelectedClient();
  const showToast = useToastDisplay();
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const handleClose = () => {
    setOpenPasswordModal(false);
  };
  function redirectToQuarter(userProfileId, qtrType) {
    setIsClicked(true);
    API.get(
      QTR_API_URL +
        `CheckAndGetQtrProfile/${userProfileId}?QtrType=${qtrType}&fy=${fy}`
    )
      .then((res) => {
        const data = res.data;
        if (data.isRedirectToProfile === true) {
          setClient({
            ...data,
          });
          navigate(`/profile/${userProfileId}`);
          return;
        }
        const clientData = {
          ...data,
          isDisabled:
            (data?.qtrType !== "Quarter" + activeQuarter && fy === CURRENT_AY) ||
            fy !== CURRENT_AY,
        };
        setClient({
          ...clientData,
        });
        setIsClicked(false);
        navigate(`/advance-tax/quarter/${res.data.qtrProfileId}/`);
      })
      .catch((err) => {
        setIsClicked(false);
        // if (qtrType == '1') {
          showToast({
            message:
              `Quarter ${qtrType} due date is already over and hence, you can not edit it now.`,
            severity: 'error',
          });
        // } else {
        // showToast({
        //   message: "OOPS! Something went wrong.",
        //   severity: "error",
        // });
        // }
      });
  }

  return (
    <Grid
      item
      spacing={2}
      xs={12}
      md={12}
      style={{
        color: "#fff",
        backgroundColor: "#8e99d5",
        padding: 15,
        borderRadius: 5,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Grid spacing={2}>
        <div style={{ float: "right" }}>
          {user.role !== "Client" && (
            <>
              <IconButton
                size="small"
                aria-haspopup="true"
                color="inherit"
                title="Change Password"
              >
                <img
                  src={lock}
                  alt="Change Password"
                  style={{ width: 25, height: 25, marginRight: 5 }}
                  onClick={() => setOpenPasswordModal(true)}
                />
              </IconButton>

              <Modal
                open={openPasswordModal}
                onClose={() => setOpenPasswordModal(false)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  onSubmit={async (values, actions) => {
                    API.post(Login_URL + "ChangePasswordForClient", {
                      LoginId: t.loginId,
                      Password: values.password,
                    })
                      .then((response) => {
                        showToast({
                          message: "Password changed successfully!",
                          severity: "success",
                        });
                        handleClose();
                      })
                      .catch((e) => {
                        showToast({
                          message: "Current password did not match.",
                          severity: "error",
                        });
                      });
                  }}
                  validationSchema={Yup.object({
                    password: Yup.string()
                      .required("Password is a required Field")
                      .min(6, "Minimum 6 Characters required"),
                    confirmPassword: Yup.string()
                      .required("Confirm Password is a required Field")
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      ),
                  })}
                >
                  {(formikProps) => (
                    <div
                      style={{
                        height: 420, //520
                        width: 450,
                        backgroundColor: "#fff",
                        elevation: 0.5,
                        borderRadius: 5,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 50,
                        }}
                      >
                        <h2>Change Password</h2>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      ></div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: 30,
                          marginRight: 30,
                        }}
                      >
                        <SizedBox height={20} width={20}></SizedBox>

                        <TextField
                          id="password"
                          name="password"
                          label="Password"
                          variant="outlined"
                          size="small"
                          type="password"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.password}
                          error={
                            formikProps.touched.password &&
                            !!formikProps.errors.password
                          }
                          helperText={
                            formikProps.touched.password &&
                            formikProps.errors.password
                          }
                          disabled={formikProps.isSubmitting}
                        />
                        <SizedBox height={20} width={20}></SizedBox>
                        <TextField
                          id="confirmPassword"
                          name="confirmPassword"
                          label="Confirm Password"
                          variant="outlined"
                          size="small"
                          type="password"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.confirmPassword}
                          error={
                            formikProps.touched.confirmPassword &&
                            !!formikProps.errors.confirmPassword
                          }
                          helperText={
                            formikProps.touched.confirmPassword &&
                            formikProps.errors.confirmPassword
                          }
                          disabled={formikProps.isSubmitting}
                        />
                      </div>

                      <div
                        style={{
                          marginTop: 30,
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Button
                          style={{ backgroundColor: "#EE9A4D" }}
                          variant="contained"
                          onClick={formikProps.handleSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </Formik>
              </Modal>
            </>
          )}
          <Chip
            size="small"
            label={t.accountType == "1" ? "Self" : "Family member"}
          />
          <IconButton
            size="small"
            aria-haspopup="true"
            color="inherit"
            title="View Profile"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => {
              navigate(`/profile/${t.userProfileId}`);
            }}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
          </IconButton>
        </div>
        <div>
          <div>
            <span
              className={classes.nameLink}
              onClick={(e) => {
                navigate(`/profile/${t.userProfileId}`);
              }}
            >
              {t.firstName} {t.lastName}
            </span>
          </div>
        </div>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3} md={3}>
          <Button
            disabled={isClicked}
            className={
              activeQuarter >= 1 ? classes.button : classes.disabledButton
            }
            onClick={() => {
              redirectToQuarter(t.userProfileId, 1);
            }}
          >
            Q1
          </Button>
        </Grid>
        <Grid item xs={3} md={3}>
          <Button
            className={
              activeQuarter >= 2 ? classes.button : classes.disabledButton
            }
            // style={{
            //   backgroundColor: activeQuarter < 2 ? "lightgray" : "#edc274",
            //   color: "#fff",
            // }}
            disabled={isClicked || activeQuarter < 2}
            onClick={() => {
              redirectToQuarter(t.userProfileId, 2);
            }}
          >
            Q2
          </Button>
        </Grid>
        <Grid item xs={3} md={3}>
          <Button
            className={
              activeQuarter >= 3 ? classes.button : classes.disabledButton
            }
            disabled={isClicked || activeQuarter < 3}
            onClick={() => redirectToQuarter(t.userProfileId, 3)}
          >
            Q3
          </Button>
        </Grid>
        <Grid item xs={3} md={3}>
          <Button
            className={
              activeQuarter >= 4 ? classes.button : classes.disabledButton
            }
            disabled={isClicked || activeQuarter < 4}
            onClick={() => redirectToQuarter(t.userProfileId, 4)}
          >
            Q4
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
