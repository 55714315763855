import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import {
  FormControl,
  MenuItem,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SizedBox from './../SizedBox';
import CurrencyTextField from '../CurrencyTextField';
import API from '../../services/api';
import { getInteger } from '../../Common/helperMethods';
import { makeStyles } from '@material-ui/core/styles';
import CoOwner from './CoOwner';
import StateList from '../../Common/StateList';
import CountryList from '../../Common/CountryList';
import { useEffect } from 'react';
const Property_API_URL = process.env.REACT_APP_PROPERTY_CONTROLLER;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  select: {
    height: 40,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function AddProperty({
  setSelectedTab,
  client,
  showToast,
  propertyId,
  setIsView,
}) {
  const classes = useStyles();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    ['Property_Data', propertyId],
    async () => {
      return API.get(Property_API_URL + `GetPropertyDetails/${propertyId}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      enable: Boolean(propertyId),
    }
  );

  const [coOwnerList, setCoOwnerList] = useState([]);

  function getTaxableIncome(
    annualRent,
    propertyTax,
    ownership,
    housingLoanInt
  ) {
    const nav =
      (getInteger(annualRent) - getInteger(propertyTax)) *
      (getInteger(ownership) / 100);
    return Math.round(
      nav - (nav < 0 ? 0 : nav * 0.3) - getInteger(housingLoanInt)
    );
  }
  useEffect(() => {
    if (data && data.coOwners && data.coOwners.length) {
      setCoOwnerList(data.coOwners);
    }
  }, [data]);
  const initialValues = data
    ? {
        ...data,
        isSelfOccupied: data.isSelfOccupied ? 'true' : 'false',
      }
    : {
        propertyName: '',
        annualRent: 0,
        propertyTax: 0,
        housingLoanInt: 0,
        tdsOnRent: 0,
        propertyNumber: 1,
        shareInAnnualRent: 0,
        standardDeduction: 0,
        taxablePropertyIncome: 0,
        ownership: 100,
        preConstructionInterest: 0,
        rentalPropertyId: '00000000-0000-0000-0000-000000000000',
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }
  console.log("initialValues,",initialValues)
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          propertyName: Yup.string().required(
            'Property Name is a required Field'
          ),
          address: Yup.string().required(
            'Property Address is a required Field'
          ),
          city: Yup.string().required('Property City is a required Field'),
          state: Yup.string().required('Property State is a required Field'),
          country: Yup.string().required(
            'Property Country is a required Field'
          ),
          pinCode: Yup.number().required(
            'Property Pincode is a required Field'
          ),
          tenantPAN: Yup.string().when(['isSelfOccupied'], {
            is: (isSelfOccupied) => {
              //any complex calulation
              return isSelfOccupied == 'false' || isSelfOccupied == false;
            },
            then: Yup.string()
              .min(10, 'Enter correct 10 digit PAN')
              .max(10, 'Enter correct 10 digit PAN')
              .matches(
                /^[A-Za-z]{3}[PpHh][A-Za-z][0-9]{4}[a-zA-Z]/,
                'Enter correct PAN'
              ),
          }),
        })}
        onSubmit={async (values) => {
          let nav = getInteger(
            (getInteger(values.annualRent) - getInteger(values.propertyTax)) *
              (getInteger(values.ownership) / 100)
          );
          if (values.state === '99' && values.country === 'India') {
            showToast({
              message: 'Please select country other than India',
              severity: 'error',
            });
            return;
          }
          if (values.state !== '99' && values.country !== 'India') {
            showToast({
              message: 'Please select country as India',
              severity: 'error',
            });
            return;
          }
          if (getInteger(values.ownership) < 100 && coOwnerList.length === 0) {
            showToast({
              message: 'Please add co-owner',
              severity: 'error',
            });
            return;
          }
          if (coOwnerList.length>0 && getInteger(values.ownership)+coOwnerList.reduce((acc, b) => {
            return (
              acc +
              getInteger(
                b.ownerRatio
              )
            );
          }, 0)!==100) {
            showToast({
              message: 'Total co-owner percentage should be equal to 100',
              severity: 'error',
            });
            return;
          }

          const mappedValues = {
            ...values,
            annualRent: getInteger(values.annualRent),
            propertyTax: getInteger(values.propertyTax),
            housingLoanInt: getInteger(values.housingLoanInt),
            tdsOnRent: getInteger(values.tdsOnRent),
            ownership: getInteger(values.ownership),
            preConstructionInterest: getInteger(values.preConstructionInterest),
            shareInAnnualRent: nav,
            standardDeduction: Math.round(nav < 0 ? 0 : nav * 0.3),
            taxablePropertyIncome: Math.round(
              nav -
                (nav < 0 ? 0 : nav * 0.3) -
                getInteger(values.housingLoanInt)
            ),
            taxProfileId: id,
            //propertyName: values.propertyName,
            // rentalPropertyId: values.rentalPropertyId,
            // tenantName: values.tenantName,
            // tenantPAN: values.tenantPAN,
            // address: values.address,
            // city: values.city,
            // state: values.state,
            // country: values.country,
            pinCode: values.pinCode.toString(),
            coOwners: coOwnerList,
            // .map((r) => {
            //   return {
            //     name: r.coOwnerName,
            //     pan: r.coOwnerPAN,
            //     ownerRatio: r.ownershipRatio,
            //   };
            //}),
            isSelfOccupied: values.isSelfOccupied === 'true' ? true : false,
            //propertyNumber: index + 1,
            //id: index,
            comment: '',
          };
          const input = { ...mappedValues };
          API.post(Property_API_URL + `AddPropertyDetails`, {
            ...input,
          })
            .then((response) => {
              console.log('Add api called ', response);
              showToast({
                message: 'Property submitted successfully!',
                severity: 'success',
              });
              queryClient.refetchQueries('ALL_Properties');

              setIsView(false);
            })
            .catch((err) => console.log('err', err));
        }}
      >
        {(formikProps) => (
          <div style={{ width: '100%' }}>
            <SizedBox height={10} width={10}></SizedBox>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Property Name"
                  type="text"
                  size="small"
                  name="propertyName"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.propertyName}
                  error={
                    formikProps.touched.propertyName &&
                    !!formikProps.errors.propertyName
                  }
                  helperText={
                    formikProps.touched.propertyName &&
                    formikProps.errors.propertyName
                  }
                  disabled={formikProps.isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Property Address"
                  type="text"
                  size="small"
                  name="address"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.address}
                  error={
                    formikProps.touched.address && !!formikProps.errors.address
                  }
                  helperText={
                    formikProps.touched.address && formikProps.errors.address
                  }
                  disabled={formikProps.isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Property City"
                  type="text"
                  name="city"
                  size="small"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.city}
                  error={formikProps.touched.city && !!formikProps.errors.city}
                  helperText={
                    formikProps.touched.city && formikProps.errors.city
                  }
                  disabled={formikProps.isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    name="state"
                    select
                    label="Property State"
                    variant="outlined"
                    size="small"
                    error={
                      formikProps.touched.state && !!formikProps.errors.state
                    }
                    helperText={
                      formikProps.touched.state && formikProps.errors.state
                    }
                    value={formikProps.values.state}
                    onChange={(e) => {
                      formikProps.setFieldValue('state', e.target.value);
                      if (e.target.value !== '99') {
                        formikProps.setFieldValue('country', 'India');
                      } else formikProps.setFieldValue('country', '');
                    }}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    {StateList &&
                      StateList.map((s) => {
                        return (
                          <MenuItem key={s.code} value={s.code}>
                            <em>{s.name}</em>
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    name="country"
                    select
                    label="Property Country"
                    variant="outlined"
                    size="small"
                    error={
                      formikProps.touched.country &&
                      !!formikProps.errors.country
                    }
                    helperText={
                      formikProps.touched.country && formikProps.errors.country
                    }
                    value={formikProps.values.country}
                    onChange={(e) => {
                      formikProps.setFieldValue('country', e.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    {CountryList &&
                      CountryList.map((s) => {
                        return (
                          <MenuItem value={s.code}>
                            <em>{s.name}</em>
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  variant="outlined"
                  label="Property Pincode"
                  type="number"
                  name="pinCode"
                  size="small"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.pinCode}
                  error={
                    formikProps.touched.pinCode && !!formikProps.errors.pinCode
                  }
                  helperText={
                    formikProps.touched.pinCode && formikProps.errors.pinCode
                  }
                  disabled={formikProps.isSubmitting}
                  fullWidth
                />
              </Grid>
            </Grid>
            <SizedBox height={20} width={10}></SizedBox>
            <hr></hr>
            <SizedBox height={20} width={10}></SizedBox>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    name="isSelfOccupied"
                    select
                    label="Property Type"
                    variant="outlined"
                    size="small"
                    error={
                      formikProps.touched.isSelfOccupied &&
                      !!formikProps.errors.isSelfOccupied
                    }
                    helperText={
                      formikProps.touched.isSelfOccupied &&
                      formikProps.errors.isSelfOccupied
                    }
                    value={formikProps.values.isSelfOccupied}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        'isSelfOccupied',
                        e.target.value
                      );
                    }}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value="false">
                      <em>Let out for part of the year or for full year</em>
                    </MenuItem>
                    <MenuItem value="true">
                      <em>Self-occupied/vacant</em>
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

              {formikProps.values.isSelfOccupied === 'false' && (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <CurrencyTextField
                      variant="outlined"
                      label="Annual Rent"
                      name={`annualRent`}
                      maximumValue="100000000"
                      type="text"
                      size="small"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={getInteger(formikProps.values.annualRent)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <CurrencyTextField
                      variant="outlined"
                      label="Property Tax"
                      name={`propertyTax`}
                      maximumValue="10000000"
                      type="text"
                      size="small"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={getInteger(formikProps.values.propertyTax)}
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={4} md={4}>
                <CurrencyTextField
                  variant="outlined"
                  label="Ownership %"
                  maximumValue="100"
                  minimumValue="0"
                  currencySymbol=""
                  name="ownership"
                  type="text"
                  size="small"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={getInteger(formikProps.values.ownership)}
                  fullWidth
                />
              </Grid>
              {formikProps.values.isSelfOccupied === 'false' && (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <CurrencyTextField
                      size="small"
                      variant="outlined"
                      name="shareInAnnualRent"
                      label="NAV"
                      minimumValue="-999999999"
                      disabled={true}
                      value={
                        (getInteger(formikProps.values.annualRent) -
                          getInteger(formikProps.values.propertyTax)) *
                        (getInteger(formikProps.values.ownership) / 100)
                      }
                      placeholder=""
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <CurrencyTextField
                      size="small"
                      variant="outlined"
                      name="standardDeduction"
                      label="Std. Ded."
                      disabled={true}
                      value={
                        (getInteger(formikProps.values.annualRent) -
                          getInteger(formikProps.values.propertyTax)) *
                          (getInteger(formikProps.values.ownership) / 100) <
                        0
                          ? 0
                          : Math.round(
                              (getInteger(formikProps.values.annualRent) -
                                getInteger(formikProps.values.propertyTax)) *
                                (getInteger(formikProps.values.ownership) /
                                  100) *
                                0.3
                            )
                      }
                      placeholder=""
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={4} md={4}>
                <CurrencyTextField
                  size="small"
                  variant="outlined"
                  name="housingLoanInt"
                  maximumValue="100000000"
                  label="Housing Loan Interest"
                  placeholder=""
                  type="text"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={getInteger(formikProps.values.housingLoanInt)}
                  fullWidth
                />
              </Grid>
              {formikProps.values.isSelfOccupied === 'false' && (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <CurrencyTextField
                      size="small"
                      variant="outlined"
                      disabled={true}
                      name="taxableRentalIncome"
                      maximumValue="100000000"
                      minimumValue="-100000000"
                      label="Taxable rental income"
                      placeholder=""
                      type="text"
                      value={getTaxableIncome(
                        formikProps.values.annualRent,
                        formikProps.values.propertyTax,
                        formikProps.values.ownership,
                        formikProps.values.housingLoanInt
                      )}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <CurrencyTextField
                      size="small"
                      variant="outlined"
                      name="tdsOnRent"
                      maximumValue="100000000"
                      label="TDS On Rent"
                      placeholder=""
                      type="text"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={getInteger(formikProps.values.tdsOnRent)}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
              {formikProps.values.isSelfOccupied === 'false' && (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      variant="outlined"
                      label="Tenant Name"
                      size="small"
                      type="text"
                      name="tenantName"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.tenantName}
                      error={
                        formikProps.touched.tenantName &&
                        !!formikProps.errors.tenantName
                      }
                      helperText={
                        formikProps.touched.tenantName &&
                        formikProps.errors.tenantName
                      }
                      disabled={formikProps.isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      variant="outlined"
                      label="Tenant PAN"
                      size="small"
                      type="text"
                      name="tenantPAN"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.tenantPAN}
                      error={
                        formikProps.touched.tenantPAN &&
                        !!formikProps.errors.tenantPAN
                      }
                      helperText={
                        formikProps.touched.tenantPAN &&
                        formikProps.errors.tenantPAN
                      }
                      disabled={formikProps.isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  {getInteger(formikProps.values.housingLoanInt) < 200000 && (
                    <Grid item xs={12} sm={4} md={4}>
                      <CurrencyTextField
                        size="small"
                        variant="outlined"
                        name="preConstructionInterest"
                        maximumValue="100000000"
                        minimumValue="0"
                        label="Pre-construction period interest"
                        placeholder="I paid interest (in prior years OR in current year) when it was under-construction"
                        type="text"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(
                          formikProps.values.preConstructionInterest
                        )}
                        fullWidth
                      ></CurrencyTextField>
                    </Grid>
                  )}
                </>
              )}
            </Grid>

            {getInteger(formikProps.values.ownership) < 100 && (
              <CoOwner
                {...{
                  coOwnerList,
                  setCoOwnerList,
                  ownership: getInteger(formikProps.values.ownership),
                }}
              ></CoOwner>
            )}
            <SizedBox height={20} width={10}></SizedBox>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}></Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        disabled={formikProps.isSubmitting}
                        color="primary"
                        onClick={formikProps.handleSubmit}
                      >
                        Save and Next
                      </Button>
                      <SizedBox height={20} width={20}></SizedBox>
                      <Button
                        variant="outlined"
                        disabled={formikProps.isSubmitting}
                        color="primary"
                        onClick={() => setIsView(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Formik>
    </div>
  );
}
export default AddProperty;
