import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../services/api';
import { useToastDisplay } from '../../Common/useToastDisplay';

const LOGIN_API_URL = process.env.REACT_APP_LOGIN_CONTROLLER;

export default function PassWord({ text, userLoginId }) {
  let navigate = useNavigate();
  const showToast = useToastDisplay();
  return (
    <Grid>
      <div>
        <Formik
          initialValues={{ password: '', ConfirmPassword: '' }}
          onSubmit={async (values, actions) => {
            await API.put(
              LOGIN_API_URL +
                `PasswordUpdate/${userLoginId}?password=${values.password}`
            )
              .then((res) => res.data)
              .then((f) => {
                showToast({
                  message: 'Your password has been reset successfully!',
                  severity: 'success',
                });
                navigate('/login');
              })
              .catch((e) => console.log('API FAILED', e));
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .required('Password is a required Field')
              .min(6, 'Minimum 6 Characters required'),
            ConfirmPassword: Yup.string()
              .required('Confirm Password is a required Field')
              .oneOf([Yup.ref('password'), null], 'Passwords must match'),
          })}
        >
          {(formikProps) => (
            <Grid
              container
              size="sm"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  variant="outlined"
                  size="small"
                  type="password"
                  style={{ width: '100%', marginTop: 30 }}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.password}
                  error={
                    formikProps.touched.password &&
                    !!formikProps.errors.password
                  }
                  helperText={
                    formikProps.touched.password && formikProps.errors.password
                  }
                  disabled={formikProps.isSubmitting}
                />
                <TextField
                  id="ConfirmPassword"
                  name="ConfirmPassword"
                  label="Confirm Password"
                  variant="outlined"
                  size="small"
                  type="password"
                  style={{ width: '100%', marginTop: 30 }}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.ConfirmPassword}
                  error={
                    formikProps.touched.ConfirmPassword &&
                    !!formikProps.errors.ConfirmPassword
                  }
                  helperText={
                    formikProps.touched.ConfirmPassword &&
                    formikProps.errors.ConfirmPassword
                  }
                  disabled={formikProps.isSubmitting}
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <Button
                  variant="contained"
                  onClick={formikProps.handleSubmit}
                  style={{ width: '30%' }}
                  disabled={formikProps.isSubmitting}
                  color="primary"
                >
                  Reset Password
                </Button>
              </div>
            </Grid>
          )}
        </Formik>
      </div>
    </Grid>
  );
}
