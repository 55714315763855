import {
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Grid,
  Link,
} from "@material-ui/core";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../services/api";
import { useQuery } from "react-query";
import SizedBox from "../SizedBox";
import { useEffect } from "react";
import { useState } from "react";
import { useToastDisplay } from "../../Common/useToastDisplay";
import AdvisorSide from "./Summary/AdvisorSide";
import ProfileStatus from "../../Common/ProfileStatus";
import { CloudDownload } from "@material-ui/icons";
import { useSelectedClient } from "../../Common/useSelectedClient";

const TAX_API_URL = process.env.REACT_APP_TAXPROFILE_CONTROLLER;
const FILE_API_URL = process.env.REACT_APP_FILE_CONTROLLER;

export default function Summary({ isDisable }) {
  const { id } = useParams();
  let navigate = useNavigate();
  const showToast = useToastDisplay();
  const client = useSelectedClient();
  const [initialValues, setInitialValues] = useState({
    comment: "",
    status: "",
  });
  const { data, isLoading, refetch } = useQuery(
    ["GET_TAX_SUMMARY", id],
    async () => {
      return API.get(TAX_API_URL + `GetTaxSummary/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  useEffect(() => {
    if (data) {
      setInitialValues({ comment: data.comment ?? "", status: data.status });
    }
  }, [data]);

  const submitToAdvisor = () => {
    API.post(TAX_API_URL + "SubmitTaxProfileAdvisor", {
      ...initialValues,
      taxProfileId: id,
      status: ProfileStatus.Assigned.id,
    })
      .then((f) => {
        showToast({
          message: "Tax profiled assigned to advisor",
          severity: "success",
        });
        refetch();
      })
      .catch((e) => console.log("API FAILED", e));
  };
  const approveSummary = (isApproved) => {
    API.post(TAX_API_URL + "ApproveSummary", {
      taxProfileId: id,
      isApproved,
    })
      .then((f) => {
        showToast({
          message: isApproved
            ? "Tax summary approved"
            : "Query raised successfully",
          severity: "success",
        });
        refetch();
      })
      .catch((e) => console.log("API FAILED", e));
  };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <TextField
        id="comment"
        variant="outlined"
        multiline={true}
        label="Please enter comments, if any for your Tax Advisor"
        disabled={isDisable}
        type="text"
        name="comment"
        style={{ width: "80%" }}
        minRows={5}
        value={initialValues.comment}
        onChange={(e) => {
          setInitialValues({ ...initialValues, comment: e.target.value });
        }}
        fullWidth
      />
      <SizedBox height={20}></SizedBox>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <Button
          variant="contained"
          disabled={isDisable}
          color="primary"
          onClick={() => {
            submitToAdvisor();
          }}
        >
          Submit to Advisor
        </Button>
        <SizedBox width={10}></SizedBox>
        {data.status >= ProfileStatus.InProcess.id && (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              navigate(`/income/documents/${client.userProfileGuid}?type=income`)
            }
            style={{ color: "#0090FF", textDecoration: "none" }}
          >
            View/ Upload Documents
          </Link>
        )}
      </div>
      <SizedBox height={20}></SizedBox>
      {data &&
        data.isClient === true &&
        data.status ===
          (ProfileStatus.Assigned.id ||
            ProfileStatus.PendingForInternalReview.id) && (
          <div style={{ fontSize: 14 }}>
            <h3>Thanks for providing documents & information.</h3>
            <h3>
              We will soon prepare your Tax Summary & share with you for your
              review.
            </h3>
          </div>
        )}
      {data &&
        data.isClient === true &&
        data.status === ProfileStatus.TaxSummaryPrepared.id && (
          <div style={{ fontSize: 14 }}>
            <h2>Kindly review and approve tax summary</h2>
            <SizedBox height={20}></SizedBox>

            {data &&
              data.fileList &&
              data.fileList.map((s, index) => {
                return (
                  <div key={index}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        {s.fileName}
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <IconButton
                          size="small"
                          aria-haspopup="true"
                          color="inherit"
                          title="Download File"
                          aria-controls="profile-menu"
                          onClick={(e) => {
                            API.get(
                              FILE_API_URL + "GetDownloadFromS3/" + s.fileId,
                              {
                                responseType: "blob",
                              }
                            ).then((response) => {
                              const url = window.URL.createObjectURL(
                                new Blob([response.data])
                              );
                              const link = document.createElement("a");
                              link.href = url;
                              link.setAttribute("download", `${s.fileName}`);
                              document.body.appendChild(link);
                              link.click();

                              // Clean up and remove the link
                              link.parentNode.removeChild(link);
                            });
                          }}
                        >
                          <CloudDownload />
                        </IconButton>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            approveSummary(true);
                          }}
                        >
                          Approve
                        </Button>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            approveSummary(false);
                          }}
                        >
                          Have a Question?
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
          </div>
        )}
      {data &&
        data.isClient === true &&
        data.status === ProfileStatus.TaxSummaryRejected.id && (
          <div style={{ fontSize: 14 }}>
            <h3>Thanks for your input.</h3>
            <h3>
              We will work on your computation again & share with you for your
              review.
            </h3>
          </div>
        )}
      {data &&
        data.isClient === true &&
        (data.status === ProfileStatus.TaxSummaryApproved.id ||
          data.status === ProfileStatus.ReadyToEfile.id) && (
          <div style={{ fontSize: 14 }}>
            <h3>Thanks for approving computation.</h3>
            <h3>We will E-file your return soon.</h3>
          </div>
        )}
      {data && data.isClient === false && (
        <AdvisorSide {...{ data }}></AdvisorSide>
      )}
    </div>
  );
}
