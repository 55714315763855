import React from "react";
import { Grid, TextField, Link, Button } from "@material-ui/core";

const AdvisorDetails = () => {
  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <div
          style={{
            backgroundColor: "#0059A9",
            width: "50%",
            height: 50,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p style={{ color: "#fff" }}>Advisor Password Reset</p>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",

          width: "100%",
          marginTop: 100,
        }}
      >
        <div
          style={{
            width: "30%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: 50,
            paddingBottom: 70,
          }}
        >
          <p>Password*</p>
          <TextField
            id="password"
            name="password"
            label="Password"
            variant="outlined"
            size="small"
            type="password"
            onChange={() => {}}
          />
          <p> Confirm Password*</p>
          <TextField
            id="password"
            name="password"
            label="Password"
            variant="outlined"
            size="small"
            type="password"
            onChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvisorDetails;
