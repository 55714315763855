import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

const drawerWidth = 105;
export default makeStyles(theme => ({
    body: {
        padding: 20
    },
    input: {
        width: 1,
        height: 1,
        opacity: 0,
        overflow: "hidden",
        position:"absolute",
        zIndex: -1,
    },
    label: {
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
}));
