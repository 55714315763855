import React from "react";
import { Formik } from "formik";
import API from "../../services/api";
import { Button, MenuItem, TextField } from "@material-ui/core";
import { useToastDisplay } from "../../Common/useToastDisplay";
import SizedBox from "../SizedBox";
import DocumentTabType from "../../Common/DocumentTabType";
import * as Yup from "yup";
const API_URL = process.env.REACT_APP_FILE_CONTROLLER;

export default function MoveDocuments({
  row,
  setOpenMoveModal,
  selectedFileIds,
  refetch,
  documentTab,
}) {
  const showToast = useToastDisplay();
  return (
    <Formik
      initialValues={{
        taxProfileId: row?.taxProfileId,
        selectedFileIds,
        documentTabType: "",
      }}
      onSubmit={async (values, actions) => {
        if (values.selectedFileIds && values.selectedFileIds.length === 0) {
          showToast({
            message: "Please select files to move",
            severity: "error",
          });
          return;
        }
        await API.post(API_URL + "MoveDocument", {
          ...values,
          fileIds: selectedFileIds,
          documentTab: values.documentTabType,
        })
          .then((res) => res.data)
          .then((f) => {
            showToast({
              message: "Advisor assigned successfully!",
              severity: "success",
            });
            refetch();
            setOpenMoveModal(false);
          })
          .catch((e) => console.log("API FAILED", e));
      }}
      validationSchema={Yup.object({
        documentTabType: Yup.string().required("Please select document tab"),
      })}
    >
      {(formikProps) => (
        <div
          style={{
            height: 450,
            width: 400,
            backgroundColor: "#fff",
            elevation: 0.5,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              padding: 10,
            }}
          >
            <SizedBox height={30}></SizedBox>
            <TextField
              name="documentTabType"
              select
              label="Select Document Tab"
              variant="outlined"
              size="small"
              error={
                formikProps.touched.documentTabType &&
                !!formikProps.errors.documentTabType
              }
              helperText={
                formikProps.touched.documentTabType &&
                formikProps.errors.documentTabType
              }
              value={formikProps.values.documentTabType}
              onChange={(e) => {
                formikProps.setFieldValue("documentTabType", e.target.value);
              }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {Object.keys(DocumentTabType)
                .filter((t) => t !== DocumentTabType.Unknown.toString())
                .map((s) => {
                  return (
                    <MenuItem key={s} value={s}>
                      <em>{s.toString()}</em>
                    </MenuItem>
                  );
                })}
            </TextField>
          </div>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ backgroundColor: "#EE9A4D" }}
              variant="contained"
              onClick={formikProps.handleSubmit}
            >
              Save
            </Button>
            <SizedBox width={10}></SizedBox>
            <Button
              variant="contained"
              onClick={() => {
                setOpenMoveModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
}
