import React from 'react';
import {
  Button,
  TextField,
  Grid,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import SizedBox from '../SizedBox';
import API from '../../services/api';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Alert, AlertTitle } from '@material-ui/lab';
import DisplayFiles from '../Common/displayFiles';

const DEDUCTION_API_URL = process.env.REACT_APP_DEDUCTION_CONTROLLER;

export default function Deductions({ setSelectedTab, client, showToast,isDisable }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['Deduction_Data', id],
    async () => {
      return API.get(DEDUCTION_API_URL + `GetDeductionDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  const isDisabled = client?.isDisabled;

  const initialValues = data
    ? data
    : {
        eightyC: '',
        nps: '',
        healthInsuranceSelf: '',
        healthInsuranceParent: '',
        preventiveHealth: '',
        parentsHealthExpences: '',
        donation: '',
        educationLoan: '',
        disability: '',
        expenditure: '',
        deductionId: '00000000-0000-0000-0000-000000000000',
        taxProfileId: id,
        fileList: [],
      };

  // const [fileList, setFileList] = useState([]);

  // useEffect(() => {
  //   if (data && data.fileList && data.fileList.length > 0) {
  //     setFileList(data.fileList);
  //   }
  // }, [data]);

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          API.post(DEDUCTION_API_URL + 'AddDeductionDetails', {
            ...values,
            //fileList,
          }).then((response) => {
            showToast({
              message: 'Deduction submitted successfully!',
              severity: 'success',
            });
            setSelectedTab(7);
            navigate(`/income/filing/${id}/disclosures/`);
          });
        }}
      >
        {(formik) => (
          <>
            <Alert style={{ flex: 3 }} severity="info">
              <AlertTitle>
                Update details for the items which are <b>not reflected</b> in
                Form 16.
              </AlertTitle>
            </Alert>
            <SizedBox height={20} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="80C"
                  type="text"
                  name="eightyC"
                  placeholder="Enter amounts of PPF, ELSS, Senior Citizen, Savings Scheme, home loan repayment, etc"
                  minRows={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.eightyC}
                  error={formik.touched.eightyC && !!formik.errors.eightyC}
                  helperText={formik.touched.eightyC && formik.errors.eightyC}
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="NPS"
                  placeholder="Did you contribute to NPS tier 1?"
                  type="text"
                  name="nps"
                  minRows={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nps}
                  error={formik.touched.nps && !!formik.errors.nps}
                  helperText={formik.touched.nps && formik.errors.nps}
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
            </Grid>

            <SizedBox height={40} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="Health Insurance Self"
                  type="text"
                  placeholder="Enter the amount of premium for health insurance for you, spouse and kids?"
                  name="healthInsuranceSelf"
                  minRows={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.healthInsuranceSelf}
                  error={
                    formik.touched.healthInsuranceSelf &&
                    !!formik.errors.healthInsuranceSelf
                  }
                  helperText={
                    formik.touched.healthInsuranceSelf &&
                    formik.errors.healthInsuranceSelf
                  }
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="Health Insurance Parent"
                  type="text"
                  name="healthInsuranceParent"
                  placeholder="Enter the amount of premium you paid for health insurance for your parents"
                  minRows={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.healthInsuranceParent}
                  error={
                    formik.touched.healthInsuranceParent &&
                    !!formik.errors.healthInsuranceParent
                  }
                  helperText={
                    formik.touched.healthInsuranceParent &&
                    formik.errors.healthInsuranceParent
                  }
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
            </Grid>

            <SizedBox height={40} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="Preventive Health Check up"
                  type="text"
                  placeholder="Any expenses for preventive health care for self, spouse, kids. "
                  name="preventiveHealth"
                  minRows={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.preventiveHealth}
                  error={
                    formik.touched.preventiveHealth &&
                    !!formik.errors.preventiveHealth
                  }
                  helperText={
                    formik.touched.preventiveHealth &&
                    formik.errors.preventiveHealth
                  }
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="Parents Health Care Expenses"
                  type="text"
                  name="parentsHealthExpences"
                  minRows={2}
                  placeholder="In case not covered under any health insurance plan then add amount of expense incurred on health care"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.parentsHealthExpences}
                  error={
                    formik.touched.parentsHealthExpences &&
                    !!formik.errors.parentsHealthExpences
                  }
                  helperText={
                    formik.touched.parentsHealthExpences &&
                    formik.errors.parentsHealthExpences
                  }
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
            </Grid>

            <SizedBox height={40} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="Donation"
                  type="text"
                  name="donation"
                  minRows={2}
                  placeholder="Upload donation receipts using the upload button below"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.donation}
                  error={formik.touched.donation && !!formik.errors.donation}
                  helperText={formik.touched.donation && formik.errors.donation}
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="Education Loan"
                  type="text"
                  name="educationLoan"
                  placeholder="Did you pay any interest on education loan for self, spouse or kids?"
                  minRows={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.educationLoan}
                  error={
                    formik.touched.educationLoan &&
                    !!formik.errors.educationLoan
                  }
                  helperText={
                    formik.touched.educationLoan && formik.errors.educationLoan
                  }
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
            </Grid>

            <SizedBox height={40} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="Disability"
                  type="text"
                  name="disability"
                  minRows={2}
                  placeholder="Do you have any family member with physical/ mental disabilities?"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.disability}
                  error={
                    formik.touched.disability && !!formik.errors.disability
                  }
                  helperText={
                    formik.touched.disability && formik.errors.disability
                  }
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  multiline={true}
                  label="Expenditure of prescribed ailments"
                  type="text"
                  name="expenditure"
                  minRows={2}
                  placeholder="Did you incur medical expenditure on certain types of ailments"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.expenditure}
                  error={
                    formik.touched.expenditure && !!formik.errors.expenditure
                  }
                  helperText={
                    formik.touched.expenditure && formik.errors.expenditure
                  }
                  disabled={isDisable || formik.isSubmitting}
                  fullWidth
                />
              </Grid>
            </Grid>

            <SizedBox height={30} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <DisplayFiles
                  {...{
                    // fileList,
                    // setFileList,
                    formikProps: formik,
                    type: 'Deductions',
                    buttonText:
                      (formik.values.fileList?.length || 0) === 0
                        ? 'Upload documents'
                        : 'Upload more documents',
                  }}
                ></DisplayFiles>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="otherComment"
                  variant="outlined"
                  multiline={true}
                  label="Enter your comments/notes here"
                  name="otherComment"
                  rows={4}
                  autoComplete={false}
                  value={formik.values.otherComment}
                  onChange={isDisabled || formik.handleChange}
                  fullWidth
                />
              </Grid> */}
            </Grid>

            <SizedBox height={40} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}></Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    disabled={isDisable || formik.isSubmitting}
                    color="primary"
                    onClick={isDisabled || formik.handleSubmit}
                  >
                    Save and Next
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
}
