import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  Card,
  Badge,
  Typography,
} from "@material-ui/core";
import { Field, Form, FieldArray, Formik } from "formik";

import SizedBox from "./../../SizedBox";
import CurrencyTextField from "../../CurrencyTextField";
import { IconButton } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import API from "../../../services/api";
import { getInteger } from "../../../Common/helperMethods";
import { format, parseISO } from "date-fns";

const DIRECTOR_API_URL = process.env.REACT_APP_UNLISTEDSHARE_CONTROLLER;
export default function UnlistedShares({
  setSelectedSubTab,
  client,
  showToast,
  isDisable
}) {
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["UnlistedSharesData", id],
    async () => {
      return API.get(DIRECTOR_API_URL + `GetUnlistedSharesDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
  const CustomInputComponent = ({ field, form: { touched }, ...props }) => {
    return (
      <div>
        <CurrencyTextField {...field} {...props} />
        {touched[field.name]}
      </div>
    );
  };

  const CustomTextComponent = ({ field, form: { touched }, ...props }) => (
    <div>
      <TextField {...field} {...props} />
      {touched[field.name]}
    </div>
  );

  const initialValues = {
    rentals:
      data && data.length > 0
        ? data.map((s) => {
            return {
              ...s,
              dateOfPurchase: format(
                parseISO(s.dateOfPurchase ?? new Date()),
                "yyyy-MM-dd"
              ),
            };
          })
        : [
            {
              nameOfCompany: "",
              pan: "",
              openingShares: 0,
              openingCostOfAcquisition: 0,
              acquiredShares: 0,
              dateOfPurchase: format(
                parseISO(new Date().toISOString()),
                "yyyy-MM-dd"
              ),
              acquiredFaceValue: 0,
              acquiredIssueValue: 0,
              acquiredPurchaseValue: 0,
              soldShares: 0,
              soldSaleConsideration: 0,
              closingShares: 0,
              closingCostAcquisition: 0,
              unlistedSharesId: "00000000-0000-0000-0000-000000000000",
            },
          ],
  };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const mappedData = values.rentals.map((t, index) => {
            return {
              ...t,
              openingShares: getInteger(t.openingShares),
              openingCostOfAcquisition: getInteger(t.openingCostOfAcquisition),
              acquiredShares: getInteger(t.acquiredShares),
              acquiredFaceValue: getInteger(t.acquiredFaceValue),
              acquiredIssueValue: getInteger(t.acquiredIssueValue),
              acquiredPurchaseValue: getInteger(t.acquiredPurchaseValue),
              soldShares: getInteger(t.soldShares),
              soldSaleConsideration: getInteger(t.soldSaleConsideration),
              closingShares: getInteger(t.closingShares),
              closingCostAcquisition: getInteger(t.closingCostAcquisition),
              id: index,
            };
          });

          if (mappedData.length > 25) {
            showToast({
              message: "You can add max 25 ",
              severity: "error",
            });
            return;
          }

          const input = { taxProfileId: id, unlistedSharesVM: mappedData };
          API.post(DIRECTOR_API_URL + `AddUnlistedSharesDetails`, {
            ...input,
          })
            .then((response) => {
              showToast({
                message: "Immovable asset submitted successfully!",
                severity: "success",
              });
              setSelectedSubTab(2);
              //navigate(`/income/filing/${id}/disclosures/immovable`);
            })
            .catch((err) => console.log("err", err));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="rentals">
              {({ insert, remove, push }) => (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  {values.rentals.length > 0 &&
                    values.rentals.map((rental, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Badge
                          key={index}
                          badgeContent={index + 1}
                          style={{ top: "14px" }}
                          color="primary"
                        ></Badge>
                        <Card
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                          variant="outlined"
                        >
                          <Grid
                            style={{
                              padding: 15,
                              borderColor: "red",
                              borderWidth: 1,
                              // flexDirection: "row",
                              // justifyContent: "space-between",
                              // display: "flex",
                            }}
                            container
                            key={index}
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    variant="outlined"
                                    label="Name Of Company"
                                    component={CustomTextComponent}
                                    name={`rentals.${index}.nameOfCompany`}
                                    type="text"
                                    fullWidth
                                    size="small"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.nameOfCompany`,
                                        e.target.value
                                      );
                                    }}
                                    value={rental.nameOfCompany}
                                    disabled={isDisable}
                                  />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    variant="outlined"
                                    label="PAN"
                                    component={CustomTextComponent}
                                    name={`rentals.${index}.pan`}
                                    type="text"
                                    fullWidth
                                    size="small"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.pan`,
                                        e.target.value
                                      );
                                    }}
                                    value={rental.pan}
                                    disabled={isDisable}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <SizedBox height={10} width={20}></SizedBox>

                            <Grid item xs={12} sm={12} md={12}>
                              <Typography>Opening Balance</Typography>
                              <SizedBox height={10} width={20}></SizedBox>
                              <Grid container spacing={3}>
                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    variant="outlined"
                                    label="Opening Number of Shares"
                                    fullWidth
                                    component={CustomInputComponent}
                                    name={`rentals.${index}.openingShares`}
                                    type="text"
                                    size="small"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.openingShares`,
                                        e.target.value
                                      );
                                    }}
                                    value={getInteger(rental.openingShares)}
                                    disabled={isDisable}
                                  />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    component={CustomInputComponent}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    label="Cost Of Acquisition"
                                    name={`rentals.${index}.openingCostOfAcquisition`}
                                    placeholder=""
                                    type="text"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.openingCostOfAcquisition`,
                                        e.target.value
                                      );
                                    }}
                                    value={getInteger(
                                      rental.openingCostOfAcquisition
                                    )}
                                    disabled={isDisable}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <SizedBox height={10} width={20}></SizedBox>

                            <Grid item xs={12} sm={12} md={12}>
                              <Typography>
                                Shares acquired during the year
                              </Typography>
                              <SizedBox height={10} width={20}></SizedBox>
                              <Grid container spacing={3}>
                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    component={CustomInputComponent}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    label="Number Of Shares"
                                    name={`rentals.${index}.acquiredShares`}
                                    placeholder=""
                                    type="text"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.acquiredShares`,
                                        e.target.value
                                      );
                                    }}
                                    value={getInteger(rental.acquiredShares)}
                                    disabled={isDisable}
                                  />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    component={CustomTextComponent}
                                    size="small"
                                    variant="outlined"
                                    label="Date Of Purchase"
                                    fullWidth
                                    value={rental.dateOfPurchase}
                                    name={`rentals.${index}.dateOfPurchase`}
                                    placeholder=""
                                    type="date"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.dateOfPurchase`,
                                        e.target.value
                                      );
                                    }}
                                    disabled={isDisable}
                                  />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    component={CustomInputComponent}
                                    size="small"
                                    variant="outlined"
                                    label="Face Value Per Share"
                                    value={getInteger(rental.acquiredFaceValue)}
                                    name={`rentals.${index}.acquiredFaceValue`}
                                    placeholder=""
                                    type="text"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.acquiredFaceValue`,
                                        e.target.value
                                      );
                                    }}
                                    disabled={isDisable}
                                  />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    component={CustomInputComponent}
                                    size="small"
                                    variant="outlined"
                                    label="Issue Price Per Share"
                                    value={getInteger(
                                      rental.acquiredIssueValue
                                    )}
                                    name={`rentals.${index}.acquiredIssueValue`}
                                    placeholder=""
                                    type="text"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.acquiredIssueValue`,
                                        e.target.value
                                      );
                                    }}
                                    disabled={isDisable}
                                  />
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                  <Field
                                    component={CustomInputComponent}
                                    size="small"
                                    variant="outlined"
                                    maximumValue="900000000"
                                    label="Purchase Price Per Share"
                                    value={getInteger(
                                      rental.acquiredPurchaseValue
                                    )}
                                    name={`rentals.${index}.acquiredPurchaseValue`}
                                    placeholder=""
                                    type="text"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rentals.${index}.acquiredPurchaseValue`,
                                        e.target.value
                                      );
                                    }}
                                    disabled={isDisable}
                                  />
                                </Grid>
                              </Grid>
                              <SizedBox height={10} width={20}></SizedBox>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography>
                                  Share sold or transferred during the FY
                                </Typography>
                                <SizedBox height={10} width={20}></SizedBox>
                                <Grid container spacing={3}>
                                  <Grid item xs={6} md={4} lg={3}>
                                    <Field
                                      component={CustomInputComponent}
                                      size="small"
                                      variant="outlined"
                                      maximumValue="900000000"
                                      label="Number Of Shares"
                                      value={getInteger(rental.soldShares)}
                                      name={`rentals.${index}.soldShares`}
                                      placeholder=""
                                      type="text"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `rentals.${index}.soldShares`,
                                          e.target.value
                                        );
                                      }}
                                      disabled={isDisable}
                                    />
                                  </Grid>

                                  <Grid item xs={6} md={4} lg={3}>
                                    <Field
                                      component={CustomInputComponent}
                                      size="small"
                                      variant="outlined"
                                      maximumValue="900000000"
                                      label="Sale Consideration"
                                      value={getInteger(
                                        rental.soldSaleConsideration
                                      )}
                                      name={`rentals.${index}.soldSaleConsideration`}
                                      placeholder=""
                                      type="text"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `rentals.${index}.soldSaleConsideration`,
                                          e.target.value
                                        );
                                      }}
                                      disabled={isDisable}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <SizedBox height={10} width={20}></SizedBox>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography>Closing Balance</Typography>
                                <SizedBox height={10} width={20}></SizedBox>
                                <Grid container spacing={3}>
                                  <Grid item xs={6} md={4} lg={3}>
                                    <Field
                                      component={CustomInputComponent}
                                      size="small"
                                      variant="outlined"
                                      maximumValue="900000000"
                                      label="Number of shares"
                                      value={getInteger(rental.closingShares)}
                                      name={`rentals.${index}.closingShares`}
                                      placeholder=""
                                      type="text"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `rentals.${index}.closingShares`,
                                          e.target.value
                                        );
                                      }}
                                      disabled={isDisable}
                                    />
                                  </Grid>
                                  <Grid item xs={6} md={4} lg={3}>
                                    <Field
                                      component={CustomInputComponent}
                                      size="small"
                                      variant="outlined"
                                      maximumValue="900000000"
                                      label="Cost Of Acquisition"
                                      value={getInteger(
                                        rental.closingCostAcquisition
                                      )}
                                      name={`rentals.${index}.closingCostAcquisition`}
                                      placeholder=""
                                      type="text"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `rentals.${index}.closingCostAcquisition`,
                                          e.target.value
                                        );
                                      }}
                                      disabled={isDisable}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                        <div
                          style={{
                            justifyContent: "flex-end",
                            position: "relative",
                            top: -11,
                            left: -19,
                          }}
                        >
                          <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            aria-controls="profile-menu"
                            style={{ padding: 6 }}
                            onClick={() => remove(index)}
                            disabled={isDisable}
                          >
                            <CloseOutlined />
                          </IconButton>
                        </div>
                      </div>
                    ))}

                  <SizedBox height={30} width={20}></SizedBox>
                  <Grid
                    container
                    spacing={1}
                    columns={12}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginBottom: "30px" }}
                  >
                    <Grid item xs={6} sm={6} md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          if (values.rentals.length > 25) {
                            showToast({
                              message: "You can add max 25 asset",
                              severity: "error",
                            });
                            return;
                          }
                          push({
                            nameOfInstistute: "",
                            typeOfAccount: "",
                            accountNumber: "",
                            address: "",
                            dateOfPurchase: format(
                              parseISO(new Date().toISOString()),
                              "yyyy-MM-dd"
                            ),
                            accountOpeningDate: "",
                            priorYearValues: 0,
                            priorYearClosingBalance: 0,
                            currentMaximumValues: 0,
                            currentClosingBalance: 0,
                          });
                        }}
                        disabled={isDisable}
                      >
                        Add more
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        //disabled={values.rentals.length === 0}
                        color="primary"
                      >
                        Save and Next
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>

      {/* </form> */}
    </div>
  );
}
