import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import React from "react";
import CurrencyTextField from "../../CurrencyTextField/CurrencyTextField";
import { Formik } from "formik";
import API from "../../../services/api";
import DisplayFiles from "../../Common/displayFiles";
import { useToastDisplay } from "../../../Common/useToastDisplay";
import SizedBox from "../../SizedBox";
import { useParams } from "react-router-dom";
import { getInteger } from "../../../Common/helperMethods";

const API_URL = process.env.REACT_APP_ADVISOR_CONTROLLER;

export default function AdvisorSide({ data }) {
  const showToast = useToastDisplay();
  const { id } = useParams();
  const formValues = data
    ? {
        ...data,
        taxProfileId: id,
      }
    : {
        isTaxPayable: false,
        taxPaidAmount: 0,
        fileList: [],
        taxProfileId: id,
      };

  return (
    <Formik
      initialValues={formValues}
      onSubmit={async (values, actions) => {
        API.post(API_URL + "UploadTaxSummary", {
          ...values,
          taxProfileId: id,
          taxPayable:
            values.isTaxPayable 
              ? getInteger(values.taxPayable)
              : 0,
        }).then((response) => {
          showToast({
            message: "Tax summary shared with client",
            severity: "success",
          });
        });
      }}
    >
      {(formikProps) => (
        <>
          <SizedBox height={20}></SizedBox>
          <h3>Please upload tax summary</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={formikProps.values.isTaxPayable}
                  onChange={() => {
                    formikProps.setFieldValue(
                      "isTaxPayable",
                      !formikProps.values.isTaxPayable
                    );
                    formikProps.setFieldValue("taxPayable", 0);
                  }}
                  name="isTaxPayable"
                />
              }
              label="Is Tax Payable"
            />
            <CurrencyTextField
              id="taxPayable"
              name="taxPayable"
              label="Amount of Tax Payable"
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
              value={formikProps.values.taxPayable}
              error={
                formikProps.touched.taxPayable &&
                !!formikProps.errors.taxPayable
              }
              helperText={
                formikProps.touched.taxPayable && formikProps.errors.taxPayable
              }
              disabled={
                formikProps.isSubmitting || !formikProps.values.isTaxPayable
              }
            />
          </div>
          <SizedBox height={20}></SizedBox>
          <DisplayFiles
            key="taxSummary"
            {...{
              formikProps,
              type: "Summary",
              buttonText: "Upload Tax Summary",
            }}
          ></DisplayFiles>
          <SizedBox height={30} width={10}></SizedBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  disabled={formikProps.isSubmitting}
                  color="primary"
                  onClick={formikProps.handleSubmit}
                >
                  Save and Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
