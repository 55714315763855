import React from "react";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  IconButton,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import AppRoutes from "./components/AppRoutes";
import Themes from "../src/Common/themes";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SnackComponent({ showToast, handleSnackClose }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Snackbar
      anchorOrigin={{
        vertical: matches ? "bottom" : "top",
        horizontal: matches ? "center" : "right",
      }}
      open={Boolean(showToast && showToast.message)}
      autoHideDuration={6000}
      onClose={handleSnackClose}
    >
      {showToast && showToast.message ? (
        <Alert severity={showToast.severity}>
          {showToast.message}&nbsp;&nbsp;
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackClose}
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Alert>
      ) : null}
    </Snackbar>
  );
}

//export function AppContext = createContext <{
//    setShowToast
//} > ({
//    setShowToast: () => { },
//});

export const AppContext = React.createContext({
  selectedFY: {},
  setSelectedFY: () => {},
  selectedClient: {},
  setSelectedClient: () => {},
  setShowToast: () => {},
});

//{
//    severity: 'info' | 'error' | 'warning' | 'success',
//        message: ''
//}
export default function App() {
  const queryClient = new QueryClient();
  const [showToast, setShowToast] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedFY, setSelectedFY] = useState(null);

  const handleSnackClose = (event) => {
    //if (reason === 'clickaway') {
    //    return;
    //}
    setShowToast(null);
  };

  useEffect(() => {
    const selectedClient = localStorage.getItem("selectedClient");
    if (selectedClient) {
      setSelectedClient(JSON.parse(selectedClient));
    }
    const selectedFY = localStorage.getItem("selectedFY");
    if (selectedFY) {
      setSelectedFY(JSON.parse(selectedFY));
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{
          selectedClient,
          setSelectedClient,
          selectedFY,
          setSelectedFY,
          setShowToast,
        }}
      >
        <ThemeProvider theme={Themes.default}>
          <BrowserRouter>
            <AppRoutes />
            <SnackComponent
              {...{
                showToast,
                handleSnackClose,
              }}
            />
          </BrowserRouter>
        </ThemeProvider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}
