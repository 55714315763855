import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  FormControl,
  MenuItem,
  Button,
  TextField,
  Divider,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import SizedBox from '../SizedBox';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../services/api';
import CurrencyTextField from '../CurrencyTextField';
import { getInteger } from '../../Common/helperMethods';
import HelpText from '../../Common/helpText';
import {
  useSelectedClient,
  useSelectedFY,
} from '../../Common/useSelectedClient';
import ServiceType from '../../Common/ServiceType';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  select: {
    height: 40,
  },
}));

function MyAnnualProfile({ showToast, role, allProfileData, service }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const API_URL = process.env.REACT_APP_TAXPROFILE_CONTROLLER;
  //const CURRENT_AY = process.env.REACT_APP_AY;
  const selectedFy = useSelectedFY();
  const { id } = useParams();
  const isIncomeTax = location.pathname.indexOf('incomeprofile') > -1;
  const profile = allProfileData.find(
    (s) => s.userProfileId === id && s.fy === selectedFy
  );
  const { data, isLoading } = useQuery(
    ['ANNUAL_PROFILE', profile.taxProfileId],
    async () => {
      return API.get(API_URL + `GetTaxProfileById/${profile.taxProfileId}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  const formValues = data
    ? {
        ...data,
        residentialStatus:
          data.residentialStatus == '0' ? '' : data.residentialStatus,
        coveredUnderPresumptiveTaxation:
          data.coveredUnderPresumptiveTaxation == '0'
            ? '0'
            : data.coveredUnderPresumptiveTaxation,
        service: service,
      }
    : {
        fy: selectedFy,
        taxableIncomeSalary: '',
        taxProfileId: profile.taxProfileId,
        residentialStatus: '',
        coveredUnderPresumptiveTaxation: '0',
        isSeniorCitizen: '',
        taxSlabRate: '',
        service: service,
      };
  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: 18, color: '#363636' }}>
          {isIncomeTax ? 'Residential Status' : 'My Profile Annual'}
        </p>
      </div>
      <Divider
        style={{ color: '#EAEAEC', borderRadius: 2 }}
        variant="fullWidth"
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Formik
          initialValues={formValues}
          onSubmit={async (values, actions) => {
            API.post(API_URL + 'UpdateTaxProfile', {
              ...values,
              taxableIncomeSalary: getInteger(values.taxableIncomeSalary),
              taxSlabRate: parseFloat(values.taxSlabRate),
              isSeniorCitizen: values.isSeniorCitizen === 'true' ? true : false,
            }).then((response) => {
              showToast({
                message: !isIncomeTax
                  ? 'Annual profile submitted successfully!'
                  : 'Residential status submitted successfully!',
                severity: 'success',
              });
              navigate('/advance-tax');
            });
          }}
          validationSchema={Yup.object({
            fy: Yup.string().required('FY is a required field'),
            // taxableIncomeSalary: Yup.string().when('service', (service) => {
            //   if (service === ServiceType.ADVANCETAX) {
            //     return Yup.string().required(
            //       'Estimated annual taxable salary is a required field'
            //     );
            //   }
            // }),
            // coveredUnderPresumptiveTaxation: Yup.string().when(
            //   'service',
            //   (service) => {
            //     if (service === ServiceType.ADVANCETAX) {
            //       return Yup.string().required(
            //         'Presumptive taxation is a required field'
            //       );
            //     }
            //   }
            // ),
            citizenship: Yup.string().required(
              'Citizenship is a required field'
            ),
            residentialStatus: Yup.string().required(
              'Residential status is a required field'
            ),
          })}
        >
          {(formikProps) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <SizedBox height={25} width={25}></SizedBox>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="fy"
                        select
                        label="Financial Year"
                        variant="outlined"
                        size="small"
                        disabled={true}
                        error={
                          formikProps.touched.fy && !!formikProps.errors.fy
                        }
                        helperText={
                          formikProps.touched.fy && formikProps.errors.fy
                        }
                        value={formikProps.values.fy}
                        onChange={(e) => {
                          formikProps.setFieldValue('fy', e.target.value);
                        }}
                      >
                        <MenuItem value="2024-25">
                            <em>FY 2024-25</em>
                        </MenuItem>
                        <MenuItem value="2023-24">
                          <em>FY 2023-24</em>
                        </MenuItem>
                        <MenuItem value="2022-23">
                          <em>FY 2022-23</em>
                        </MenuItem>
                        <MenuItem value="2021-22">
                          <em>FY 2021-22</em>
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  {service === ServiceType.ADVANCETAX && (
                    <>
                      <SizedBox height={25} width={25}></SizedBox>
                      <Grid item xs={12} sm={12} md={12}>
                        <CurrencyTextField
                          id="taxableIncomeSalary"
                          label="Estimated annual taxable salary"
                          fullWidth
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.taxableIncomeSalary}
                          error={
                            formikProps.touched.taxableIncomeSalary &&
                            !!formikProps.errors.taxableIncomeSalary
                          }
                          helperText={
                            formikProps.touched.taxableIncomeSalary &&
                            formikProps.errors.taxableIncomeSalary
                          }
                          disabled={formikProps.isSubmitting}
                        />
                        <HelpText>Round off to nearest 1 lakh</HelpText>
                      </Grid>
                    </>
                  )}
                  {role !== 'Client' &&
                    service === ServiceType.ADVANCETAX &&
                    getInteger(formikProps.values.taxableIncomeSalary) <=
                      1000000 && (
                      <Grid item xs={12} sm={12} md={12}>
                        <SizedBox height={25} width={25}></SizedBox>
                        <CurrencyTextField
                          name="taxSlabRate"
                          label="Enter tax slab rate"
                          fullWidth
                          minimumValue="0"
                          maximumValue="100"
                          decimalPlaces="2"
                          onChange={formikProps.handleChange}
                          value={formikProps.values.taxSlabRate}
                          onBlur={formikProps.handleBlur}
                          error={
                            formikProps.touched.taxSlabRate &&
                            !!formikProps.errors.taxSlabRate
                          }
                          helperText={
                            formikProps.touched.taxSlabRate &&
                            formikProps.errors.taxSlabRate
                          }
                          disabled={formikProps.isSubmitting}
                        />
                      </Grid>
                    )}
                  <SizedBox height={25} width={25}></SizedBox>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="citizenship"
                        name="citizenship"
                        select
                        label="Citizenship"
                        variant="outlined"
                        size="small"
                        error={
                          formikProps.touched.citizenship &&
                          !!formikProps.errors.citizenship
                        }
                        helperText={
                          formikProps.touched.citizenship &&
                          formikProps.errors.citizenship
                        }
                        value={formikProps.values.citizenship}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            'citizenship',
                            e.target.value
                          );
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Citizenship</em>
                        </MenuItem>
                        <MenuItem value="Indian">
                          <em>Indian</em>
                        </MenuItem>
                        <MenuItem value="GreenCard">
                          <em>Indian with US Green Card</em>
                        </MenuItem>
                        <MenuItem value="US">
                          <em>US citizen</em>
                        </MenuItem>
                        <MenuItem value="Other">
                          <em>Other citizen</em>
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>

                  <SizedBox height={25} width={25}></SizedBox>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="residentialStatus"
                        select
                        label="Residetial Status"
                        variant="outlined"
                        size="small"
                        error={
                          formikProps.touched.residentialStatus &&
                          !!formikProps.errors.residentialStatus
                        }
                        helperText={
                          formikProps.touched.residentialStatus &&
                          formikProps.errors.residentialStatus
                        }
                        value={formikProps.values.residentialStatus}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            'residentialStatus',
                            e.target.value
                          );
                        }}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value="1">
                          <em>Ordinarily Resident</em>
                        </MenuItem>
                        <MenuItem value="2">
                          <em>Not ordinarily resident</em>
                        </MenuItem>
                        <MenuItem value="3">
                          <em>Non-resident</em>
                        </MenuItem>
                      </TextField>
                      <HelpText>
                        Select Ordinarily resident, if you have been generally
                        in India
                      </HelpText>
                    </FormControl>
                  </Grid>

                  {service === ServiceType.ADVANCETAX && (
                    <>
                      <SizedBox height={25} width={25}></SizedBox>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            id="presumptiveTaxation"
                            select
                            label="Presumptive Taxation"
                            variant="outlined"
                            size="small"
                            error={
                              formikProps.touched
                                .coveredUnderPresumptiveTaxation &&
                              !!formikProps.errors
                                .coveredUnderPresumptiveTaxation
                            }
                            helperText={
                              formikProps.touched
                                .coveredUnderPresumptiveTaxation &&
                              formikProps.errors.coveredUnderPresumptiveTaxation
                            }
                            value={
                              formikProps.values.coveredUnderPresumptiveTaxation
                            }
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                'coveredUnderPresumptiveTaxation',
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            <MenuItem value="1">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="2">
                              <em>44AD</em>
                            </MenuItem>
                            <MenuItem value="3">
                              <em>44ADA</em>
                            </MenuItem>
                          </TextField>
                          <HelpText>
                            Select None if you do not have business income
                          </HelpText>
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  {role !== 'Client' && service === ServiceType.ADVANCETAX && (
                    <Grid item xs={12} sm={12} md={12}>
                      <SizedBox height={25} width={25}></SizedBox>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          id="seniorCitizen"
                          select
                          label="Senior Citizen"
                          variant="outlined"
                          size="small"
                          error={
                            formikProps.touched.isSeniorCitizen &&
                            !!formikProps.errors.isSeniorCitizen
                          }
                          helperText={
                            formikProps.touched.isSeniorCitizen &&
                            formikProps.errors.isSeniorCitizen
                          }
                          value={formikProps.values.isSeniorCitizen}
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              'isSeniorCitizen',
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>

                          <MenuItem value="true">
                            <em>Yes</em>
                          </MenuItem>
                          <MenuItem value="false">
                            <em>No</em>
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}
                  <SizedBox height={40} width={10}></SizedBox>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save And Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}
export default MyAnnualProfile;
