import { AppContext } from '../App';
import { useContext } from 'react';

export function useSelectedClient() {
  const { selectedClient } = useContext(AppContext);
  return selectedClient;
}

export function useUpdateSelectedClient() {
  const { setSelectedClient } = useContext(AppContext);
  function setClient(client) {
    localStorage.setItem('selectedClient', JSON.stringify(client));
    setSelectedClient(client);
  }

  return setClient;
}

export function useSelectedFY() {
  const { selectedFY } = useContext(AppContext);
  return selectedFY;
}

export function useUpdateSelectedFY() {
  const { setSelectedFY } = useContext(AppContext);
  function setFY(fy) {
    localStorage.setItem('selectedFY', JSON.stringify(fy));
    setSelectedFY(fy);
  }

  return setFY;
}
