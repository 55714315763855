const ProfileStatus = {
  Unknown: { id: 0, name: "All" },
  Pipeline: { id: 1, name: "Pipeline" },
  Initiated: { id: 2, name: "Initiated" },
  Assigned: { id: 3, name: "Assigned" },
  InProcess: { id: 4, name: "In Process" },
  PendingFromClient: { id: 5, name: "Pending From Client" },
  PendingForInternalReview: { id: 6, name: "Pending For Internal Review" },
  TaxSummaryPrepared: { id: 7, name: "Tax Summary Prepared" },
  TaxSummaryPayable: { id: 8, name: "Tax Summary Payable" },
  TaxSummaryRejected: { id: 9, name: "Tax Summary Rejected" },//QueryRaised
  TaxSummaryApproved: { id: 10, name: "Tax Summary Approved" },
  ReadyToEfile: { id: 11, name: "Ready To Efile" },
  Efile: { id: 12, name: "Efile" },
  EVerified: { id: 13, name: "E-Verified" },
};

export default ProfileStatus;
