import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  TextField,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import SizedBox from "../SizedBox";
import CurrencyTextField from "../CurrencyTextField";
import { Formik } from "formik";
import API from "../../services/api";
import { getInteger } from "../../Common/helperMethods";
import { Alert, AlertTitle } from "@material-ui/lab";

const QTR_DEDUCTION_API_URL = process.env.REACT_APP_DEDUCTIONDETAILS_CONTROLLER;

function Deduction({ setSelectedTab, client, showToast, isOtherTabVisible }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["Deduction_Data", id],
    async () => {
      return API.get(QTR_DEDUCTION_API_URL + `GetDeductionDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  const isDisabled = client?.isDisabled; 

  const deductionData = data
    ? data
    : {
        prevSection80C: 0,
        prevSection80CCC: 0,
        prevSection80CCDEmployeeOrSE: 0,
        prevSection80CCD1B: 0,
        prevSection80CCDEmployer: 0,
        prevSection80D: 0,
        prevSection80DD: 0,
        prevSection80DDB: 0,
        prevSection80E: 0,
        prevSection80EE: 0,
        prevSection80EEA: 0,
        prevSection80EEB: 0,
        prevSection80G: 0,
        prevSection80GG: 0,
        prevSection80GGA: 0,
        prevSection80GGC: 0,
        prevSection80U: 0,
        prevSection80RRB: 0,
        prevSection80QQB: 0,
        prevSection80TTA: 0,
        prevSection80TTB: 0,

        curSection80C: 0,
        curSection80CCC: 0,
        curSection80CCDEmployeeOrSE: 0,
        curSection80CCD1B: 0,
        curSection80CCDEmployer: 0,
        curSection80D: 0,
        curSection80DD: 0,
        curSection80DDB: 0,
        curSection80E: 0,
        curSection80EE: 0,
        curSection80EEA: 0,
        curSection80EEB: 0,
        curSection80G: 0,
        curSection80GG: 0,
        curSection80GGA: 0,
        curSection80GGC: 0,
        curSection80U: 0,
        curSection80RRB: 0,
        curSection80QQB: 0,
        curSection80TTA: 0,
        curSection80TTB: 0,
        comment: "",
        deductionId: "00000000-0000-0000-0000-000000000000",
        taxQtrProfileId : id
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={deductionData}
        // validationSchema={Yup.object({
        //   fD_TDS: Yup.string().test({
        //     name: "max",
        //     exclusive: true,
        //     params: {},
        //     message: "TDS should not exceed 10% of interest",
        //     test: function (value) {
        //       // You can access the price field with `this.parent`.
        //       console.log("vald", value, this.parent.fd);
        //       return getInteger(value) <= getInteger(this.parent.fd) * 0.1;
        //     },
        //   }),
        // })}
        onSubmit={async (values, actions) => {
          const result = {};

          for (const key in values) {
            if (key.indexOf("Section") > -1 && values.hasOwnProperty(key)) {
              result[key] = getInteger(values[key]);
            } else {
              result[key] = values[key];
            }
          }
          API.post(QTR_DEDUCTION_API_URL + "AddDeductionDetails", result).then(
            (response) => {
              showToast({
                message: "Deductions submitted successfully!",
                severity: "success",
              });

              setSelectedTab(7);
              navigate(`/advance-tax/quarter/${id}/summary`);
            }
          );
        }}
      >
        {(formikProps) => (
          <>
            <SizedBox height={10} width={10}></SizedBox>
            <Grid container>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>
                    Please enter the estimated annual income amounts.
                  </AlertTitle>
                </Alert>
              </Grid>
            </Grid>
            <SizedBox height={40} width={10}></SizedBox>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80C"
                      label="Previous Section 80C"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80C}
                      error={
                        formikProps.touched.prevSection80C &&
                        !!formikProps.errors.prevSection80C
                      }
                      helperText={
                        formikProps.touched.prevSection80C &&
                        formikProps.errors.prevSection80C
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80C"
                      label="Current Section 80C"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80C}
                      error={
                        formikProps.touched.curSection80C &&
                        !!formikProps.errors.curSection80C
                      }
                      helperText={
                        formikProps.touched.curSection80C &&
                        formikProps.errors.curSection80C
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80CCC"
                      label="Previous Section 80CCC"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80CCC}
                      error={
                        formikProps.touched.prevSection80CCC &&
                        !!formikProps.errors.prevSection80CCC
                      }
                      helperText={
                        formikProps.touched.prevSection80CCC &&
                        formikProps.errors.prevSection80CCC
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80CCC"
                      label="Current Section 80CCC"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80CCC}
                      error={
                        formikProps.touched.curSection80CCC &&
                        !!formikProps.errors.curSection80CCC
                      }
                      helperText={
                        formikProps.touched.curSection80CCC &&
                        formikProps.errors.curSection80CCC
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80CCDEmployeeOrSE"
                      label="Previous Section 80CCD Employee Or SE"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80CCDEmployeeOrSE}
                      error={
                        formikProps.touched.prevSection80CCDEmployeeOrSE &&
                        !!formikProps.errors.prevSection80CCDEmployeeOrSE
                      }
                      helperText={
                        formikProps.touched.prevSection80CCDEmployeeOrSE &&
                        formikProps.errors.prevSection80CCDEmployeeOrSE
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80CCDEmployeeOrSE"
                      label="Current Section  80CCD Employee Or SE"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80CCDEmployeeOrSE}
                      error={
                        formikProps.touched.curSection80CCDEmployeeOrSE &&
                        !!formikProps.errors.curSection80CCDEmployeeOrSE
                      }
                      helperText={
                        formikProps.touched.curSection80CCDEmployeeOrSE &&
                        formikProps.errors.curSection80CCDEmployeeOrSE
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80CCD1B"
                      label="Previous Section 80CCD1B"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80CCD1B}
                      error={
                        formikProps.touched.prevSection80CCD1B &&
                        !!formikProps.errors.prevSection80CCD1B
                      }
                      helperText={
                        formikProps.touched.prevSection80CCD1B &&
                        formikProps.errors.prevSection80CCD1B
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80CCD1B"
                      label="Current Section 80CCD1B"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80CCD1B}
                      error={
                        formikProps.touched.curSection80CCD1B &&
                        !!formikProps.errors.curSection80CCD1B
                      }
                      helperText={
                        formikProps.touched.curSection80CCD1B &&
                        formikProps.errors.curSection80CCD1B
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80CCDEmployer"
                      label="Previous Section 80CCD Employer"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80CCDEmployer}
                      error={
                        formikProps.touched.prevSection80CCDEmployer &&
                        !!formikProps.errors.prevSection80CCDEmployer
                      }
                      helperText={
                        formikProps.touched.prevSection80CCDEmployer &&
                        formikProps.errors.prevSection80CCDEmployer
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80CCDEmployer"
                      label="Current Section 80CCD Employer"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80CCDEmployer}
                      error={
                        formikProps.touched.curSection80CCDEmployer &&
                        !!formikProps.errors.curSection80CCDEmployer
                      }
                      helperText={
                        formikProps.touched.curSection80CCDEmployer &&
                        formikProps.errors.curSection80CCDEmployer
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80D"
                      label="Previous Section 80D"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80D}
                      error={
                        formikProps.touched.prevSection80D &&
                        !!formikProps.errors.prevSection80D
                      }
                      helperText={
                        formikProps.touched.prevSection80D &&
                        formikProps.errors.prevSection80D
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80D"
                      label="Current Section 80D"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80D}
                      error={
                        formikProps.touched.curSection80D &&
                        !!formikProps.errors.curSection80D
                      }
                      helperText={
                        formikProps.touched.curSection80D &&
                        formikProps.errors.curSection80D
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80DD"
                      label="Previous Section 80DD"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80DD}
                      error={
                        formikProps.touched.prevSection80DD &&
                        !!formikProps.errors.prevSection80DD
                      }
                      helperText={
                        formikProps.touched.prevSection80DD &&
                        formikProps.errors.prevSection80DD
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80DD"
                      label="Current Section 80DD"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80DD}
                      error={
                        formikProps.touched.curSection80DD &&
                        !!formikProps.errors.curSection80DD
                      }
                      helperText={
                        formikProps.touched.curSection80DD &&
                        formikProps.errors.curSection80DD
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80DDB"
                      label="Previous Section 80DDB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80DDB}
                      error={
                        formikProps.touched.prevSection80DDB &&
                        !!formikProps.errors.prevSection80DDB
                      }
                      helperText={
                        formikProps.touched.prevSection80DDB &&
                        formikProps.errors.prevSection80DDB
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80DDB"
                      label="Current Section 80DDB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80DDB}
                      error={
                        formikProps.touched.curSection80DDB &&
                        !!formikProps.errors.curSection80DDB
                      }
                      helperText={
                        formikProps.touched.curSection80DDB &&
                        formikProps.errors.curSection80DDB
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80E"
                      label="Previous Section 80E"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80E}
                      error={
                        formikProps.touched.prevSection80E &&
                        !!formikProps.errors.prevSection80E
                      }
                      helperText={
                        formikProps.touched.prevSection80E &&
                        formikProps.errors.prevSection80E
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80E"
                      label="Current Section 80E"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80E}
                      error={
                        formikProps.touched.curSection80E &&
                        !!formikProps.errors.curSection80E
                      }
                      helperText={
                        formikProps.touched.curSection80E &&
                        formikProps.errors.curSection80E
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80EE"
                      label="Previous Section 80EE"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80EE}
                      error={
                        formikProps.touched.prevSection80EE &&
                        !!formikProps.errors.prevSection80EE
                      }
                      helperText={
                        formikProps.touched.prevSection80EE &&
                        formikProps.errors.prevSection80EE
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80EE"
                      label="Current Section 80EE"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80EE}
                      error={
                        formikProps.touched.curSection80EE &&
                        !!formikProps.errors.curSection80EE
                      }
                      helperText={
                        formikProps.touched.curSection80EE &&
                        formikProps.errors.curSection80EE
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80EEA"
                      label="Previous Section 80EEA"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80EEA}
                      error={
                        formikProps.touched.prevSection80EEA &&
                        !!formikProps.errors.prevSection80EEA
                      }
                      helperText={
                        formikProps.touched.prevSection80EEA &&
                        formikProps.errors.prevSection80EEA
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80EEA"
                      label="Current Section 80EEA"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80EEA}
                      error={
                        formikProps.touched.curSection80EEA &&
                        !!formikProps.errors.curSection80EEA
                      }
                      helperText={
                        formikProps.touched.curSection80EEA &&
                        formikProps.errors.curSection80EEA
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80EEB"
                      label="Previous Section 80EEB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80EEB}
                      error={
                        formikProps.touched.prevSection80EEB &&
                        !!formikProps.errors.prevSection80EEB
                      }
                      helperText={
                        formikProps.touched.prevSection80EEB &&
                        formikProps.errors.prevSection80EEB
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80EEB"
                      label="Current Section 80EEB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80EEB}
                      error={
                        formikProps.touched.curSection80EEB &&
                        !!formikProps.errors.curSection80EEB
                      }
                      helperText={
                        formikProps.touched.curSection80EEB &&
                        formikProps.errors.curSection80EEB
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80G"
                      label="Previous Section 80G"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80G}
                      error={
                        formikProps.touched.prevSection80G &&
                        !!formikProps.errors.prevSection80G
                      }
                      helperText={
                        formikProps.touched.prevSection80G &&
                        formikProps.errors.prevSection80G
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80G"
                      label="Current Section 80G"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80G}
                      error={
                        formikProps.touched.curSection80G &&
                        !!formikProps.errors.curSection80G
                      }
                      helperText={
                        formikProps.touched.curSection80G &&
                        formikProps.errors.curSection80G
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80GG"
                      label="Previous Section 80GG"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80GG}
                      error={
                        formikProps.touched.prevSection80GG &&
                        !!formikProps.errors.prevSection80GG
                      }
                      helperText={
                        formikProps.touched.prevSection80GG &&
                        formikProps.errors.prevSection80GG
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80GG"
                      label="Current Section 80GG"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80GG}
                      error={
                        formikProps.touched.curSection80GG &&
                        !!formikProps.errors.curSection80GG
                      }
                      helperText={
                        formikProps.touched.curSection80GG &&
                        formikProps.errors.curSection80GG
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80GGA"
                      label="Previous Section 80GGA"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80GGA}
                      error={
                        formikProps.touched.prevSection80GGA &&
                        !!formikProps.errors.prevSection80GGA
                      }
                      helperText={
                        formikProps.touched.prevSection80GGA &&
                        formikProps.errors.prevSection80GGA
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80GGA"
                      label="Current Section 80GGA"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80GGA}
                      error={
                        formikProps.touched.curSection80GGA &&
                        !!formikProps.errors.curSection80GGA
                      }
                      helperText={
                        formikProps.touched.curSection80GGA &&
                        formikProps.errors.curSection80GGA
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80GGC"
                      label="Previous Section 80GGC"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80GGC}
                      error={
                        formikProps.touched.prevSection80GGC &&
                        !!formikProps.errors.prevSection80GGC
                      }
                      helperText={
                        formikProps.touched.prevSection80GGC &&
                        formikProps.errors.prevSection80GGC
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80GGC"
                      label="Current Section 80GGC"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80GGC}
                      error={
                        formikProps.touched.curSection80GGC &&
                        !!formikProps.errors.curSection80GGC
                      }
                      helperText={
                        formikProps.touched.curSection80GGC &&
                        formikProps.errors.curSection80GGC
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80U"
                      label="Previous Section 80U"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80U}
                      error={
                        formikProps.touched.prevSection80U &&
                        !!formikProps.errors.prevSection80U
                      }
                      helperText={
                        formikProps.touched.prevSection80U &&
                        formikProps.errors.prevSection80U
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80U"
                      label="Current Section 80U"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80U}
                      error={
                        formikProps.touched.curSection80U &&
                        !!formikProps.errors.curSection80U
                      }
                      helperText={
                        formikProps.touched.curSection80U &&
                        formikProps.errors.curSection80U
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80RRB"
                      label="Previous Section 80RRB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80RRB}
                      error={
                        formikProps.touched.prevSection80RRB &&
                        !!formikProps.errors.prevSection80RRB
                      }
                      helperText={
                        formikProps.touched.prevSection80RRB &&
                        formikProps.errors.prevSection80RRB
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80RRB"
                      label="Current Section 80RRB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80RRB}
                      error={
                        formikProps.touched.curSection80RRB &&
                        !!formikProps.errors.curSection80RRB
                      }
                      helperText={
                        formikProps.touched.curSection80RRB &&
                        formikProps.errors.curSection80RRB
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80QQB"
                      label="Previous Section 80QQB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80QQB}
                      error={
                        formikProps.touched.prevSection80QQB &&
                        !!formikProps.errors.prevSection80QQB
                      }
                      helperText={
                        formikProps.touched.prevSection80QQB &&
                        formikProps.errors.prevSection80QQB
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80QQB"
                      label="Current Section 80QQB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80QQB}
                      error={
                        formikProps.touched.curSection80QQB &&
                        !!formikProps.errors.curSection80QQB
                      }
                      helperText={
                        formikProps.touched.curSection80QQB &&
                        formikProps.errors.curSection80QQB
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80TTA"
                      label="Previous Section 80TTA"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80TTA}
                      error={
                        formikProps.touched.prevSection80TTA &&
                        !!formikProps.errors.prevSection80TTA
                      }
                      helperText={
                        formikProps.touched.prevSection80TTA &&
                        formikProps.errors.prevSection80TTA
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80TTA"
                      label="Current Section 80TTA"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80TTA}
                      error={
                        formikProps.touched.curSection80TTA &&
                        !!formikProps.errors.curSection80TTA
                      }
                      helperText={
                        formikProps.touched.curSection80TTA &&
                        formikProps.errors.curSection80TTA
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="prevSection80TTB"
                      label="Previous Section 80TTB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.prevSection80TTB}
                      error={
                        formikProps.touched.prevSection80TTB &&
                        !!formikProps.errors.prevSection80TTB
                      }
                      helperText={
                        formikProps.touched.prevSection80TTB &&
                        formikProps.errors.prevSection80TTB
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="curSection80TTB"
                      label="Current Section 80TTB"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.curSection80TTB}
                      error={
                        formikProps.touched.curSection80TTB &&
                        !!formikProps.errors.curSection80TTB
                      }
                      helperText={
                        formikProps.touched.curSection80TTB &&
                        formikProps.errors.curSection80TTB
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="comment"
                      variant="outlined"
                      multiline={true}
                      label="Enter your comments/notes here"
                      type="text"
                      name="comment"
                      minRows={4}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.comment}
                      error={
                        formikProps.touched.comment &&
                        !!formikProps.errors.comment
                      }
                      helperText={
                        formikProps.touched.comment &&
                        formikProps.errors.comment
                      }
                      disabled={isDisabled || formikProps.isSubmitting}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}></Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        disabled={isDisabled || formikProps.isSubmitting}
                        color="primary"
                        onClick={formikProps.handleSubmit}
                      >
                        Save and Next
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
}
export default Deduction;
