import React from 'react';
import { useQuery } from "react-query";
import API from "../../services/api";
import { Button, CircularProgress, Grid, Modal } from "@material-ui/core";
import AddFamilyProfile from "../../components/Profile/AddFamilyProfile";
import { Alert } from "@material-ui/lab";
import DisplayFilingClient from "../../components/Dashboard/DisplayFilingClient";
import SizedBox from "../../components/SizedBox";
import ServiceType from "../../Common/ServiceType";

const API_URL = process.env.REACT_APP_USERPROFILE_CONTROLLER;
const CURRENT_FY = process.env.REACT_APP_FY;

export default function UserList({ fy, id, openAddMember, setAddMemberOpen }) {
  const { data, isLoading,refetch } = useQuery(
    ["ALL_USER_INCOME_PROFILE", id],
    async () => {
      return API.get(
        API_URL +
          `GetAllIncomeUsers/${id ?? "00000000-0000-0000-0000-000000000000"}`
      )
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  const selfRecord = data ? data.find((t) => t.accountType == "1") : [];
  const firstName = selfRecord && selfRecord?.firstName;

  /*<div style={{ paddingLeft: 80, marginTop: 32 }}>*/
  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid item md={1}></Grid>
      <Grid item xs={12} sm={12} md={10}>
        <Grid style={{ marginBottom: "30px" }}>
          <h1>Welcome {firstName},</h1>
        </Grid>
        <hr style={{ color: "#eee" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Current FY: {fy}</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAddMemberOpen(true);
            }}
          >
            Add Family Member
          </Button>
        </div>
        {fy !== CURRENT_FY && (
          <Alert severity="warning">
            You are reviewing prior year's details. Please select FY{" "}
            {CURRENT_FY} to provide the information for filing the tax return.
          </Alert>
        )}
        <div className="row" style={{ marginTop: 16, border: 1 }}>
          {data &&
            data
              .filter((s) => s.fy === fy)
              .map((t, index) => {
                return (
                  <Grid key={index} container>
                    <SizedBox height={10} width={10}></SizedBox>
                    {
                      <DisplayFilingClient
                        {...{
                          index,
                          t,
                          fy,
                        }}
                      ></DisplayFilingClient>
                    }
                  </Grid>
                );
              })}
        </div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={openAddMember}
          onClose={() => {
            setAddMemberOpen(false);
          }}
        >
          <AddFamilyProfile
            {...{
              row: { userProfileId: id, firstName: "", lastName: "" },
              service: ServiceType.INCOMETAX,
              setAddMemberOpen,
              refetch
            }}
          ></AddFamilyProfile>
        </Modal>{" "}
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
}
