import React from "react";
import { Formik } from "formik";
import API from "../../services/api";
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useToastDisplay } from "../../Common/useToastDisplay";
import SizedBox from "../SizedBox";
import AdvisorType from "../../Common/AdvisorType";

const API_URL = process.env.REACT_APP_ADVISOR_CONTROLLER;

export default function ReAssignTaxAdvisor({
  row,
  setOpen,
  advisorType,
  isAllowTpReassign,
  refetch,
}) {
  const showToast = useToastDisplay();
  const { data, isLoading } = useQuery(
    ["All_Advisor_List"],
    async () => {
      return API.get(API_URL + `GetAllAdvisorDetails`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  const advisorList = data ?? [];
  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        userProfileId: row.userProfileId,
        advisorId: "00000000-0000-0000-0000-000000000000",
        wpAdvisorId: "00000000-0000-0000-0000-000000000000",
      }}
      onSubmit={async (values, actions) => {
        if (
          values.advisorId === "00000000-0000-0000-0000-000000000000" &&
          values.wpAdvisorId === "00000000-0000-0000-0000-000000000000"
        ) {
          showToast({
            message: "Please select advisor to assign",
            severity: "error",
          });
          return;
        }
        await API.post(API_URL + "ReassignAdvisor", values)
          .then((res) => res.data)
          .then((f) => {
            showToast({
              message: "Advisor assigned successfully!",
              severity: "success",
            });
            refetch();
            setOpen(false);
          })
          .catch((e) => console.log("API FAILED", e));
      }}
      // validationSchema={Yup.object({
      //   advisorId: Yup.string().required("Please select advisor"),
      // })}
    >
      {(formikProps) => (
        <div
          style={{
            height: 450,
            width: 400,
            backgroundColor: "#fff",
            elevation: 0.5,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                justifyContent: "center",
              }}
            >
              <h2>Re-assign Advisor</h2>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              padding: 10,
            }}
          >
            <div style={{ fontSize: 14 }}>
              Client Name : {row?.firstName} {row?.lastName}
            </div>
            <div style={{ fontSize: 14, marginTop: 15 }}>
              Current Tax Advisor : {row?.advisorName}
            </div>
            <div style={{ fontSize: 14, marginTop: 15 }}>
              Current WP Advisor : {row?.wpAdvisorName}
            </div>
            <SizedBox height={10}></SizedBox>
            <TextField
              name="advisorId"
              select
              label="Select Tax Advisor"
              variant="outlined"
              size="small"
              disabled={
                !isAllowTpReassign 
              }
              error={
                formikProps.touched.advisorId && !!formikProps.errors.advisorId
              }
              helperText={
                formikProps.touched.advisorId && formikProps.errors.advisorId
              }
              value={formikProps.values.advisorId}
              onChange={(e) => {
                formikProps.setFieldValue("advisorId", e.target.value);
              }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {advisorList &&
                advisorList
                  .filter((t) => t.advisorType === AdvisorType.TaxAdvisor.name)
                  .map((s) => {
                    return (
                      <MenuItem value={s.apiAdvisorId}>
                        <em>{s.advisorFullName}</em>
                      </MenuItem>
                    );
                  })}
            </TextField>
            <SizedBox height={10}></SizedBox>
            <TextField
              name="wpAdvisorId"
              select
              label="Select WP Advisor"
              variant="outlined"
              size="small"
              disabled={
                (advisorType &&
                (advisorType === AdvisorType.TaxAdvisor.name ||
                  advisorType === AdvisorType.SeniorTaxAdvisor.name ||
                  advisorType === AdvisorType.TaxManager.name))
                  || isAllowTpReassign
              }
              error={
                formikProps.touched.wpAdvisorId &&
                !!formikProps.errors.wpAdvisorId
              }
              helperText={
                formikProps.touched.wpAdvisorId &&
                formikProps.errors.wpAdvisorId
              }
              value={formikProps.values.wpAdvisorId}
              onChange={(e) => {
                formikProps.setFieldValue("wpAdvisorId", e.target.value);
              }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {advisorList &&
                advisorList
                  .filter(
                    (t) => t.advisorType === AdvisorType.WealthPlanner.name
                  )
                  .map((s) => {
                    return (
                      <MenuItem value={s.apiAdvisorId}>
                        <em>{s.advisorFullName}</em>
                      </MenuItem>
                    );
                  })}
            </TextField>
          </div>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ backgroundColor: "#EE9A4D" }}
              variant="contained"
              onClick={formikProps.handleSubmit}
            >
              Save
            </Button>
            <SizedBox width={10}></SizedBox>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
}
