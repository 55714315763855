import React from "react";

function SizedBox({
  height = 0,
  width = 0,
}: {
  height?: number;
  width?: number;
}) {
  return <div style={{ height, width }}></div>;
}

export default SizedBox;