import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  TextField,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import SizedBox from "../SizedBox";
import CurrencyTextField from "../CurrencyTextField";
import { Formik } from "formik";
import API from "../../services/api";
import { getInteger } from "../../Common/helperMethods";

const OTHER_API_URL = process.env.REACT_APP_OTHERS_CONTROLLER;

function Others({ setSelectedTab, showToast, role }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["Others_Data", id],
    async () => {
      return API.get(OTHER_API_URL + `GetOthers/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  const isClient = false; //role == 'Client';

  const otherData = data
    ? data
    : {
        additionalSalaryIncome: 0,
        additionalSalaryIncomeTDS: 0,
        salaryExemption: 0,
        anyOtherIncome: 0,
        seperateDeduction: 0,
        propertySaleTDS: 0,
        additionalForeignCredits: 0,
        tcs: 0,
        priorAdvanceTaxPayment: 0,
        comment: "",
        taxQtrProfileId: id,
        othersId: "00000000-0000-0000-0000-000000000000",
      };
  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={otherData}
        onSubmit={async (values, actions) => {
          API.post(OTHER_API_URL + "AddOthers", {
            ...values,
            additionalSalaryIncome: getInteger(values.additionalSalaryIncome),
            additionalSalaryIncomeTDS: getInteger(
              values.additionalSalaryIncomeTDS
            ),
            salaryExemption: getInteger(values.salaryExemption),
            anyOtherIncome: getInteger(values.anyOtherIncome),
            seperateDeduction: getInteger(values.seperateDeduction),
            propertySaleTDS: getInteger(values.propertySaleTDS),
            additionalForeignCredits: getInteger(
              values.additionalForeignCredits
            ),
            tcs: getInteger(values.tcs),
            priorAdvanceTaxPayment: getInteger(values.priorAdvanceTaxPayment),
            tcsExpected: getInteger(values.tcsExpected),
          }).then((response) => {
            showToast({
              message: "Other submitted successfully!",
              severity: "success",
            });
            setSelectedTab(6);
            navigate(`/advance-tax/quarter/${id}/deduction`);
          });
        }}
      >
        {(formikProps) => (
          <>
            <SizedBox height={10} width={10}></SizedBox>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="additionalSalaryIncome"
                      label="44ADA"
                      fullWidth
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "additionalSalaryIncome",
                          e.target.value
                        );
                        formikProps.setFieldValue(
                          'additionalSalaryIncomeTDS',
                          (getInteger(e.target.value) * 20) / 100
                        );
                      }}
                      value={formikProps.values.additionalSalaryIncome}
                      error={
                        formikProps.touched.additionalSalaryIncome &&
                        !!formikProps.errors.additionalSalaryIncome
                      }
                      helperText={
                        formikProps.touched.additionalSalaryIncome &&
                        formikProps.errors.additionalSalaryIncome
                      }
                      disabled={isClient || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="additionalSalaryIncomeTDS"
                      label="44ADA TDS"
                      fullWidth
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "additionalSalaryIncomeTDS",
                          e.target.value
                        );
                      }}
                      value={formikProps.values.additionalSalaryIncomeTDS}
                      error={
                        formikProps.touched.additionalSalaryIncomeTDS &&
                        !!formikProps.errors.additionalSalaryIncomeTDS
                      }
                      helperText={
                        formikProps.touched.additionalSalaryIncomeTDS &&
                        formikProps.errors.additionalSalaryIncomeTDS
                      }
                      disabled={isClient || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="propertySaleTDS"
                      label="Property Sale TDS/ Other Income TDS"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.propertySaleTDS}
                      error={
                        formikProps.touched.propertySaleTDS &&
                        !!formikProps.errors.propertySaleTDS
                      }
                      helperText={
                        formikProps.touched.propertySaleTDS &&
                        formikProps.errors.propertySaleTDS
                      }
                      disabled={isClient || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="salaryExemption"
                      label="Salary Exemption"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.salaryExemption}
                      error={
                        formikProps.touched.salaryExemption &&
                        !!formikProps.errors.salaryExemption
                      }
                      helperText={
                        formikProps.touched.salaryExemption &&
                        formikProps.errors.salaryExemption
                      }
                      disabled={isClient || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="additionalForeignCredits"
                      label="Additional Foreign Tax Credits"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.additionalForeignCredits}
                      error={
                        formikProps.touched.additionalForeignCredits &&
                        !!formikProps.errors.additionalForeignCredits
                      }
                      helperText={
                        formikProps.touched.additionalForeignCredits &&
                        formikProps.errors.additionalForeignCredits
                      }
                      disabled={isClient || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="anyOtherIncome"
                      label="Any other income"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.anyOtherIncome}
                      error={
                        formikProps.touched.anyOtherIncome &&
                        !!formikProps.errors.anyOtherIncome
                      }
                      helperText={
                        formikProps.touched.anyOtherIncome &&
                        formikProps.errors.anyOtherIncome
                      }
                      disabled={isClient || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="tcs"
                      label="TCS Incurred"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.tcs}
                      error={
                        formikProps.touched.tcs && !!formikProps.errors.tcs
                      }
                      helperText={
                        formikProps.touched.tcs && formikProps.errors.tcs
                      }
                      disabled={isClient || formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="tcsExpected"
                      label="TCS Expected"
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.tcsExpected}
                      error={
                        formikProps.touched.tcsExpected && !!formikProps.errors.tcsExpected
                      }
                      helperText={
                        formikProps.touched.tcsExpected && formikProps.errors.tcsExpected
                      }
                      disabled={isClient || formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="comment"
                      variant="outlined"
                      multiline={true}
                      label="Enter your comments/notes here"
                      type="text"
                      name="comment"
                      minRows={11}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.comment}
                      error={
                        formikProps.touched.comment &&
                        !!formikProps.errors.comment
                      }
                      helperText={
                        formikProps.touched.comment &&
                        formikProps.errors.comment
                      }
                      disabled={isClient || formikProps.isSubmitting}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}></Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        disabled={formikProps.isSubmitting}
                        color="primary"
                        onClick={formikProps.handleSubmit}
                      >
                        Save and Next
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
}
export default Others;
