import React from "react";
import {
  TextField,
  FormControl,
  Divider,
  MenuItem,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import API from "../../services/api";
import SizedBox from "../SizedBox";
import { getInteger } from "../../Common/helperMethods";
import { isValid, parse, format } from "date-fns";
import { useSelectedFY } from "../../Common/useSelectedClient";
import ServiceType from "../../Common/ServiceType";
import { useToastDisplay } from "../../Common/useToastDisplay";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}));

const CURRENT_FY = process.env.REACT_APP_FY;
const CURRENT_AY = process.env.REACT_APP_AY;

function AddFamilyProfile({ row, service, setAddMemberOpen, refetch }) {
  const showToast = useToastDisplay();
  const classes = useStyles();
  const API_URL = process.env.REACT_APP_USERPROFILE_CONTROLLER;
  const selectedFy = useSelectedFY();

  const formValues = {
    userProfileId: row.userProfileId,
    firstName: "",
    lastName: "",
    gender: "",
    pan: "",
    relationship: "",
    accountType: "2",
    dob: "",
    dd: "",
    mm: "",
    yyyy: "",
    service: service,
    fy: selectedFy
      ? selectedFy
      : service === ServiceType.INCOMETAX
      ? CURRENT_FY
      : CURRENT_AY,
  };

  return (
    <div style={{ backgroundColor: "#fff", elevation: 0.5 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: 20,
        }}
      >
        <p style={{ fontSize: 18, color: "#363636" }}>Add Family Member</p>
        <Divider
          style={{ color: "#EAEAEC", borderRadius: 2 }}
          variant="fullWidth"
        />
        {row.lastName !== "" && (
          <h3>
            Family Member Name : {row.firstName} {row.lastName}
          </h3>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: 10,
          paddingBottom: 20,
        }}
      >
        <Formik
          initialValues={formValues}
          onSubmit={async (values, actions) => {
            const dobText = `${values.yyyy}.${
              getInteger(values.mm) < 10
                ? "0" + getInteger(values.mm)
                : getInteger(values.mm)
            }.${getInteger(values.dd)}`;

            const dateOfBirth = parse(dobText, "yyyy.MM.dd", new Date());
            if (!isValid(dateOfBirth)) {
              showToast({
                message: "Please enter correct date",
                severity: "error",
              });
              return;
            }
            //return;
            API.post(API_URL + "AddFamilyProfile", {
              ...values,
              dob: format(dateOfBirth, "yyyy-MM-dd"),
            })
              .then((response) => {
                if (response.data) {
                  showToast({
                    message: "Family profile submitted successfully!",
                    severity: "success",
                  });
                  setAddMemberOpen(false);
                  refetch();
                } else {
                  showToast({
                    message: "This PAN already exists in database",
                    severity: "error",
                  });
                }
              })
              .catch((error) => {
                showToast({
                  message: "This PAN already exists in database",
                  severity: "error",
                });
              });
          }}
          validationSchema={Yup.object({
            lastName: Yup.string().required("Last Name is a required field"),
            dd: Yup.string()
              .required("DD is a required Field")
              .test({
                name: "max",
                exclusive: true,
                params: {},
                message: "Date should be between 1 and 31",
                test: function (value) {
                  return getInteger(value) >= 1 && getInteger(value) <= 31;
                },
              }),
            mm: Yup.string()
              .required("Month is a required field")
              .test({
                name: "max",
                exclusive: true,
                params: {},
                message: "Month should be between 1 and 12",
                test: function (value) {
                  return getInteger(value) >= 1 && getInteger(value) <= 12;
                },
              }),
            yyyy: Yup.string()
              .required("Year is a required field")
              .test({
                name: "max",
                exclusive: true,
                params: {},
                message: "Year should be between 1912 and 2017",
                test: function (value) {
                  return getInteger(value) >= 1912 && getInteger(value) <= 2017;
                },
              }),
            gender: Yup.string().required("Gender is a required field"),
            pan: Yup.string()
              .required("PAN is a required field")
              .min(10, "Enter correct 10 digit PAN")
              .max(10, "Enter correct 10 digit PAN")
              .matches(
                /^[A-Za-z]{3}[PpHh][A-Za-z][0-9]{4}[a-zA-Z]/,
                "Enter correct PAN"
              ),
            relationship: Yup.string().required(
              "Relationship is a required Field."
            ),
            // accountType: Yup.string()
            //     .required('Account Type is a required Field'),
          })}
        >
          {(formikProps) => (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      id="firstName"
                      fullWidth
                      label="First Name"
                      variant="outlined"
                      size="small"
                      value={formikProps.values.firstName}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={
                        formikProps.touched.firstName &&
                        !!formikProps.errors.firstName
                      }
                      helperText={
                        formikProps.touched.firstName &&
                        formikProps.errors.firstName
                      }
                    />
                  </FormControl>
                </Grid>

                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      id="lastName"
                      fullWidth
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      value={formikProps.values.lastName}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={
                        formikProps.touched.lastName &&
                        !!formikProps.errors.lastName
                      }
                      helperText={
                        formikProps.touched.lastName &&
                        formikProps.errors.lastName
                      }
                      disabled={formikProps.isSubmitting}
                    />
                  </FormControl>
                </Grid>
                <Margin></Margin>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          fullWidth
                          id="dd"
                          type="number"
                          label="DD"
                          variant="outlined"
                          size="small"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.dd}
                          error={
                            formikProps.touched.dd && !!formikProps.errors.dd
                          }
                          helperText={
                            formikProps.touched.dd && formikProps.errors.dd
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        {" "}
                        <TextField
                          fullWidth
                          id="mm"
                          label="MM"
                          type="number"
                          variant="outlined"
                          size="small"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.mm}
                          error={
                            formikProps.touched.mm && !!formikProps.errors.mm
                          }
                          helperText={
                            formikProps.touched.mm && formikProps.errors.mm
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          fullWidth
                          id="yyyy"
                          label="YYYY"
                          type="number"
                          variant="outlined"
                          size="small"
                          onChange={formikProps.handleChange}
                          value={formikProps.values.yyyy}
                          onBlur={formikProps.handleBlur}
                          error={
                            formikProps.touched.yyyy &&
                            !!formikProps.errors.yyyy
                          }
                          helperText={
                            formikProps.touched.yyyy && formikProps.errors.yyyy
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      id="gender"
                      select
                      label="Gender"
                      variant="outlined"
                      size="small"
                      error={
                        formikProps.touched.gender &&
                        !!formikProps.errors.gender
                      }
                      helperText={
                        formikProps.touched.gender && formikProps.errors.gender
                      }
                      value={formikProps.values.gender}
                      onChange={(e) => {
                        formikProps.setFieldValue("gender", e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>Select Gender</em>
                      </MenuItem>
                      <MenuItem value="1">
                        <em>Male</em>
                      </MenuItem>
                      <MenuItem value="2">
                        <em>Female</em>
                      </MenuItem>
                      <MenuItem value="3">
                        <em>Other</em>
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      style={{ textTransform: "uppercase" }}
                      fullWidth
                      value={formikProps.values.pan.toUpperCase()}
                      id="pan"
                      label="PAN"
                      variant="outlined"
                      size="small"
                      error={
                        formikProps.touched.pan && !!formikProps.errors.pan
                      }
                      helperText={
                        formikProps.touched.pan && formikProps.errors.pan
                      }
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      id="accountType"
                      select
                      label="Account Type"
                      variant="outlined"
                      size="small"
                      error={
                        formikProps.touched.accountType &&
                        !!formikProps.errors.accountType
                      }
                      helperText={
                        formikProps.touched.accountType &&
                        formikProps.errors.accountType
                      }
                      value={formikProps.values.accountType}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "accountType",
                          e.target.value
                        );
                        if (e.target.value == "1")
                          formikProps.setFieldValue("relationship", "Self");
                        else formikProps.setFieldValue("relationship", "");
                      }}
                    >
                      <MenuItem value="">
                        <em>Select Account Type</em>
                      </MenuItem>
                      <MenuItem value="1">
                        <em>Self</em>
                      </MenuItem>
                      <MenuItem value="2">
                        <em>Family Member</em>
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Margin></Margin>
                <Grid>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      fullWidth
                      value={formikProps.values.relationship}
                      id="relationship"
                      name="relationship"
                      label="Relationship"
                      variant="outlined"
                      size="small"
                      onChange={formikProps.handleChange}
                      error={
                        formikProps.touched.relationship &&
                        !!formikProps.errors.relationship
                      }
                      helperText={
                        formikProps.touched.relationship &&
                        formikProps.errors.relationship
                      }
                    />
                  </FormControl>
                </Grid>

                <SizedBox height={40} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save And Next
                    </Button>
                    <SizedBox width={10}></SizedBox>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setAddMemberOpen(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </div>
    </div>
  );
}

function Margin() {
  return <SizedBox height={25} width={25}></SizedBox>;
}

export default AddFamilyProfile;
