import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import SizedBox from "../../components/SizedBox";
import {
  useUpdateSelectedClient,
  useUpdateSelectedFY,
} from "../../Common/useSelectedClient";
import DisplayService from "../../components/DisplayService";
import API from "../../services/api";
import { useAuth } from "../../provider/auth";

const TAX_API_URL = process.env.REACT_APP_TAXPROFILE_CONTROLLER;

export default function Home() {
  const [isClicked, setIsClicked] = useState(false);
  let navigate = useNavigate();
  const setSelectedFY = useUpdateSelectedFY();
  const setSelectedClient = useUpdateSelectedClient();
  const { user } = useAuth();

  const { data, isLoading } = useQuery(
    ["FinancialYear"],
    async () => {
      return API.get(TAX_API_URL + `GetAllFinancialYears`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  function redirectToFiling(fy) {
    setIsClicked(true);
    if (user.role === "Client") {
      API.get(
        TAX_API_URL + `CheckAndGetTaxProfile/${user.userProfileId}?fy=${fy}`
      )
        .then((res) => {
          setSelectedFY(fy);
          setSelectedClient(res.data);
          setIsClicked(false);

          if (res.data.isResidentialAdded === false)
            navigate(`/incomeprofile/${user.userProfileId}?type=income`);
          else navigate(`/income`);
        })
        .catch((err) => {
          setIsClicked(false);
          console.log("Error occured while fetching filing data");
        });
    } else {
      setSelectedFY(fy);
      setIsClicked(false);
      navigate(`/income`);
    }
  }

  function redirectToAdvance(fy) {
    setIsClicked(true);
    if (user.role === "Client") {
      API.get(
        TAX_API_URL +
          `CheckAndGetAdvanceTaxProfile/${user.userProfileId}?fy=${fy}`
      )
        .then((res) => {
          setSelectedFY(fy);
          setSelectedClient(res.data);

          setIsClicked(false);
          if (res.data) navigate(`/advance-tax`);
          else navigate(`/profile/${res.data.taxProfileId}`);
        })
        .catch((err) => {
          setIsClicked(false);
          console.log("Error occured while fetching quarter");
        });
    } else {
      setSelectedFY(fy);
      setIsClicked(false);
      navigate(`/advance-tax`);
    }
  }

  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );

  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        marginTop: 30,
      }}
    >
      <Grid container style={{ padding: "10px" }}>
        <Grid item md={1}></Grid>
        <Grid item xs={12} sm={12} md={10}>
          <DisplayService
            key="advanceTax"
            {...{
              serviceName: "Advance Tax",
              fyList: data?.advanceTaxFYList || [],
              redirectToService: redirectToAdvance,
            }}
          ></DisplayService>
          <SizedBox width={20} height={20}></SizedBox>
          {/* {user.role !== "Client" && (*/}
          <DisplayService
            key="income"
            {...{
              serviceName: "Income Tax Filing",
              fyList: data?.incomeFYList || [],
              redirectToService: redirectToFiling,
            }}
          ></DisplayService>
          {/* )} */}
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    </div>
  );
}
