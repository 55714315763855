import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Button,
  Grid,
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
} from '@material-ui/core';
import { Formik } from 'formik';
import CurrencyTextField from '../../CurrencyTextField';
import API from '../../../services/api';
import { getInteger } from '../../../Common/helperMethods';

const API_URL = process.env.REACT_APP_OTHER_ASSET_CONTROLLER;

export default function OtherAsset({ setSelectedSubTab, client, showToast,isDisable }) {
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);

  const { data, isLoading } = useQuery(
    ['OTHER_ASSET_Data',id],
    async () => {
      return API.get(API_URL + `GetOtherAssetDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  useEffect(() => {
    if (data) {
      setFileList(data.fileList);
    }
  }, [data]);

  const EsopData = data
    ? {
        ...data,
      }
    : {
        otherIncome: '',
        fileList: [],
        assetId: '00000000-0000-0000-0000-000000000000',
        comment: '',
        taxProfileId: id,
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <Formik
      initialValues={EsopData}
      onSubmit={async (values, actions) => {
        API.post(API_URL + 'AddOtherAssetDetails', {
          ...values,
          jewelleryPrior: getInteger(values.jewelleryPrior),
          jewelleryCurrent: getInteger(values.jewelleryCurrent),
          artWorkPrior: getInteger(values.artWorkPrior),
          artWorkCurrent: getInteger(values.artWorkCurrent),
          vehiclePrior: getInteger(values.vehiclePrior),
          vehicleCurrent: getInteger(values.vehicleCurrent),
          bankPrior: getInteger(values.bankPrior),
          bankCurrent: getInteger(values.bankCurrent),

          sharesPrior: getInteger(values.sharesPrior),
          sharesCurrent: getInteger(values.sharesCurrent),

          insurancePrior: getInteger(values.insurancePrior),
          insuranceCurrent: getInteger(values.insuranceCurrent),

          loanPrior: getInteger(values.loanPrior),
          loanCurrent: getInteger(values.loanCurrent),

          cashPrior: getInteger(values.cashPrior),
          cashCurrent: getInteger(values.cashCurrent),

          liabilityPrior: getInteger(values.liabilityPrior),
          liabilityCurrent: getInteger(values.liabilityCurrent),
          taxProfileId: id,
          fileList,
        }).then((response) => {
          showToast({
            message: 'Interest/Dividends/Others submitted successfully!',
            severity: 'success',
          });
          setSelectedSubTab(4);
        });
      }}
    >
      {(formikProps) => (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Sr.No</b>
                    </TableCell>
                    <TableCell>
                      <b>Description</b>
                    </TableCell>
                    <TableCell>
                      <b>Prior year amounts</b>
                    </TableCell>
                    <TableCell>
                      <b>Current year amounts</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{'(i)'}</TableCell>
                    <TableCell>Jewellery, bullion etc.</TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="jewelleryPrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.jewelleryPrior)}
                        error={
                          formikProps.touched.jewelleryPrior &&
                          !!formikProps.errors.jewelleryPrior
                        }
                        helperText={
                          formikProps.touched.jewelleryPrior &&
                          formikProps.errors.jewelleryPrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="jewelleryCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.jewelleryCurrent)}
                        error={
                          formikProps.touched.jewelleryCurrent &&
                          !!formikProps.errors.jewelleryCurrent
                        }
                        helperText={
                          formikProps.touched.jewelleryCurrent &&
                          formikProps.errors.jewelleryCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{'(ii)'}</TableCell>
                    <TableCell>
                      Archaeological collections, drawings, painting, sculpture
                      or any work of art
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="artWorkPrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.artWorkPrior)}
                        error={
                          formikProps.touched.artWorkPrior &&
                          !!formikProps.errors.artWorkPrior
                        }
                        helperText={
                          formikProps.touched.artWorkPrior &&
                          formikProps.errors.artWorkPrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="artWorkCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.artWorkCurrent)}
                        error={
                          formikProps.touched.artWorkCurrent &&
                          !!formikProps.errors.artWorkCurrent
                        }
                        helperText={
                          formikProps.touched.artWorkCurrent &&
                          formikProps.errors.artWorkCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{'(ii)'}</TableCell>
                    <TableCell>Vehicles/Boats</TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="vehiclePrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.vehiclePrior)}
                        error={
                          formikProps.touched.vehiclePrior &&
                          !!formikProps.errors.vehiclePrior
                        }
                        helperText={
                          formikProps.touched.vehiclePrior &&
                          formikProps.errors.vehiclePrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="vehicleCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.vehicleCurrent)}
                        error={
                          formikProps.touched.vehicleCurrent &&
                          !!formikProps.errors.vehicleCurrent
                        }
                        helperText={
                          formikProps.touched.vehicleCurrent &&
                          formikProps.errors.vehicleCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{'(iv)'}</TableCell>
                    <TableCell>Financial asset</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{''}</TableCell>
                    <TableCell>(a) Bank (including all deposits)</TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="bankPrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.bankPrior)}
                        error={
                          formikProps.touched.bankPrior &&
                          !!formikProps.errors.bankPrior
                        }
                        helperText={
                          formikProps.touched.bankPrior &&
                          formikProps.errors.bankPrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="bankCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.bankCurrent)}
                        error={
                          formikProps.touched.bankCurrent &&
                          !!formikProps.errors.bankCurrent
                        }
                        helperText={
                          formikProps.touched.bankCurrent &&
                          formikProps.errors.bankCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{''}</TableCell>
                    <TableCell>(b) Shares and securities</TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="sharesPrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.sharesPrior)}
                        error={
                          formikProps.touched.sharesPrior &&
                          !!formikProps.errors.sharesPrior
                        }
                        helperText={
                          formikProps.touched.sharesPrior &&
                          formikProps.errors.sharesPrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="sharesCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.sharesCurrent)}
                        error={
                          formikProps.touched.sharesCurrent &&
                          !!formikProps.errors.sharesCurrent
                        }
                        helperText={
                          formikProps.touched.sharesCurrent &&
                          formikProps.errors.sharesCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{''}</TableCell>
                    <TableCell>(c) Insurance policies </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="insurancePrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.insurancePrior)}
                        error={
                          formikProps.touched.insurancePrior &&
                          !!formikProps.errors.insurancePrior
                        }
                        helperText={
                          formikProps.touched.insurancePrior &&
                          formikProps.errors.insurancePrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="insuranceCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.insuranceCurrent)}
                        error={
                          formikProps.touched.insuranceCurrent &&
                          !!formikProps.errors.insuranceCurrent
                        }
                        helperText={
                          formikProps.touched.insuranceCurrent &&
                          formikProps.errors.insuranceCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{''}</TableCell>
                    <TableCell>(d) Loans and advances given </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="loanPrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.loanPrior)}
                        error={
                          formikProps.touched.loanPrior &&
                          !!formikProps.errors.loanPrior
                        }
                        helperText={
                          formikProps.touched.loanPrior &&
                          formikProps.errors.loanPrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="loanCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.loanCurrent)}
                        error={
                          formikProps.touched.loanCurrent &&
                          !!formikProps.errors.loanCurrent
                        }
                        helperText={
                          formikProps.touched.loanCurrent &&
                          formikProps.errors.loanCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{''}</TableCell>
                    <TableCell>(e) Cash in hand </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="cashPrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.cashPrior)}
                        error={
                          formikProps.touched.cashPrior &&
                          !!formikProps.errors.cashPrior
                        }
                        helperText={
                          formikProps.touched.cashPrior &&
                          formikProps.errors.cashPrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="cashCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.cashCurrent)}
                        error={
                          formikProps.touched.cashCurrent &&
                          !!formikProps.errors.cashCurrent
                        }
                        helperText={
                          formikProps.touched.cashCurrent &&
                          formikProps.errors.cashCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{'(v)'}</TableCell>
                    <TableCell> Liability </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="liabilityPrior"
                        label="Amount"
                        fullWidth
                        disabled={true}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.liabilityPrior)}
                        error={
                          formikProps.touched.liabilityPrior &&
                          !!formikProps.errors.liabilityPrior
                        }
                        helperText={
                          formikProps.touched.liabilityPrior &&
                          formikProps.errors.liabilityPrior
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        name="liabilityCurrent"
                        label="Amount"
                        fullWidth
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={getInteger(formikProps.values.liabilityCurrent)}
                        error={
                          formikProps.touched.liabilityCurrent &&
                          !!formikProps.errors.liabilityCurrent
                        }
                        helperText={
                          formikProps.touched.liabilityCurrent &&
                          formikProps.errors.liabilityCurrent
                        }
                        disabled={isDisable}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}></Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disabled={formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
