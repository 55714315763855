import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  TextField,
  Grid,
  Button,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import SizedBox from "../SizedBox";
import CurrencyTextField from "../CurrencyTextField";
import { Formik } from "formik";
import API from "../../services/api";
import { getInteger } from "../../Common/helperMethods";
import { Alert, AlertTitle } from "@material-ui/lab";
import DisplayFiles from "../Common/displayFiles";

const CG_API_URL = process.env.REACT_APP_CG_CONTROLLER;

function CG({
  activeQuarter,
  setSelectedTab,
  client,
  showToast,
  isOtherTabVisible,
  role,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["CG_Data", id],
    async () => {
      return API.get(CG_API_URL + `GetCapitalGains/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  const isDisabled = client?.isDisabled;
  const isClient = role === "Client";

  const cg_Data = data
    ? data
    : {
        shortTermCG_15: 0,
        shortTermCG_Marginal: 0,
        lossBroughtForward: 0,
        netTaxableShortTerm: 0,
        longTermCG_10: 0,
        longTermCG_20: 0,
        exemption: 0,
        longLossBroughtForward: 0,
        netTaxableLongTerm: 0,
        netTaxableGain: 0,
        taxOnCG: 0,
        totalLossBroughtForward: 0,
        comment: "",
        taxQtrProfileId: id,
        capitalGainId: "00000000-0000-0000-0000-000000000000",
        fileList: [],
      };

  function getNetTaxableTerm_Intra(
    shortTermCG_15,
    shortTermCG_Marginal,
    isNST15
  ) {
    const st15 = getInteger(shortTermCG_15);
    const st30 = getInteger(shortTermCG_Marginal);
    if (st15 >= 0 && st30 >= 0) {
      return isNST15 ? st15 : st30;
    } else if (st15 < 0 && st30 >= 0) {
      return isNST15 ? 0 : st15 + st30;
    } else if (st15 >= 0 && st30 < 0) {
      return isNST15 ? st15 + st30 : 0;
    } else if (st15 < 0 && st30 < 0) {
      return isNST15 ? 0 : st15 + st30;
    }
    return 0;
  }

  function getNetTaxableShortTerm_Inter(
    shortTermCG_15,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_20,
    isNST15
  ) {
    let nst = getNetTaxableTerm_Intra(
      shortTermCG_15,
      shortTermCG_Marginal,
      isNST15
    );
    const lst10 = getNetTaxableTerm_Intra(longTermCG_10, longTermCG_20, true);
    const lst20 = getNetTaxableTerm_Intra(longTermCG_10, longTermCG_20, false);

    if (nst > 0) {
      return nst;
    } else {
      return Math.min(nst + Math.max(lst10 + lst20, 0), 0);
    }
  }

  function getShortGain(
    shortTermCG_15,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_20,
    lossBroughtForward,
    isNST15
  ) {
    const anst15 = getNetTaxableShortTerm_Inter(
      shortTermCG_15,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_20,
      true
    );
    const anst30 = getNetTaxableShortTerm_Inter(
      shortTermCG_15,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_20,
      false
    );
    if (isNST15) {
      if (anst15 >= 0 && anst30 + getInteger(lossBroughtForward) < 0) {
        return Math.max(anst15 + anst30 + getInteger(lossBroughtForward), 0);
      } else {
        return anst15;
      }
    } else {
      if (anst30 > 0)
        return Math.max(anst30 + getInteger(lossBroughtForward), 0);
      else return anst30;
    }
  }

  function getLongGain(
    shortTermCG_15,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_20,
    stlbf,
    ltlbf,
    isNST15
  ) {
    const anlt10 = getNetTaxableLongTerm_Inter(
      longTermCG_10,
      longTermCG_20,
      shortTermCG_15,
      shortTermCG_Marginal,
      true
    );
    const anlt20 = getNetTaxableLongTerm_Inter(
      longTermCG_10,
      longTermCG_20,
      shortTermCG_15,
      shortTermCG_Marginal,
      false
    );
    const anst30 = getNetTaxableShortTerm_Inter(
      shortTermCG_15,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_20,
      false
    );
    const anst15 = getNetTaxableShortTerm_Inter(
      shortTermCG_15,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_20,
      true
    );

    let nsg15 = 0,
      nsgNormal = 0;

    if (anst15 >= 0 && anst30 + getInteger(stlbf) < 0) {
      nsg15 = anst15 + anst30 + getInteger(stlbf);
    } else {
      nsg15 = anst15;
    }

    if (anst30 > 0) nsgNormal = Math.max(anst30 + getInteger(stlbf), 0);
    else nsgNormal = anst30;

    if (isNST15) {
      if (anlt10 <= 0) return 0;
      else if (
        anlt20 +
          getInteger(ltlbf) +
          Math.min(nsg15 + nsgNormal, 0) +
          getInteger(stlbf) <
        0
      ) {
        return Math.max(
          anlt10 + anlt20 + getInteger(ltlbf) + Math.min(nsg15 + nsgNormal, 0),
          0
        );
      } else return anlt10;
    } else {
      if (anlt20 + getInteger(ltlbf) <= 0) return 0;
      else if (getInteger(stlbf) + Math.min(nsg15 + nsgNormal, 0) < 0) {
        return Math.max(
          anlt20 + getInteger(ltlbf) + Math.min(nsg15 + nsgNormal, 0),
          0
        );
      } else return anlt20 + getInteger(ltlbf);
    }
  }

  function getNetTaxableLongTerm_Inter(
    longTermCG_10,
    longTermCG_20,
    shortTermCG_15,
    shortTermCG_Marginal,
    isNST15
  ) {
    const nlt10 = getNetTaxableTerm_Intra(longTermCG_10, longTermCG_20, true);
    const nlt20 = getNetTaxableTerm_Intra(longTermCG_10, longTermCG_20, false);
    const nst15 = getNetTaxableTerm_Intra(
      shortTermCG_15,
      shortTermCG_Marginal,
      true
    );
    const nst30 = getNetTaxableTerm_Intra(
      shortTermCG_15,
      shortTermCG_Marginal,
      false
    );

    if (isNST15) {
      if (nlt10 >= 0 && nlt20 + nst30 + nst15 < 0) {
        return Math.max(nlt10 + nlt20 + nst30 + nst15, 0);
      } else {
        return nlt10;
      }
    } else {
      if (nlt20 >= 0) {
        return Math.max(nlt20 + Math.min(nst30 + nst15, 0), 0);
      } else {
        return nlt20;
      }
    }
  }

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={cg_Data}
        onSubmit={async (values, actions) => {
          API.post(CG_API_URL + "AddCapitalGains", {
            ...values,
            tabName: "CG",
            shortTermCG_15: getInteger(values.shortTermCG_15),
            shortTermCG_Marginal: getInteger(values.shortTermCG_Marginal),
            lossBroughtForward: getInteger(values.lossBroughtForward),
            totalLossBroughtForward: getInteger(values.totalLossBroughtForward),
            longTermCG_10: getInteger(values.longTermCG_10),
            longTermCG_20: getInteger(values.longTermCG_20),
            longLossBroughtForward: getInteger(values.longLossBroughtForward),
            exemption: getInteger(values.exemption),
            taxOnCG: getInteger(values.taxOnCG),
            intraNetTaxableShortTerm_15: getNetTaxableTerm_Intra(
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              true
            ),
            intraNetTaxableShortTermNormal: getNetTaxableTerm_Intra(
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              false
            ),

            intraNetTaxableLongTerm_10: getNetTaxableTerm_Intra(
              values.longTermCG_10,
              values.longTermCG_20,
              true
            ),
            intraNetTaxableLongTerm_20: getNetTaxableTerm_Intra(
              values.longTermCG_10,
              values.longTermCG_20,
              false
            ),

            interNetTaxableShortTerm_15: getNetTaxableShortTerm_Inter(
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_20,
              true
            ),
            interNetTaxableShortTermNormal: getNetTaxableShortTerm_Inter(
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_20,
              false
            ),
            interNetTaxableLongTerm_10: getNetTaxableLongTerm_Inter(
              values.longTermCG_10,
              values.longTermCG_20,
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              true
            ),
            interNetTaxableLongTerm_20: getNetTaxableLongTerm_Inter(
              values.longTermCG_10,
              values.longTermCG_20,
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              false
            ),

            netTaxableGainShort_15AfterSetOff: getShortGain(
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_20,
              values.lossBroughtForward,
              true
            ),
            netTaxableGainShort_NormalAfterSetOff: getShortGain(
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_20,
              values.lossBroughtForward,
              false
            ),
            netTaxableGainLong_10AfterSetOff: getLongGain(
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward,
              true
            ),
            netTaxableGainLong_20AfterSetOff: getLongGain(
              values.shortTermCG_15,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward,
              false
            ),
            shortTermCG_20: getInteger(values.shortTermCG_20),
            longTermCG_12_5: getInteger(values.longTermCG_12_5),
            netTaxableShortTerm20:getNetTaxableTerm_Intra(
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              true
            ),
            interNetTaxableShortTerm_20:getNetTaxableShortTerm_Inter(
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_12_5,
              values.longTermCG_20,
              true
            ),
            intraNetTaxableLongTerm_12_5:getNetTaxableTerm_Intra(
              values.longTermCG_12_5,
              values.longTermCG_20,
              true
            ),
            interNetTaxableLongTerm_12_5:getNetTaxableLongTerm_Inter(
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              true
            ),
            netTaxableGainShort_20AfterSetOff:getShortGain(
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.lossBroughtForward,
              true
            ),
            netTaxableGainLong_12_5AfterSetOff:getLongGain(
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward,
              true
            ),
          }).then((response) => {
            showToast({
              message: "Capital Gain submitted successfully!",
              severity: "success",
            });
            setSelectedTab(5);
            navigate(`/advance-tax/quarter/${id}/others`);
          });
        }}
      >
        {(formikProps) => (
          <>
            <SizedBox height={10} width={10}></SizedBox>
            <Grid container>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>
                    <Typography>
                      Please upload your Capital Gains documents here.
                    </Typography>
                  </AlertTitle>
                </Alert>
              </Grid>
            </Grid>

            <SizedBox height={30} width={10}></SizedBox>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="shortTermCG_15"
                      label="Short Term CG at 15%"
                      fullWidth
                      disabled={isClient}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "shortTermCG_15",
                          e.target.value
                        );
                      }}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={formikProps.values.shortTermCG_15}
                      error={
                        formikProps.touched.shortTermCG_15 &&
                        !!formikProps.errors.shortTermCG_15
                      }
                      helperText={
                        formikProps.touched.shortTermCG_15 &&
                        formikProps.errors.shortTermCG_15
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="longTermCG_10"
                      label="Long term CG at 10%"
                      fullWidth
                      disabled={isClient}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={formikProps.values.longTermCG_10}
                      error={
                        formikProps.touched.longTermCG_10 &&
                        !!formikProps.errors.longTermCG_10
                      }
                      helperText={
                        formikProps.touched.longTermCG_10 &&
                        formikProps.errors.longTermCG_10
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="shortTermCG_20"
                      label="Short Term CG at 20%"
                      fullWidth
                      disabled={isClient}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "shortTermCG_20",
                          e.target.value
                        );
                      }}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={formikProps.values.shortTermCG_20}
                      error={
                        formikProps.touched.shortTermCG_20 &&
                        !!formikProps.errors.shortTermCG_20
                      }
                      helperText={
                        formikProps.touched.shortTermCG_20 &&
                        formikProps.errors.shortTermCG_20
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="longTermCG_12_5"
                      label="Long term CG at 12.5%"
                      fullWidth
                      disabled={isClient}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={formikProps.values.longTermCG_12_5}
                      error={
                        formikProps.touched.longTermCG_12_5 &&
                        !!formikProps.errors.longTermCG_12_5
                      }
                      helperText={
                        formikProps.touched.longTermCG_12_5 &&
                        formikProps.errors.longTermCG_12_5
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="shortTermCG_Marginal"
                      label="Short Term CG at marginal rates"
                      disabled={isClient}
                      fullWidth
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.shortTermCG_Marginal}
                      error={
                        formikProps.touched.shortTermCG_Marginal &&
                        !!formikProps.errors.shortTermCG_Marginal
                      }
                      helperText={
                        formikProps.touched.shortTermCG_Marginal &&
                        formikProps.errors.shortTermCG_Marginal
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="longTermCG_20"
                      label="Long term CG at 20%"
                      disabled={isClient}
                      fullWidth
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.longTermCG_20}
                      error={
                        formikProps.touched.longTermCG_20 &&
                        !!formikProps.errors.longTermCG_20
                      }
                      helperText={
                        formikProps.touched.longTermCG_20 &&
                        formikProps.errors.longTermCG_20
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableShortTerm"
                      label="Intra - Net short term gain 15%"
                      disabled={true}
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm_Intra(
                        formikProps.values.shortTermCG_15,
                        formikProps.values.shortTermCG_Marginal,
                        true
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableLongTerm"
                      label="Intra - Net long term gain 10%"
                      disabled={true}
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm_Intra(
                        formikProps.values.longTermCG_10,
                        formikProps.values.longTermCG_20,
                        true
                      )}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableShortTerm20"
                      label="Intra - Net short term gain 20%"
                      disabled={true}
                      fullWidth
                      //onChange={formikProps.handleChange}
                      //onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm_Intra(
                        formikProps.values.shortTermCG_20,
                        formikProps.values.shortTermCG_Marginal,
                        true
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="intraNetTaxableLongTerm_12_5"
                      label="Intra - Net long term gain 12.5%"
                      disabled={true}
                      fullWidth
                      //onChange={formikProps.handleChange}
                      //onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm_Intra(
                        formikProps.values.longTermCG_12_5,
                        formikProps.values.longTermCG_20,
                        true
                      )}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableShortTermNormal"
                      label="Intra - Net short term gain normal"
                      disabled={true}
                      fullWidth
                      // onChange={formikProps.handleChange}
                      // onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm_Intra(
                        formikProps.values.shortTermCG_15,
                        formikProps.values.shortTermCG_Marginal,
                        false
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableLongTerm"
                      label="Intra - Net long term gain 20%"
                      disabled={true}
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm_Intra(
                        formikProps.values.longTermCG_10,
                        formikProps.values.longTermCG_20,
                        false
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {!isDisabled && (
                  <Grid item xs={12} sm={12} md={12}>
                    <DisplayFiles
                      key="Adv_CapitalGains"
                      {...{
                        formikProps,
                        type: "Adv_CapitalGains",
                        buttonText: "Upload File",
                        isClient,
                      }}
                    ></DisplayFiles>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                <SizedBox height={30}></SizedBox>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="Enter your comments/notes here"
                    type="text"
                    name="comment"
                    minRows={5}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.comment}
                    error={
                      formikProps.touched.comment &&
                      !!formikProps.errors.comment
                    }
                    helperText={
                      formikProps.touched.comment && formikProps.errors.comment
                    }
                    disabled={isDisabled || formikProps.isSubmitting}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <SizedBox height={30}></SizedBox>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disabled={isDisabled || formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableShortTermIntra"
                    label="Inter - Net short term gain 15%"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableShortTerm_Inter(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_20,
                      true
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableLongTermIntra"
                    label="Inter - Net long term gain 10%"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    //value={formikProps.values.netTaxableLongTerm}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableLongTerm_Inter(
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_20,
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_Marginal,
                      true
                    )}
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="interNetTaxableShortTerm_20"
                    label="Inter - Net short term gain 20%"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableShortTerm_Inter(
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      true
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="interNetTaxableLongTerm_12_5"
                    label="Inter - Net long term gain 12.5%"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    //value={formikProps.values.netTaxableLongTerm}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableLongTerm_Inter(
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      true
                    )}
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableShortTermNormalIntra"
                    label="Inter - Net short term gain normal"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableShortTerm_Inter(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_20,
                      false
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="interNetTaxableLongTerm_20"
                    label="Inter - Net long term gain 20%"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableLongTerm_Inter(
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_20,
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_Marginal,
                      false
                    )}
                  />
                </Grid>
              </Grid>

              <SizedBox height={30} width={10}></SizedBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="lossBroughtForward"
                    label="Short Term Loss B/f"
                    disabled={isClient}
                    fullWidth
                    maximumValue="0"
                    minimumValue="-999999999"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.lossBroughtForward}
                    error={
                      formikProps.touched.lossBroughtForward &&
                      !!formikProps.errors.lossBroughtForward
                    }
                    helperText={
                      formikProps.touched.lossBroughtForward &&
                      formikProps.errors.lossBroughtForward
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="longLossBroughtForward"
                    label="Long Term Loss B/f"
                    disabled={isClient}
                    fullWidth
                    maximumValue="0"
                    minimumValue="-999999999"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.longLossBroughtForward}
                    error={
                      formikProps.touched.longLossBroughtForward &&
                      !!formikProps.errors.longLossBroughtForward
                    }
                    helperText={
                      formikProps.touched.longLossBroughtForward &&
                      formikProps.errors.longLossBroughtForward
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="totalLossBroughtForward"
                    label="Total Loss B/f"
                    disabled={true}
                    fullWidth
                    maximumValue="999999999"
                    minimumValue="0"
                    value={formikProps.values.totalLossBroughtForward}
                    error={
                      formikProps.touched.totalLossBroughtForward &&
                      !!formikProps.errors.totalLossBroughtForward
                    }
                    helperText={
                      formikProps.touched.totalLossBroughtForward &&
                      formikProps.errors.totalLossBroughtForward
                    }
                  />
                </Grid>
              </Grid>

              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGain"
                    label="Net Short Term Gain 15% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getShortGain(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      true
                    )}
                  />
                  {/* <Typography style={{ color: "red", fontSize: 12 }}>
                      {msg}
                    </Typography> */}
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainLong"
                    label="Net Long Term Gain 10% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getLongGain(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longLossBroughtForward,
                      true
                    )}
                  />
                </Grid>
              </Grid>

              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainShort_20AfterSetOff"
                    label="Net Short Term Gain 20% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getShortGain(
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      true
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainLong_12_5AfterSetOff"
                    label="Net Long Term Gain 12.5% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getLongGain(
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longLossBroughtForward,
                      true
                    )}
                  />
                </Grid>
              </Grid>

              <SizedBox height={30} width={10}></SizedBox>


              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainNormalAfterSetoff"
                    label="Net Short Term Gain Normal after set-off of b/f"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getShortGain(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      false
                    )}
                  />
                  {/* <Typography style={{ color: "red", fontSize: 12 }}>
                      {msg}
                    </Typography> */}
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainLongAfterSetOff"
                    label="Net Long Term Gain 20% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getLongGain(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longLossBroughtForward,
                      false
                    )}
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
}
export default CG;
