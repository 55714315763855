import React from "react";
import { Formik } from "formik";
import API from "../../services/api";
import { Button, MenuItem, TextField } from "@material-ui/core";
import { useToastDisplay } from "../../Common/useToastDisplay";
import SizedBox from "../SizedBox";
import ProfileStatus from "../../Common/ProfileStatus";

const API_URL = process.env.REACT_APP_TAXPROFILE_CONTROLLER;

export default function ChangeStatus({ row, setOpen, refetch }) {
  const showToast = useToastDisplay();
  return (
    <Formik
      initialValues={{
        taxProfileId: row.taxProfileId,
        statusId: 0,
      }}
      onSubmit={async (values, actions) => {
        if (values.taxProfileId === "00000000-0000-0000-0000-000000000000") {
          showToast({
            message: "Please create tax profile for this client",
            severity: "error",
          });
          return;
        }
        if (values.statusId <= 1) {
          showToast({
            message: "Please select status to change",
            severity: "error",
          });
          return;
        }
        await API.post(API_URL + "ChangeStatus", values)
          .then((res) => res.data)
          .then((f) => {
            showToast({
              message: "Status changed successfully!",
              severity: "success",
            });
            refetch();
            setOpen(false);
          })
          .catch((e) => console.log("API FAILED", e));
      }}
      // validationSchema={Yup.object({
      //   advisorId: Yup.string().required("Please select advisor"),
      // })}
    >
      {(formikProps) => (
        <div
          style={{
            height: 450,
            width: 400,
            backgroundColor: "#fff",
            elevation: 0.5,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                justifyContent: "center",
              }}
            >
              <h2>Change Status</h2>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              padding: 10,
            }}
          >
            <div style={{ fontSize: 14 }}>
              Client Name : {row?.firstName} {row?.lastName}
            </div>
            <div style={{ fontSize: 14, marginTop: 15 }}>
              Current Status : {ProfileStatus[row?.status].name ?? ""}
            </div>
            <SizedBox height={15}></SizedBox>
            <TextField
              name="statusId"
              select
              label="Select Status"
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              error={
                formikProps.touched.statusId && !!formikProps.errors.statusId
              }
              helperText={
                formikProps.touched.statusId && formikProps.errors.statusId
              }
              value={formikProps.values.statusId}
              onChange={(e) => {
                formikProps.setFieldValue("statusId", e.target.value);
              }}
            >
              <MenuItem value="0">
                <em>Select</em>
              </MenuItem>
              {[
                ProfileStatus.Pipeline,
                ProfileStatus.Initiated,
                ProfileStatus.Assigned,
                ProfileStatus.InProcess,
                ProfileStatus.PendingFromClient,
                ProfileStatus.PendingForInternalReview,
                ProfileStatus.TaxSummaryPrepared,
                ProfileStatus.TaxSummaryPayable,
                ProfileStatus.TaxSummaryRejected,
                ProfileStatus.TaxSummaryApproved,
                ProfileStatus.ReadyToEfile,
                ProfileStatus.Efile,
                ProfileStatus.EVerified,
              ].map((t) => {
                return (
                  <MenuItem key={t.id} value={t.id}>
                    <em>{t.name}</em>
                  </MenuItem>
                );
              })}
            </TextField>
            <SizedBox height={10}></SizedBox>
          </div>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ backgroundColor: "#EE9A4D" }}
              variant="contained"
              onClick={formikProps.handleSubmit}
            >
              Save
            </Button>
            <SizedBox width={10}></SizedBox>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
}
