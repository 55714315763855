import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const CustomPasswordField = ({ field, form: { touched }, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <TextField
        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...field}
        {...props}
      />
      {touched[field.name]}
    </div>
  );
};
export default CustomPasswordField;
