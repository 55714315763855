import React, { useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MyFixedProfile from "../../components/Profile/MyFixedProfile";
import MyAnnualProfile from "../../components/Profile/MyAnnualProfile";
import { IconButton, Grid, CircularProgress } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useToastDisplay } from "../../Common/useToastDisplay";
import { useAuth } from "../../provider/auth";
import { useQuery } from "react-query";
import API from "../../services/api";
import ServiceType from "../../Common/ServiceType";

const useStyles = makeStyles((theme) => ({
  menu: {
    color: "#fff",
    textDecoration: "none",
    borderRadius: 5,
    margin: 10,
    textAlign: "center",
    backgroundColor: "#8e99d5",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#c5c8d9",
    },
    padding: theme.spacing(2),
  },
}));
const API_URL = process.env.REACT_APP_USERPROFILE_CONTROLLER;

export default function IncomeProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  var classes = useStyles();
  const { id } = useParams();
  const auth = useAuth();
  const showToast = useToastDisplay();

  const franchiseList = [
    {
      id: 1,
      name: "My Profile Fixed",
      url: `/incomeprofile/${id}?type=income`,
    },
    {
      id: 2,
      name: "Residential Status",
      url: `/incomeprofile/${id}/residential`,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(
    franchiseList.find((t) => t.url === location.pathname)?.id ?? 1
  );

  const { data, isLoading } = useQuery(
    ["ALL_USER_INCOME_PROFILE", id],
    async () => {
      return API.get(API_URL + `GetAllIncomeUsers/${id??"00000000-0000-0000-0000-000000000000"}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );

  return (
    <Grid container spacing={2}>
      <Grid item md={1}></Grid>
      <Grid item xs={11} sm={11} md={10}>
        <div style={{ marginTop: 16 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              onClick={(e) => navigate("/")}
            >
              <ArrowBack />
            </IconButton>
            {franchiseList &&
              franchiseList.map((t, index) => {
                return (
                  <div
                    key={t.id}
                    className={classes.menu}
                    style={
                      selectedTab === t.id
                        ? { backgroundColor: "#e5ab40" }
                        : null
                    }
                    onClick={() => {
                      setSelectedTab(t.id);
                      navigate(t.url);
                    }}
                  >
                    {t.name}
                  </div>
                );
              })}
          </div>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Routes>
            <Route
              path="/"
              element={
                <MyFixedProfile
                  {...{
                    showToast,
                    setSelectedTab,
                    role: auth.user.role,
                    service: ServiceType.INCOMETAX,
                  }}
                />
              }
            />
            <Route
              path="/residential"
              element={
                <MyAnnualProfile
                  {...{
                    showToast,
                    role: auth.user.role,
                    allProfileData: data,
                    service: ServiceType.INCOMETAX,
                  }}
                />
              }
            />
          </Routes>
        </div>
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
}
