import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  Card,
  Badge,
} from '@material-ui/core';
import { Field, Form, FieldArray, Formik } from 'formik';

import SizedBox from './../../SizedBox';
import { IconButton } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import API from '../../../services/api';
import { getInteger } from '../../../Common/helperMethods';
import CustomNumberField from '../../Common/CustomNumberField';
import CustomDropDownField from '../../Common/CustomDropDownField';
import StateList from '../../../Common/StateList';
import CountryList from '../../../Common/CountryList';
import { Alert, AlertTitle } from '@material-ui/lab';

const DIRECTOR_API_URL = process.env.REACT_APP_IMMOVABLE_ASSET_CONTROLLER;
export default function Immovable({ setSelectedSubTab, client, showToast,isDisable }) {
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ['ImmovableData', id],
    async () => {
      return API.get(DIRECTOR_API_URL + `GetImmovableAssetDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const CustomTextComponent = ({ field, form: { touched }, ...props }) => (
    <div>
      <TextField {...field} {...props} />
      {touched[field.name]}
    </div>
  );

  const initialValues = {
    rentals:
      data && data.length > 0
        ? data
        : [
            {
              description: '',
              flatNo: '',
              buildingName: '',
              road: '',
              area: '',
              city: '',
              state: '',
              country: '',
              pincode: '',
              amount: 0,
              remarks: '',
              assetId: '00000000-0000-0000-0000-000000000000',
            },
          ],
  };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Alert style={{ flex: 3 }} severity="info">
        <AlertTitle>
          Please fill only if the total income exceeds Rs. 50 lakhs
        </AlertTitle>
      </Alert>
      <SizedBox height={20} width={10}></SizedBox>

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const mappedData = values.rentals.map((t, index) => {
            return {
              ...t,
              amount: getInteger(t.amount),
              id: index,
            };
          });

          if (mappedData.length > 25) {
            showToast({
              message: 'You can add max 25 ',
              severity: 'error',
            });
            return;
          }

          const input = { taxProfileId: id, immovableAssetVM: mappedData };
          API.post(DIRECTOR_API_URL + `AddImmovableAssetDetails`, {
            ...input,
          })
            .then((response) => {
              showToast({
                message: 'Immovable asset submitted successfully!',
                severity: 'success',
              });
              setSelectedSubTab(3);
              //navigate(`/income/filing/${id}/disclosures/other`);
            })
            .catch((err) => console.log('err', err));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="rentals">
              {({ insert, remove, push }) => (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                  }}
                >
                  {values.rentals.length > 0 &&
                    values.rentals.map((rental, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Badge
                          key={index}
                          badgeContent={index + 1}
                          style={{ top: '14px' }}
                          color="primary"
                        ></Badge>
                        <Card
                          style={{ marginTop: '5px', marginBottom: '5px' }}
                          variant="outlined"
                        >
                          <Grid
                            style={{
                              padding: 15,
                              borderColor: 'red',
                              borderWidth: 1,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              display: 'flex',
                            }}
                            key={index}
                          >
                            <Grid container spacing={3} columns={11}>
                              <Grid item xs={12}>
                                <Grid
                                  columns={12}
                                  spacing={3}
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item xs={6} md={4} lg={4}>
                                    <Field
                                      variant="outlined"
                                      label="Description"
                                      component={CustomTextComponent}
                                      name={`rentals.${index}.description`}
                                      type="text"
                                      fullWidth
                                      size="small"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `rentals.${index}.description`,
                                          e.target.value
                                        );
                                      }}
                                      value={rental.description}
                                      disabled={isDisable}
                                    />
                                  </Grid>
                                  <Grid item xs={6} md={4} lg={4}>
                                    <Field
                                      variant="outlined"
                                      label="Flat/Door/Block No."
                                      component={CustomTextComponent}
                                      name={`rentals.${index}.flatNo`}
                                      type="text"
                                      fullWidth
                                      size="small"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `rentals.${index}.flatNo`,
                                          e.target.value
                                        );
                                      }}
                                      value={rental.flatNo}
                                      disabled={isDisable}
                                    />
                                  </Grid>
                                  <Grid item xs={6} md={4} lg={4}>
                                    <Field
                                      variant="outlined"
                                      label="Name of Premises/Building/Village"
                                      component={CustomTextComponent}
                                      name={`rentals.${index}.buildingName`}
                                      type="text"
                                      fullWidth
                                      size="small"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `rentals.${index}.buildingName`,
                                          e.target.value
                                        );
                                      }}
                                      value={rental.buildingName}
                                      disabled={isDisable}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  variant="outlined"
                                  label="Road/Street/Post Office"
                                  fullWidth
                                  component={CustomTextComponent}
                                  name={`rentals.${index}.road`}
                                  type="text"
                                  size="small"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.road`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.road}
                                  disabled={isDisable}
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomTextComponent}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  label="Area/ locality"
                                  name={`rentals.${index}.area`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.area`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.area}
                                  disabled={isDisable}
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomTextComponent}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  label="Town/City/District"
                                  name={`rentals.${index}.city`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.city`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.city}
                                  disabled={isDisable}
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomDropDownField}
                                  size="small"
                                  variant="outlined"
                                  label="State"
                                  menuItems={StateList}
                                  value={rental.state}
                                  name={`rentals.${index}.state`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.state`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={isDisable}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomDropDownField}
                                  size="small"
                                  variant="outlined"
                                  label="Country"
                                  value={rental.country}
                                  name={`rentals.${index}.country`}
                                  placeholder=""
                                  menuItems={CountryList}
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.country`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={isDisable}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomTextComponent}
                                  size="small"
                                  variant="outlined"
                                  label="Pincode"
                                  value={rental.pincode}
                                  name={`rentals.${index}.pincode`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.pincode`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={isDisable}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomNumberField}
                                  size="small"
                                  variant="outlined"
                                  maximumValue="900000000"
                                  label="Amount (cost) in Rs."
                                  value={getInteger(rental.amount)}
                                  name={`rentals.${index}.amount`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.amount`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={isDisable}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomTextComponent}
                                  size="small"
                                  variant="outlined"
                                  label="Remarks"
                                  value={rental.remarks}
                                  name={`rentals.${index}.remarks`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.remarks`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={isDisable}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                        <div
                          style={{
                            justifyContent: 'flex-end',
                            position: 'relative',
                            top: -11,
                            left: -19,
                          }}
                        >
                          <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            aria-controls="profile-menu"
                            style={{ padding: 6 }}
                            onClick={() => remove(index)}
                            disabled={isDisable}
                          >
                            <CloseOutlined />
                          </IconButton>
                        </div>
                      </div>
                    ))}

                  <SizedBox height={30} width={20}></SizedBox>
                  <Grid
                    container
                    spacing={1}
                    columns={12}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginBottom: '30px' }}
                  >
                    <Grid item xs={6} sm={6} md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          if (values.rentals.length > 25) {
                            showToast({
                              message: 'You can add max 25 asset',
                              severity: 'error',
                            });
                            return;
                          }
                          push({
                            nameOfInstistute: '',
                            typeOfAccount: '',
                            accountNumber: '',
                            address: '',
                            accountOpeningDate: '',
                            priorYearValues: 0,
                            priorYearClosingBalance: 0,
                            currentMaximumValues: 0,
                            currentClosingBalance: 0,
                          });
                        }}
                        disabled={isDisable}
                      >
                        Add more
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      style={{ textAlign: 'end' }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        //disabled={values.rentals.length === 0}
                        color="primary"
                      >
                        Save and Next
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
}
