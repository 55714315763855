import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../services/api';
import DisplayFiles from '../Common/displayFiles';
import {
  FormControl,
  MenuItem,
  Button,
  TextField,
  Box,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import SizedBox from '../SizedBox';
import { Formik } from 'formik';
const SALARY_API_URL = process.env.REACT_APP_SALARY_CONTROLLER;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  select: {
    height: 40,
  },
}));

export default function Salary({ setSelectedTab, client, showToast,isDisable }) {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  //const [fileList, setFileList] = useState([]);
  //const [form16AList, setForm16AList] = useState([]);
  //const [attchmentList, setAttachmentList] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    'Salary_Data',
    async () => {
      return API.get(SALARY_API_URL + `GetSalaryDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  // const queryClient = useQueryClient();
  const salaryData = data
    ? {
        ...data,
        //fileList: data.fileList.filter((t) => t.fileType === "Form16"),
        //form16AList: data.fileList.filter((t) => t.fileType === "Form16BA"),
        rentalAccomodation: data.rentalAccomodation.toString(),
        ltA_Exemption: data.ltA_Exemption.toString(),
      }
    : {
        fileList: [],
        salaryId: '00000000-0000-0000-0000-000000000000',
        rentalAccomodation: '',
        ltA_Exemption: '',
        comment: '',
      };

  // useEffect(() => {
  //   if (data && data.fileList && data.fileList.length > 0) {
  //     setFileList(data.fileList);
  //   }
  // }, [data]);

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <Formik
      initialValues={salaryData}
      enableReinitialize={true}
      onSubmit={async (values, actions) => {
        API.post(SALARY_API_URL + 'AddSalaryDetails', {
          ...values,
          taxProfileId: id,
          //fileList,
          rentalAccomodation:
            values.rentalAccomodation === 'true' ? true : false,
          ltA_Exemption: values.ltA_Exemption === 'true' ? true : false,
        }).then((response) => {
          showToast({
            message: 'Salary submitted successfully!',
            severity: 'success',
          });
          setSelectedTab(2);
          navigate(`/income/filing/${id}/properties`);
        });
      }}
    >
      {(formikProps) => (
        <>
          <Alert style={{ flex: 3 }} severity="info">
            <AlertTitle>
              Please ensure that you upload all parts of the Form 16 such as
              Part A, Part B, Form 12BA and the break-up of total salary.
              <SizedBox height={10}></SizedBox>
              Upload salary slips if you have earned salary abroad.
            </AlertTitle>
          </Alert>
          <SizedBox height={20}></SizedBox>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <DisplayFiles
                key="form16"
                {...{
                  // fileList,
                  // setFileList,
                  formikProps,
                  type: 'Salary',
                  buttonText:
                    (formikProps.values.fileList?.length || 0) === 0
                      ? 'Upload Form 16/ Form 12BA'
                      : 'Upload more Form 16/ 12BA',
                      isDisable,
                }}
              ></DisplayFiles>
              {/* <SizedBox height={30}></SizedBox>
              <DisplayFiles
                key="form16BA"
                {...{
                  fileList: form16AList,
                  setFileList: setForm16AList,
                  type: "Form16BA",
                  buttonText:
                    (form16AList?.length || 0) === 0
                      ? "Upload form16 BA"
                      : "Upload more form 16 BA",
                }}
              ></DisplayFiles> */}

              <SizedBox height={30}></SizedBox>
              <Grid item xs={12} sm={8} md={8}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    name="rentalAccomodation"
                    select
                    label="I'm staying in rented accommodation"
                    variant="outlined"
                    size="small"
                    error={
                      formikProps.touched.rentalAccomodation &&
                      !!formikProps.errors.rentalAccomodation
                    }
                    helperText={
                      formikProps.touched.rentalAccomodation &&
                      formikProps.errors.rentalAccomodation
                    }
                    value={formikProps.values.rentalAccomodation}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        'rentalAccomodation',
                        e.target.value
                      );
                    }}
                       disabled={isDisable}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value="true">
                      <em>Yes</em>
                    </MenuItem>
                    <MenuItem value="false">
                      <em>No</em>
                    </MenuItem>
                  </TextField>
                  {/* <HelpText>
                    Select Ordinarily resident, if you have been generally in
                    India
                  </HelpText> */}
                </FormControl>
              </Grid>

              <SizedBox height={30}></SizedBox>
              <Grid item xs={12} sm={8} md={8}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    name="ltA_Exemption"
                    select
                    label="I want to claim LTA Exemption this year"
                    variant="outlined"
                    size="small"
                    error={
                      formikProps.touched.ltA_Exemption &&
                      !!formikProps.errors.ltA_Exemption
                    }
                    helperText={
                      formikProps.touched.ltA_Exemption &&
                      formikProps.errors.ltA_Exemption
                    }
                    value={formikProps.values.ltA_Exemption}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        'ltA_Exemption',
                        e.target.value
                      );
                    }}
                         disabled={isDisable}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value="true">
                      <em>Yes</em>
                    </MenuItem>
                    <MenuItem value="false">
                      <em>No</em>
                    </MenuItem>
                  </TextField>
                  {/* <HelpText>
                    Select Ordinarily resident, if you have been generally in
                    India
                  </HelpText> */}
                </FormControl>
                {/* <FormControlLabel
                  sx={{ color: "text.primary" }}
                  control={<Switch checked={true} onChange={() => {}} />}
                  label="Show Badge"
                /> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="Enter your comments/notes here"
                    type="text"
                    name="comment"
                    minRows={11}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.comment}
                    error={
                      formikProps.touched.comment &&
                      !!formikProps.errors.comment
                    }
                    helperText={
                      formikProps.touched.comment && formikProps.errors.comment
                    }
                    disabled={isDisable || formikProps.isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}></Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                     // disabled={formikProps.isSubmitting}
                     disabled={isDisable || formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid> 
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
