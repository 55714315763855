import React, { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon, CloudDownload } from "@material-ui/icons";
import { Field, FieldArray } from "formik";
import useStyles from "./styles";
import CustomTextField from "./CustomTextField";
import CustomPasswordField from "./CustomPasswordField";
import API from "../../services/api";
import SizedBox from "../SizedBox";
import { useLocation, useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_FILE_CONTROLLER;

export default function DisplayFiles(props) {
  const {
    formikProps,
    type,
    buttonText,
    isClient,
    isCommentOn = false,
    maxSize = 15,
    isDisable,
    extensionAllowed = ".pdf, .xls, .xlsx, .doc, .docx, .csv, .jpeg, .png, .jpg, .bmp",
  } = props;
  const { id } = useParams();
  const location = useLocation();
  const isIncomeTax = location.pathname.indexOf("income") > -1;
  const taxProfileId = isIncomeTax
    ? id
    : "00000000-0000-0000-0000-000000000000";
  const [isFileLoading, setIsFileLoading] = useState(false);
  var classes = useStyles();

  const handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;
    setIsFileLoading(true);
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = (event) => {
        console.log("event", event.target);
      };

      reader.readAsDataURL(files[0]);

      const formData = new FormData();
      formData.append("fileData", files[0]);

      API.post(API_URL + "upload", formData)
        .then((res) => {
          const newFileList = [
            ...formikProps.values.fileList,
            {
              fileName: files[0].name,
              fileId: res.data,
              fileType: type,
              password: "",
              comment: "",
              isNotUploaded: true,
              isUploadedByClient: isClient,
            },
          ];
          formikProps.setFieldValue("fileList", newFileList);
          console.log("Uploaded successfully");
          setIsFileLoading(false);
        })
        .catch((err) => {
          console.log("error", err);
          setIsFileLoading(false);
        });
    }
  };

  return (
    <div>
      <FieldArray name="fileList">
        {({ insert, remove, push }) => (
          <div>
            {formikProps.values &&
              formikProps.values.fileList &&
              formikProps.values.fileList.length > 0 &&
              formikProps.values.fileList
                //.filter((t) => t.fileType === type)
                .map((s, index) => {
                  if (s.fileType === type)
                    return (
                      <div key={index}>
                        <Grid container spacing={1}>
                          <Grid item xs={isCommentOn ? 3 : 5}>
                            {s.fileName}
                          </Grid>
                          <Grid item xs={isCommentOn ? 2 : 5}>
                            <Field
                              id={`fileList.${index}.password`}
                              variant="outlined"
                              label="Password, if any"
                              component={CustomPasswordField}
                              name={`fileList.${index}.password`}
                              fullWidth
                              size="small"
                              onBlur={(e) => {
                                formikProps.setFieldValue(
                                  `fileList.${index}.password`,
                                  e.target.value
                                );
                              }}
                              value={s.password}
                            />
                          </Grid>
                          {isCommentOn && (
                            <Grid item xs={5} sm={5} md={5}>
                              <Field
                                component={CustomTextField}
                                type="text"
                                name={`fileList.${index}.comment`}
                                label="Comment"
                                variant="outlined"
                                placeholder="e.g This is my Zerodha Capital Gains Statement"
                                value={s.comment}
                                fullWidth
                                size="small"
                                onChange={(e) => {
                                  formikProps.setFieldValue(
                                    `fileList.${index}.comment`,
                                    e.target.value
                                  );
                                }}
                              />
                            </Grid>
                          )}
                         
                          <Grid item xs={1} sm={1} md={1}>
                            <IconButton
                              size="small"
                              aria-haspopup="true"
                              color="inherit"
                              title="Download File"
                              aria-controls="profile-menu"
                              onClick={(e) => {
                                API.get(
                                  API_URL +
                                    `GetDownloadFromS3/${s.fileId}/${taxProfileId}`,
                                  {
                                    responseType: "blob",
                                  }
                                ).then((response) => {
                                  const url = window.URL.createObjectURL(
                                    new Blob([response.data])
                                  );
                                  const link = document.createElement("a");
                                  link.href = url;
                                  link.setAttribute(
                                    "download",
                                    `${s.fileName}`
                                  );
                                  document.body.appendChild(link);
                                  link.click();

                                  // Clean up and remove the link
                                  link.parentNode.removeChild(link);
                                });
                              }}
                            >
                              <CloudDownload />
                            </IconButton>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1}>
                          {(s.isNotUploaded || !isClient) && !isDisable && (
                              <IconButton
                                size="small"
                                aria-haspopup="true"
                                color="inherit"
                                title="Delete"
                                aria-controls="profile-menu"
                                onClick={(e) => {
                                  const newfileList =
                                    formikProps.values.fileList.filter(
                                      (f) => f.fileId !== s.fileId
                                    );
                                  formikProps.setFieldValue(
                                    "fileList",
                                    newfileList
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                        <SizedBox height={20}></SizedBox>
                      </div>
                    );
                  return null;
                })}{" "}
          </div>
        )}
      </FieldArray>
      {formikProps.values.fileList.filter((s) => s.fileType === type).length <
        maxSize && (
        <div>
          <input
            id={`car${type}`}
            type="file"
            accept={extensionAllowed}
            className={classes.input}
            onChange={handleFileChange}
          />

          <div>
            <div variant="contained">
              <label
                htmlFor={`car${type}`}
                style={{
                  padding: 10,
                  backgroundColor: "#334f64",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  fontSize: 12,
                  width: 300,
                  alignSelf: "center",
                }}
              >
                {buttonText}
              </label>
            </div>

            {isFileLoading && <span>Uploading...</span>}
          </div>
        </div>
      )}
    </div>
  );
}
