import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Button, Grid, CircularProgress, Card, Badge } from "@material-ui/core";

import { Field, Form, FieldArray, Formik } from "formik";
import SizedBox from "./../../SizedBox";
import { IconButton } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import API from "../../../services/api";
import { getInteger } from "../../../Common/helperMethods";
import CustomNumberField from "../../Common/CustomNumberField";
import CustomDropDownField from "../../Common/CustomDropDownField";
import CustomTextField from "../../Common/CustomTextField";
import { format, parseISO } from "date-fns";

const FOREIGN_API_URL = process.env.REACT_APP_FOREIGN_CONTROLLER;

const AccountType = [
  "Foreign Bank Account",
  "Custodial",
  "Foreign Equity & Debt",
  "Foreign Cash Value Insurance",
  "Financial Interest in Any Entity",
  "Immovable Property",
  "Other Capital Asset",
  "Accounts with signing authority",
  "Trustee, Beneficiary or Settlor in Foreign Trust",
];

export default function ForeignAsset({ setSelectedSubTab, client, showToast,isDisable }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ["ForeignAssetData", id],
    async () => {
      return API.get(FOREIGN_API_URL + `GetForeignAssetDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const initialValues = {
    rentals:
      data && data.length > 0
        ? data.map((s) => {
            return {
              ...s,
              accountOpeningDate: format(
                parseISO(s.accountOpeningDate ?? new Date()),
                "yyyy-MM-dd"
              ),
            };
          })
        : [
            {
              nameOfInstistute: "",
              typeOfAccount: "",
              accountNumber: "",
              address: "",
              accountOpeningDate: format(
                parseISO(new Date().toISOString()),
                "yyyy-MM-dd"
              ),
              priorYearValues: 0,
              priorYearClosingBalance: 0,
              currentMaximumValues: 0,
              currentClosingBalance: 0,
              foreignAssetId: "00000000-0000-0000-0000-000000000000",
            },
          ],
  };

  console.log("IntialsValues", initialValues);

  const isDisabledForClient = client?.isDisabled;

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const mappedData = values.rentals.map((t, index) => {
            return {
              nameOfInstistute: t.nameOfInstistute,
              typeOfAccount: t.typeOfAccount,
              accountNumber: t.accountNumber,
              address: t.address,
              accountOpeningDate: t.accountOpeningDate,
              priorYearValues: getInteger(t.priorYearValues),
              priorYearClosingBalance: getInteger(t.priorYearClosingBalance),
              currentMaximumValues: getInteger(t.currentMaximumValues),
              currentClosingBalance: getInteger(t.currentClosingBalance),
              foreignAssetId: t.foreignAssetId,
              id: index,
            };
          });

          if (mappedData.length > 25) {
            showToast({
              message: "You can add max 25 ",
              severity: "error",
            });
            return;
          }

          const input = { taxProfileId: id, foreignAssetVM: mappedData };
          API.post(FOREIGN_API_URL + `AddForeignAssetDetails`, {
            ...input,
          })
            .then((response) => {
              showToast({
                message: "Foreign Assets submitted successfully!",
                severity: "success",
              });
              setSelectedSubTab(1);
            })
            .catch((err) => console.log("err", err));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="rentals">
              {({ insert, remove, push }) => (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  {values.rentals.length > 0 &&
                    values.rentals.map((rental, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Badge
                          key={index}
                          badgeContent={index + 1}
                          style={{ top: "14px" }}
                          color="primary"
                        ></Badge>

                        <Card
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                          variant="outlined"
                        >
                          <Grid
                            style={{
                              padding: 15,
                              borderColor: "red",
                              borderWidth: 1,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                            key={index}
                          >
                            <Grid container spacing={2} columns={12}>
                              {/* <Grid item xs={12}>
                                <Grid
                                  columns={12}
                                  spacing={2}
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                > */}
                              <Grid item xs={3} md={2} lg={2}>
                                <Field
                                  variant="outlined"
                                  label="Bank/Asset Name"
                                  component={CustomTextField}
                                  name={`rentals.${index}.nameOfInstistute`}
                                  type="text"
                                  disabled={isDisable || isDisabledForClient}
                                  fullWidth
                                  size="small"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.nameOfInstistute`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.nameOfInstistute}
                                />
                              </Grid>
                              <Grid item xs={3} md={2} lg={2}>
                                <Field
                                  variant="outlined"
                                  label="Account Type"
                                  component={CustomDropDownField}
                                  name={`rentals.${index}.typeOfAccount`}
                                  type="text"
                                  disabled={isDisable || isDisabledForClient}
                                  fullWidth
                                  menuItems={AccountType.map((s) => {
                                    return { code: s, name: s };
                                  })}
                                  size="small"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.typeOfAccount`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.typeOfAccount}
                                />
                              </Grid>
                              <Grid item xs={3} md={2} lg={2}>
                                <Field
                                  variant="outlined"
                                  label="A/c no."
                                  fullWidth
                                  // disabled={isDisabledForClient}
                                  disabled={isDisable || isDisabledForClient}
                                  component={CustomTextField}
                                  name={`rentals.${index}.accountNumber`}
                                  type="text"
                                  size="small"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.accountNumber`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.accountNumber}
                                />{" "}
                              </Grid>
                              {/* </Grid>
                              </Grid> */}

                              <Grid item xs={4} md={3} lg={3}>
                                <Field
                                  component={CustomTextField}
                                  size="small"
                                  fullWidth
                                  disabled={isDisable || isDisabledForClient}
                                  variant="outlined"
                                  label="Address with country and ZIP"
                                  name={`rentals.${index}.address`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.address`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.address}
                                />
                              </Grid>
                              <Grid item xs={3} md={2} lg={2}>
                                <Field
                                  component={CustomTextField}
                                  size="small"
                                  type="date"
                                  variant="outlined"
                                  // disabled={isDisabledForClient}
                                  disabled={isDisable || isDisabledForClient}
                                  label="Opening date"
                                  format="yyyy-mm-dd"
                                  placeholder=""
                                  //openTo="date"
                                  //views={["year", "month", "date"]}
                                  name={`rentals.${index}.accountOpeningDate`}
                                  onChange={(e) => {
                                    console.log(
                                      "e.target.value",
                                      e.target.value
                                    );
                                    setFieldValue(
                                      `rentals.${index}.accountOpeningDate`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.accountOpeningDate}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={4} md={3} lg={3}>
                                <Field
                                  component={CustomNumberField}
                                  size="small"
                                  variant="outlined"
                                  label="Prior year Max value (Rs.)"
                                  // disabled={isDisabledForClient}
                                  disabled={isDisable || isDisabledForClient}
                                  maximumValue="900000000"
                                  value={getInteger(rental.priorYearValues)}
                                  name={`rentals.${index}.priorYearValues`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.priorYearValues`,
                                      e.target.value
                                    );
                                  }}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={4} md={3} lg={3}>
                                <Field
                                  component={CustomNumberField}
                                  size="small"
                                  variant="outlined"
                                  // disabled={isDisabledForClient}
                                  disabled={isDisable || isDisabledForClient}
                                  label="Prior year closing value (Rs.)"
                                  maximumValue="900000000"
                                  value={getInteger(
                                    rental.priorYearClosingBalance
                                  )}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.priorYearClosingBalance`,
                                      e.target.value
                                    );
                                  }}
                                  name={`rentals.${index}.priorYearClosingBalance`}
                                  placeholder=""
                                  type="text"
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={4} md={3} lg={3}>
                                <Field
                                  component={CustomNumberField}
                                  size="small"
                                  variant="outlined"
                                  maximumValue="900000000"
                                  label="Provide Max value-FY 22-23 (Rs.)"
                                  name={`rentals.${index}.currentMaximumValues`}
                                  placeholder=""
                                  type="text"
                                  // disabled={isDisabledForClient}
                                  disabled={isDisable || isDisabledForClient}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.currentMaximumValues`,
                                      e.target.value
                                    );
                                  }}
                                  value={getInteger(
                                    rental.currentMaximumValues
                                  )}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4} md={3} lg={3}>
                                <Field
                                  component={CustomNumberField}
                                  size="small"
                                  variant="outlined"
                                  maximumValue="900000000"
                                  label="Provide Closing value-FY 22-23 (Rs.)"
                                  // disabled={isDisabledForClient}
                                  disabled={isDisable || isDisabledForClient}
                                  name={`rentals.${index}.currentClosingBalance`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.currentClosingBalance`,
                                      e.target.value
                                    );
                                  }}
                                  value={getInteger(
                                    rental.currentClosingBalance
                                  )}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                        <div
                          style={{
                            justifyContent: "flex-end",
                            position: "relative",
                            top: -11,
                            left: -19,
                          }}
                        >
                          <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            disabled={isDisable}
                            aria-controls="profile-menu"
                            style={{ padding: 6 }}
                            onClick={() => remove(index)}
                          >
                            <CloseOutlined />
                          </IconButton>
                        </div>
                      </div>
                    ))}

                  <SizedBox height={30} width={20}></SizedBox>
                  <Grid
                    container
                    spacing={1}
                    columns={12}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginBottom: "30px" }}
                  >
                    <Grid item xs={6} sm={6} md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          if (values.rentals.length > 25) {
                            showToast({
                              message: "You can add max 25 asset",
                              severity: "error",
                            });
                            return;
                          }
                          push({
                            nameOfInstistute: "",
                            typeOfAccount: "",
                            accountNumber: "",
                            address: "",
                            accountOpeningDate: format(
                              parseISO(new Date().toISOString()),
                              "yyyy-MM-dd"
                            ),
                            priorYearValues: 0,
                            priorYearClosingBalance: 0,
                            currentMaximumValues: 0,
                            currentClosingBalance: 0,
                          });
                        }}
                        disabled={isDisable}
                      >
                        Add more
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isDisable || isDisabledForClient}
                        color="primary"
                      >
                        Save and Next
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>

      {/* </form> */}
    </div>
  );
}
