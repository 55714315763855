import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
const CustomDropDownField = ({ field, form: { touched }, ...props }) => (
  <div>
    <TextField select {...field} {...props}>
      <MenuItem value="">
        <em>Select</em>
      </MenuItem>
      {props.menuItems.map((s) => {
        return (
          <MenuItem value={s.code}>
            <em>{s.name}</em>
          </MenuItem>
        );
      })}
    </TextField>
    {touched[field.name]}
  </div>
);

export default CustomDropDownField;
