import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  MenuItem,
  TextField,
  Grid,
  Button,
} from '@material-ui/core';
import { useUpdateSelectedFY } from '../Common/useSelectedClient';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  select: {
    height: 40,
  },
}));

export default function DisplayService({
  serviceName,
  fyList,
  redirectToService,
}) {
  const classes = useStyles();
  const setSelectedFY = useUpdateSelectedFY();

  const [fy, setFY] = useState(fyList.length > 0 ? fyList[0] : '');
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={4}
        style={{
          backgroundColor: '#ff6500',
          textAlign: 'center',
          verticalAlign: 'middle',
          color: '#fff',
          paddingTop: 15,
        }}
      >
        <div>{serviceName}</div>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            id="fy"
            select
            label="Financial Year"
            variant="outlined"
            size="small"
            value={fy}
            onChange={(e) => {
              setFY(e.target.value);
            }}
          >
            {fyList &&
              fyList.map((s) => {
                return (
                  <MenuItem key={s} value={s}>
                    <em>FY {s}</em>
                  </MenuItem>
                );
              })}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSelectedFY(fy);
            redirectToService(fy);
          }}
        >
          Go
        </Button>
      </Grid>
    </Grid>
  );
}
