import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Asset from './CG/Asset';
import Mutual from './CG/Mutual';
import Equity from './CG/Equity';
import { Grid, Box, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../Common/TabPanel';

const useStyles = makeStyles((theme) => ({
  menu: {
    color: '#fff',
    borderRadius: 5,
    textDecoration: 'none',
    height: 39,
    margin: 10,
    textAlign: 'center',
    backgroundColor: '#8e99d5',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#c5c8d9',
    },
    padding: theme.spacing(1),
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CG({ setSelectedTab, client, showToast,isDisable }) {
  const { id } = useParams();

  //const isOtherTabVisible = data || auth.user.role != "Client" ? true : false;

  const tabList = [
    {
      id: 1,
      name: 'Indian equity investments',
      url: `/income/filing/${id}/cg/`,
    },
    {
      id: 2,
      name: 'Indian Mutual Funds',
      url: `/income/filing/${id}/cg/mutual`,
    },
    { id: 3, name: 'Other assets', url: `/income/filing/${id}/cg/asset` },
  ];
  // const [selectedSubTab, setSelectedSubTab] = useState(
  //   tabList.find((t) => t.url === location.pathname)?.id ?? 1
  // );
  const [selectedSubTab, setSelectedSubTab] = React.useState(0);

  function handleChange(event, newValue) {
    setSelectedSubTab(newValue);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        {/* <div style={{ display: "flex", flexDirection: "row" }}>
          {tabList &&
            tabList.map((t, index) => {
              return (
                <div
                  key={t.id}
                  className={classes.menu}
                  style={
                    selectedSubTab === t.id
                      ? { backgroundColor: "#edc274" }
                      : null
                  }
                  onClick={() => {
                    setSelectedSubTab(t.id);
                    navigate(t.url);
                  }}
                >
                  {t.name}
                </div>
              );
            })}
        </div> */}

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedSubTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabList &&
              tabList.map((t, index) => {
                return (
                  <Tab
                    style={{ fontSize: 12, textTransform: 'none' }}
                    label={t.name}
                    {...a11yProps(index)}
                  />
                );
              })}
          </Tabs>
        </Box>
        <TabPanel value={selectedSubTab} index={0}>
          <Equity {...{ setSelectedSubTab, client, showToast,isDisable }} />
        </TabPanel>
        <TabPanel value={selectedSubTab} index={1}>
          <Mutual {...{ setSelectedSubTab, client, showToast,isDisable }} />
        </TabPanel>
        <TabPanel value={selectedSubTab} index={2}>
          <Asset
            {...{ setSelectedSubTab, setSelectedTab, client, showToast,isDisable }}
          />
        </TabPanel>

        {/* <SizedBox height={20} width={10}></SizedBox> */}
        {/* <div style={{ margin: 10 }}>
          <Routes>
            <Route
              path="/"
              element={<Equity {...{ setSelectedSubTab, client, showToast }} />}
            />
            <Route
              path="/mutual"
              element={<Mutual {...{ setSelectedSubTab, client, showToast }} />}
            />
            <Route
              path="/asset"
              element={
                <Asset
                  {...{ setSelectedSubTab, setSelectedTab, client, showToast }}
                />
              }
            />
          </Routes>
        </div> */}
      </Grid>
    </Grid>
  );
}
