import React from "react";
import {
  TextField,
  FormControl,
  Divider,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import API from "../../services/api";
import SizedBox from "../SizedBox";
import { useToastDisplay } from "../../Common/useToastDisplay";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}));

function EditDocument({ row, setEditOpen, refetch }) {
  const showToast = useToastDisplay();
  const classes = useStyles();
  const API_URL = process.env.REACT_APP_FILE_CONTROLLER;
  const formValues = {
    taxProfileId: row.taxProfileId,
    fileId: row.fileId,
    filePassword: row.password ?? "",
    comment: row.comment ?? "",
  };

  return (
    <div style={{ backgroundColor: "#fff", elevation: 0.5 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <p style={{ fontSize: 18, color: "#363636" }}>Edit Document</p>
        <Divider
          style={{ color: "#EAEAEC", borderRadius: 2 }}
          variant="fullWidth"
        />
        {row.fileName !== "" && <h3>Name : {row.fileName}</h3>}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 20,
        }}
      >
        <Formik
          initialValues={formValues}
          onSubmit={async (values, actions) => {
            if (values.comment === "" && values.filePassword === "") {
              showToast({
                message: "Please add password or comment",
                severity: "error",
              });
              return;
            }
            API.post(API_URL + "EditDocument", {
              ...values,
              taxProfileId: row.taxProfileId,
              fileId: row.fileId,
            })
              .then((response) => {
                if (response.data) {
                  showToast({
                    message: "Document properties submitted successfully!",
                    severity: "success",
                  });
                  setEditOpen(false);
                  refetch();
                }
              })
              .catch((error) => {
                showToast({
                  message: "Unable to edit document properties",
                  severity: "error",
                });
              });
          }}
        >
          {(formikProps) => (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      id="filePassword"
                      name="filePassword"
                      fullWidth
                      label="Password"
                      variant="outlined"
                      size="small"
                      value={formikProps.values.filePassword}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={
                        formikProps.touched.filePassword &&
                        !!formikProps.errors.filePassword
                      }
                      helperText={
                        formikProps.touched.filePassword &&
                        formikProps.errors.filePassword
                      }
                    />
                  </FormControl>
                </Grid>

                <Margin></Margin>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      id="comment"
                      name="comment"
                      variant="outlined"
                      multiline={true}
                      label="Enter your comments/notes here"
                      type="text"
                      minRows={11}
                      value={formikProps.values.comment}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={
                        formikProps.touched.comment &&
                        !!formikProps.errors.comment
                      }
                      helperText={
                        formikProps.touched.comment &&
                        formikProps.errors.comment
                      }
                      disabled={formikProps.isSubmitting}
                    />
                  </FormControl>
                </Grid>
                <Margin></Margin>

                <SizedBox height={40} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save
                    </Button>
                    <SizedBox width={10}></SizedBox>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setEditOpen(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </div>
    </div>
  );
}

function Margin() {
  return <SizedBox height={25} width={25}></SizedBox>;
}

export default EditDocument;
