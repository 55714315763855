import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  Card,
  Badge,
} from "@material-ui/core";
import { Field, Form, FieldArray, Formik } from "formik";

import SizedBox from "./../../SizedBox";
import { IconButton } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import API from "../../../services/api";
import CustomDropDownField from "../../Common/CustomDropDownField";

const DIRECTOR_API_URL = process.env.REACT_APP_DIRECTORSHIP_CONTROLLER;
const COMPANY_TYPE = ["Domestic", "Foreign"];
const CURRENT_FY = process.env.REACT_APP_FY;

export default function DirectorShip({ setSelectedTab, client, showToast,isDisable }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["DirectorShipData", id],
    async () => {
      return API.get(DIRECTOR_API_URL + `GetDirectorShipDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const CustomTextComponent = ({ field, form: { touched }, ...props }) => (
    <div>
      <TextField {...field} {...props} />
      {touched[field.name]}
    </div>
  );

  const initialValues = {
    rentals:
      data && data.length > 0
        ? data
        : [
            {
              nameOfCompany: "",
              companyType: "",
              pan: "",
              sharesTypes: "",
              din: "",
              updateForFY: "",
              directorId: "00000000-0000-0000-0000-000000000000",
            },
          ],
  };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const mappedData = values.rentals;
          if (mappedData.length > 25) {
            showToast({
              message: "You can add max 25 ",
              severity: "error",
            });
            return;
          }

          const input = { taxProfileId: id, directorShipVM: mappedData };
          API.post(DIRECTOR_API_URL + `AddDirectorShipDetails`, {
            ...input,
          })
            .then((response) => {
              showToast({
                message: "Directorship submitted successfully!",
                severity: "success",
              });
              setSelectedTab(8);
              navigate(`/income/filing/${id}/summary`);
            })
            .catch((err) => console.log("err", err));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="rentals">
              {({ insert, remove, push }) => (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  {values.rentals.length > 0 &&
                    values.rentals.map((rental, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Badge
                          key={index}
                          badgeContent={index + 1}
                          style={{ top: "14px" }}
                          color="primary"
                        ></Badge>

                        <Card
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                          variant="outlined"
                        >
                          <Grid
                            style={{
                              padding: 15,
                              borderColor: "red",
                              borderWidth: 1,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                            key={index}
                          >
                            <Grid container spacing={2} columns={12}>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  variant="outlined"
                                  label="Name Of Company"
                                  component={CustomTextComponent}
                                  name={`rentals.${index}.nameOfCompany`}
                                  type="text"
                                  fullWidth
                                  size="small"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.nameOfCompany`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.nameOfCompany}
                                  disabled={isDisable}
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  variant="outlined"
                                  label="Company Type"
                                  component={CustomDropDownField}
                                  name={`rentals.${index}.companyType`}
                                  type="text"
                                  fullWidth
                                  menuItems={COMPANY_TYPE.map((s) => {
                                    return { code: s, name: s };
                                  })}
                                  size="small"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.companyType`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.companyType}
                                  disabled={isDisable}
                                />
                              </Grid>

                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  variant="outlined"
                                  label="PAN"
                                  fullWidth
                                  component={CustomTextComponent}
                                  name={`rentals.${index}.pan`}
                                  type="text"
                                  size="small"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.pan`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.pan}
                                  disabled={isDisable}
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomDropDownField}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  label="Shares are listed"
                                  name={`rentals.${index}.sharesTypes`}
                                  placeholder=""
                                  type="text"
                                  menuItems={[
                                    { code: "yes", name: "Yes" },
                                    { code: "no", name: "No" },
                                  ]}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.sharesTypes`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.sharesTypes}
                                  disabled={isDisable}
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomTextComponent}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  label="DIN"
                                  name={`rentals.${index}.din`}
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.din`,
                                      e.target.value
                                    );
                                  }}
                                  value={rental.din}
                                  disabled={isDisable}
                                />
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <Field
                                  component={CustomDropDownField}
                                  size="small"
                                  variant="outlined"
                                  label={`Status for FY ${CURRENT_FY}`}
                                  value={rental.updateForFY}
                                  name={`rentals.${index}.updateForFY`}
                                  placeholder=""
                                  menuItems={[
                                    { code: "yes", name: "Yes, I am director" },
                                    {
                                      code: "no",
                                      name: "No, I am no more director",
                                    },
                                  ]}
                                  type="select"
                                  fullWidth
                                  onChange={(e) => {
                                    setFieldValue(
                                      `rentals.${index}.updateForFY`,
                                      e.target.value
                                    );
                                  }}
                                  disabled={isDisable}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                        <div
                          style={{
                            justifyContent: "flex-end",
                            position: "relative",
                            top: -11,
                            left: -19,
                          }}
                        >
                          <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            aria-controls="profile-menu"
                            style={{ padding: 6 }}
                            onClick={() => remove(index)}
                            disabled={isDisable}
                          >
                            <CloseOutlined />
                          </IconButton>
                        </div>
                      </div>
                    ))}

                  <SizedBox height={30} width={20}></SizedBox>
                  <Grid
                    container
                    spacing={1}
                    columns={12}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginBottom: "30px" }}
                  >
                    <Grid item xs={6} sm={6} md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          if (values.rentals.length > 25) {
                            showToast({
                              message: "You can add max 25 asset",
                              severity: "error",
                            });
                            return;
                          }
                          push({
                            nameOfInstistute: "",
                            typeOfAccount: "",
                            accountNumber: "",
                            address: "",
                            accountOpeningDate: "",
                            priorYearValues: 0,
                            priorYearClosingBalance: 0,
                            currentMaximumValues: 0,
                            currentClosingBalance: 0,
                          });
                        }}
                        disabled={isDisable}
                      >
                        Add more
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        //disabled={values.rentals.length === 0}
                        color="primary"
                      >
                        Save and Next
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>

      {/* </form> */}
    </div>
  );
}
