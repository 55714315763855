import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../provider/auth";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Link, Button } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useToastDisplay } from "../Common/useToastDisplay";

const API_URL = process.env.REACT_APP_LOGIN_CONTROLLER; //.concat(process.env.REACT_APP_LOGIN_CONTROLLER); //"https://localhost:44337/api/Login/";

const useStyles = makeStyles((theme) => ({
  gridClassName: {
    boxShadow: "0px 2px 11px 6px #d7c6c6",
    padding: 20,
    borderRadius: "5px",
    backgroundColor: "#fff",
  },
  // other classes here
}));
export default function SignUpForm({ setIsLogin }) {
  let navigate = useNavigate();
  const showToast = useToastDisplay();
  var classes = useStyles();
  let auth = useAuth();
  // const [error, setError] = useState(null);
  // const errorDiv = error ? (
  //   <div style={{ color: 'red' }}>
  //     {/*<i class="material-icons error-icon">error_outline</i>*/}
  //     {error}
  //   </div>
  // ) : (
  //   ''
  // );
  return (
    <Grid>
      <div className={classes.gridClassName}>
        <Formik
          initialValues={{
            emailId: "",
            password: "",
            firstName: "",
            lastName: "",
            confirmPassword: "",
            mobileNo: "",
          }}
          onSubmit={async (values, actions) => {
            //auth.signUp(values.emailId, values.password, values.firstName, values.lastName, values.confirmPassword,
            //    () => {
            //        navigate(from, { replace: true });
            //    });
            //setError(null);
            axios
              .post(API_URL + "CreateAccount", {
                ...values,
                mobileNo: values.mobileNo.toString(),
              })
              .then((response) => {
                if (response.data.token) {
                  //authProvider.isAuthenticated = true;
                  //authProvider.token = response.data.token;
                  //authProvider.id = response.data.userId;
                  auth.setToken(
                    response.data.token,
                    {
                      loginId: response.data.loginId,
                      role: response.data.role,
                      userProfileId: response.data.profileId,
                      lastName: response.data.lastName,
                      firstName: response.data.firstName,
                    },
                    () => {
                      navigate(`/fixed-profile/${response.data.profileId}`, {
                        replace: true,
                      });
                    }
                  );
                } else {
                  showToast({
                    message: response.data.msg || "Error occured while login",
                    severity: "error",
                  });
                }
              });
          }}
          validationSchema={Yup.object({
            emailId: Yup.string()
              .email("Please enter a valid email ID")
              .required("Email ID is a required field"),
            password: Yup.string()
              .required("Password is a required field")
              .min(6),
            confirmPassword: Yup.string()
              .required("Confirm Password is a required field")
              .oneOf([Yup.ref("password")], "Passwords must match"),
            lastName: Yup.string().required("Last Name is a required field"),
            mobileNo: Yup.string()
              .min(10, "Enter correct 10 digit mobile number")
              .max(10, "Enter correct 10 digit mobile number"),
          })}
        >
          {(formikProps) => (
            <Grid container size="sm">
              <div className="form-group">
                <div
                  style={{
                    fontSize: 24,
                    fontWeight: 600,
                    color: "#262626",
                    paddingBottom: 20,
                  }}
                >
                  Create New Account
                </div>
              </div>

              <TextField
                id="emailId"
                name="emailId"
                type="email"
                label="Email ID"
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.emailId}
                error={
                  formikProps.touched.emailId && !!formikProps.errors.emailId
                }
                helperText={
                  formikProps.touched.emailId && formikProps.errors.emailId
                }
                disabled={formikProps.isSubmitting}
              />

              <TextField
                id="password"
                name="password"
                label="Password"
                variant="outlined"
                size="small"
                type="password"
                style={{ width: "100%", marginTop: 30 }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.password}
                error={
                  formikProps.touched.password && !!formikProps.errors.password
                }
                helperText={
                  formikProps.touched.password && formikProps.errors.password
                }
                disabled={formikProps.isSubmitting}
              />
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                variant="outlined"
                size="small"
                type="password"
                style={{ width: "100%", marginTop: 30 }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.confirmPassword}
                error={
                  formikProps.touched.confirmPassword &&
                  !!formikProps.errors.confirmPassword
                }
                helperText={
                  formikProps.touched.confirmPassword &&
                  formikProps.errors.confirmPassword
                }
                disabled={formikProps.isSubmitting}
              />

              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                size="small"
                type="text"
                style={{ width: "100%", marginTop: 30 }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.firstName}
                error={
                  formikProps.touched.firstName &&
                  !!formikProps.errors.firstName
                }
                helperText={
                  formikProps.touched.firstName && formikProps.errors.firstName
                }
                disabled={formikProps.isSubmitting}
              />
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                size="small"
                type="text"
                style={{ width: "100%", marginTop: 30 }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.lastName}
                error={
                  formikProps.touched.lastName && !!formikProps.errors.lastName
                }
                helperText={
                  formikProps.touched.lastName && formikProps.errors.lastName
                }
                disabled={formikProps.isSubmitting}
              />
              <TextField
                id="mobileNo"
                name="mobileNo"
                label="Mobile Number"
                variant="outlined"
                size="small"
                type="number"
                style={{ width: "100%", marginTop: 30 }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.mobileNo}
                error={
                  formikProps.touched.mobileNo && !!formikProps.errors.mobileNo
                }
                helperText={
                  formikProps.touched.mobileNo && formikProps.errors.mobileNo
                }
                disabled={formikProps.isSubmitting}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginTop: 19,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    marginTop: 19,
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="form-group">
                    <Button
                      variant="contained"
                      onClick={formikProps.handleSubmit}
                      style={{ width: "100%" }}
                      disabled={formikProps.isSubmitting}
                      color="primary"
                    >
                      Signup
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    marginTop: 19,
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ color: "#FF000", textDecoration: "none" }}>
                    Already have an account?&nbsp;
                  </div>

                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setIsLogin(true);
                    }}
                    style={{ color: "#0090FF", textDecoration: "none" }}
                  >
                    Signin
                  </Link>
                </div>
              </div>
            </Grid>
          )}
        </Formik>
      </div>
    </Grid>
  );
}
