import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../../components/Header/Header";
import API from "../../services/api";
import SizedBox from "../../components/SizedBox";

import { useAuth } from "../../provider/auth";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal, TextField } from "@material-ui/core";

import { Button, Grid } from "@material-ui/core";
import { useToastDisplay } from "../../Common/useToastDisplay";
import AdvanceTax from "../AdvanceTax";
import Income from "../Income";
import Home from "../Home";
import RequireAuth from "../../components/RequireAuth";
import MyFixedProfile from "../../components/Profile/MyFixedProfile";
import Profile from "../Profile";
import IncomeProfile from "../IncomeProfile";
import ServiceType from "../../Common/ServiceType";

const drawerWidth = 105;

const Login_URL = process.env.REACT_APP_LOGIN_CONTROLLER;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,

    //padding: theme.spacing(3),
  },
  button: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    height: 50,
    width: "100%",
    textAlign: "center",
    padding: 15,
    backgroundColor: "#edc274",
    marginRight: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#FFE600",
    },
    padding: theme.spacing(0.5),
  },
  nameLink: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    marginRight: 20,
    "&:hover": {
      cursor: "pointer",
      fontWeight: "bold",
      textDecoration: "underline",
    },
  },
}));

export default function DashBoard() {
  var classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let auth = useAuth();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => setOpen(false);
  const showToast = useToastDisplay();
  return (
    <div className={classes.root}>
      <CssBaseline />

      <Header {...{ handleDrawerToggle, setOpen, open }} />

      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />

          <Route
            path="/advance-tax/*"
            element={
              <RequireAuth>
                <AdvanceTax />
              </RequireAuth>
            }
          />

          <Route
            path="/income/*"
            element={
              <RequireAuth>
                <Income />
              </RequireAuth>
            }
          />
          <Route
            path="/profile/:id/*"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/incomeprofile/:id/*"
            element={
              <RequireAuth>
                <IncomeProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/fixed-profile/:id/"
            element={
              <RequireAuth>
                <Grid container style={{ padding: "10px" }}>
                  <Grid item md={1}></Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <MyFixedProfile
                      {...{
                        showToast,
                        role: auth.user.role,
                        service: ServiceType.INCOMETAX,
                      }}
                    />
                  </Grid>
                  <Grid item md={1}></Grid>
                </Grid>
              </RequireAuth>
            }
          />
        </Routes>
      </main>

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <Formik
          initialValues={{
            oldPassword: "",
            password: "",
            confirmPassword: "",
          }}
          onSubmit={async (values, actions) => {
            API.post(Login_URL + "ChangePassword", {
              ...values,
            })
              .then((response) => {
                showToast({
                  message: "Password changed successfully!",
                  severity: "success",
                });
                handleClose();
              })
              .catch((e) => {
                showToast({
                  message: "Current password did not match.",
                  severity: "error",
                });
              });
          }}
          validationSchema={Yup.object({
            oldPassword: Yup.string().required(
              "Current Password is a required Field"
            ),
            password: Yup.string()
              .required("Password is a required Field")
              .min(6, "Minimum 6 Characters required"),
            confirmPassword: Yup.string()
              .required("Confirm Password is a required Field")
              .oneOf([Yup.ref("password"), null], "Passwords must match"),
          })}
        >
          {(formikProps) => (
            <div
              style={{
                height: 520,
                width: 450,
                backgroundColor: "#fff",
                elevation: 0.5,
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <h2>Change Password</h2>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <TextField
                  id="oldPassword"
                  name="oldPassword"
                  label="Current Password"
                  variant="outlined"
                  size="small"
                  type="password"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.oldPassword}
                  error={
                    formikProps.touched.oldPassword &&
                    !!formikProps.errors.oldPassword
                  }
                  helperText={
                    formikProps.touched.oldPassword &&
                    formikProps.errors.oldPassword
                  }
                  disabled={formikProps.isSubmitting}
                />
                <SizedBox height={20} width={20}></SizedBox>

                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  variant="outlined"
                  size="small"
                  type="password"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.password}
                  error={
                    formikProps.touched.password &&
                    !!formikProps.errors.password
                  }
                  helperText={
                    formikProps.touched.password && formikProps.errors.password
                  }
                  disabled={formikProps.isSubmitting}
                />
                <SizedBox height={20} width={20}></SizedBox>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  variant="outlined"
                  size="small"
                  type="password"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.confirmPassword}
                  error={
                    formikProps.touched.confirmPassword &&
                    !!formikProps.errors.confirmPassword
                  }
                  helperText={
                    formikProps.touched.confirmPassword &&
                    formikProps.errors.confirmPassword
                  }
                  disabled={formikProps.isSubmitting}
                />
              </div>

              <div
                style={{
                  marginTop: 20,
                  display: "flex",

                  justifyContent: "center",
                }}
              >
                <Button
                  style={{ backgroundColor: "#EE9A4D" }}
                  variant="contained"
                  onClick={formikProps.handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
