import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import API from "../../../services/api";
import DisplayFiles from "../../Common/displayFiles";
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import SizedBox from "../../SizedBox";
import { Formik } from "formik";
import HelpText from "../../../Common/helpText";

const CG_API_URL = process.env.REACT_APP_CAPITALGAINS_CONTROLLER;

export default function Equity({ setSelectedSubTab, client, showToast,isDisable }) {
  const { id } = useParams();
  const tabName = "Equity";
  //const [fileList, setFileList] = useState([]);
  const { data, isLoading } = useQuery(
    ["CAPITALGAINS_Data", tabName, id],
    async () => {
      return API.get(CG_API_URL + `GetCGDetails/${id}?tabName=${tabName}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  // useEffect(() => {
  //   if (data) {
  //     setFileList(data.fileList);
  //   }
  // }, [data]);

  const EquityData = data
    ? {
        ...data,
      }
    : {
        fileList: [],
        cgId: "00000000-0000-0000-0000-000000000000",
        comment: "",
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <Formik
      initialValues={EquityData}
      onSubmit={async (values, actions) => {
        API.post(CG_API_URL + "AddCGDetails", {
          ...values,
          taxProfileId: id,
          //fileList,
          tabName,
        }).then((response) => {
          showToast({
            message: "Indian equity investments submitted successfully!",
            severity: "success",
          });
          setSelectedSubTab(1);
        });
      }}
    >
      {(formikProps) => (
        <>
          <Alert style={{ flex: 3 }} severity="info">
            <AlertTitle>
              Steps to download capital gain brokerage statement from popular
              brokers
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 10 }}>
                  1.{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.PUBLIC_URL}/documents/Process_Flow_Document_to_generate_Capital_Gains_Dividend_Statements_from_ZERODHA.pdf`}
                  >
                    Zerodha
                  </a>
                </div>
                <div style={{ marginRight: 10 }}>
                  2.{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.PUBLIC_URL}/documents/Process_Flow_Document_to_generate_Capital_Gains_Dividend_Statements_from_ICICI_DIRECT_Platform.pdf`}
                  >
                    ICICI Direct
                  </a>
                </div>
                <div style={{ marginRight: 10 }}>
                  3.{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.PUBLIC_URL}/documents/Process_Flow_Document_to_generate_Mutual_Funds_Statements_from_HDFC_Securities.pdf`}
                  >
                    HDFC
                  </a>
                </div>

                <div>
                  4.{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.PUBLIC_URL}/documents/Process_Flow_Document_to_generate_Capital_Gains_Dividend_Statements_from_AXIS_DIRECT_Platform.pdf`}
                  >
                    Axis Direct
                  </a>
                </div>
              </div>
            </AlertTitle>
          </Alert>
          <SizedBox height={20}></SizedBox>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DisplayFiles
                {...{
                  formikProps,
                  type: tabName,
                  isCommentOn: true,
                  buttonText:
                    ((formikProps.values?.fileList || [])?.length || 0) === 0
                      ? "Upload capital gains statment"
                      : "Upload more statments",
                }}
              ></DisplayFiles>
              <HelpText>Note : Upload statements in CSV/XLXS format</HelpText>

              <SizedBox height={30}></SizedBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="Enter comments/ notes"
                    type="text"
                    name="comment"
                    minRows={11}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.comment}
                    error={
                      formikProps.touched.comment &&
                      !!formikProps.errors.comment
                    }
                    helperText={
                      formikProps.touched.comment && formikProps.errors.comment
                    }
                    disabled={isDisable || formikProps.isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6} md={6}></Grid> */}
                <Grid item xs={12} sm={6} md={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disabled={isDisable || formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
