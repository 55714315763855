import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useAuth } from '../../provider/auth';
import { AccountCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  var { signout } = useAuth();
  const navigate = useNavigate();
  let from = '/login';

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.setOpen(false);
  };
  const handleChangePassword = () => {
    setAnchorEl(null);
    props.setOpen(true);
  };

  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: '#fff', color: 'black' }}
    >
      <Toolbar>
        {/* <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        ></IconButton> */}
        <Typography
          variant="h6"
          onClick={() => navigate('/')}
          className={classes.title}
        >
          <img
            src="logo advance-tax.png"
            alt="logo"
            style={{
              width: '5%',
              marginTop:5,
            }}
          />{' '}
        </Typography>
        {auth && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleChangePassword}>
                Change Password
              </MenuItem>
              <MenuItem
                onClick={() =>
                  signout(() => {
                    localStorage.clear();
                    navigate(from, { replace: true });
                  })
                }
              >
                Sign out
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
