import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../services/api';
import { useToastDisplay } from '../../Common/useToastDisplay';

const LOGIN_API_URL = process.env.REACT_APP_LOGIN_CONTROLLER;

export default function Email({
  setIsOtpReceived,
  isOtpReceived,
  isPasswordVisible,
  text,
  setText,
}) {
  const showToast = useToastDisplay();
  return (
    <Grid>
      <div>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={async (values, actions) => {
            const data = await API.get(
              LOGIN_API_URL + `GenerateOTP/${values.email}`
            )
              .then((res) => res.data)
              .catch((e) => console.log('API FAILED', e));
            if (data.isSuccess === true) {
              setIsOtpReceived(true);
              setText(values.email);
            } else {
              showToast({
                message: data?.msg || 'Error occured while sending mail',
                severity: 'error',
              });
            }
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Please enter a valid email ID')
              .required('Email ID is a required Field'),
          })}
        >
          {(formikProps) => (
            <Grid
              container
              size="sm"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div className="form-group">
                <div
                  style={{
                    fontSize: 24,
                    fontWeight: 600,
                    color: '#262626',
                  }}
                >
                  Forgot your password?
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginTop: 16,
                    marginBottom: 40,
                    fontWeight: 600,
                    color: '#363636',
                    opacity: 1,
                  }}
                >
                  Enter your Email to verify your Email Id
                </div>
              </div>

              <TextField
                id="email"
                name="email"
                type="email"
                label="Email ID"
                variant="outlined"
                size="small"
                style={{ width: '100%' }}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.email}
                error={formikProps.touched.email && !!formikProps.errors.email}
                helperText={
                  formikProps.touched.email && formikProps.errors.email
                }
                disabled={
                  isPasswordVisible || isOtpReceived
                    ? true
                    : formikProps.isSubmitting
                }
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  justifyContent: 'space-between',
                }}
              >
                {!isOtpReceived ? (
                  <div style={{ marginTop: 30 }}>
                    <Button
                      variant="contained"
                      onClick={formikProps.handleSubmit}
                      style={{ width: '100%' }}
                      disabled={formikProps.isSubmitting}
                      color="primary"
                    >
                      Send OTP
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          )}
        </Formik>
      </div>
    </Grid>
  );
}
