import React from 'react';
import {
  Button,
  TextField,
  Grid,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import SizedBox from '../SizedBox';
import CurrencyTextField from '../CurrencyTextField';
import API from '../../services/api';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { getInteger } from '../../Common/helperMethods';
import { Alert, AlertTitle } from '@material-ui/lab';

const QTR_API_URL = process.env.REACT_APP_DIVIDENDDETAILS_CONTROLLER;

const Dividends = ({ activeQuarter, setSelectedTab, client, showToast }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['Dividend_Data', id],
    async () => {
      return API.get(QTR_API_URL + `GetDividendDetails/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  const isDisabled = client?.isDisabled;

  const initialValues = data
    ? data
    : {
        indianDividend: 0,
        indian_TDS: 0,
        foreignDividend: 0,
        foreign_FTC: 0,
        otherComment: '',
        taxQtrProfileId: id,
        dividendId: '00000000-0000-0000-0000-000000000000',
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          indian_TDS: Yup.string().test({
            name: 'max',
            exclusive: true,
            params: {},
            message: 'TDS should not exceed 30% of dividend income',
            test: function (value) {
              // You can access the price field with `this.parent`.
              return (
                getInteger(value) <=
                getInteger(this.parent.indianDividend) * 0.3
              );
            },
          }),
          foreign_FTC: Yup.string().test({
            name: 'max',
            exclusive: true,
            params: {},
            message: 'FTC should not exceed 43% of foreign dividend',
            test: function (value) {
              // You can access the price field with `this.parent`.
              return (
                getInteger(value) <=
                getInteger(this.parent.foreignDividend) * 0.43
              );
            },
          }),
        })}
        onSubmit={async (values, actions) => {
          API.post(QTR_API_URL + 'AddDividendDetails', {
            ...values,
            indianDividend: getInteger(values.indianDividend),
            indian_TDS: getInteger(values.indian_TDS),
            foreignDividend: getInteger(values.foreignDividend),
            foreign_FTC: getInteger(values.foreign_FTC),
          }).then((response) => {
            showToast({
              message: 'Dividend submitted successfully!',
              severity: 'success',
            });
            setSelectedTab(3);
            navigate(`/advance-tax/quarter/${id}/rental`);
          });
        }}
      >
        {(formik) => (
          <>
            <SizedBox height={10} width={10}></SizedBox>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Alert severity="info">
                  <AlertTitle>
                    Please enter the estimated annual income amounts.
                  </AlertTitle>
                </Alert>
              </Grid>
            </Grid>
            <SizedBox height={40} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CurrencyTextField
                  id="indianDividend"
                  label="Indian Dividends"
                  fullWidth
                  value={formik.values.indianDividend}
                  onChange={(e) => {
                    formik.setFieldValue('indianDividend', e.target.value);
                    formik.setFieldValue(
                      'indian_TDS',
                      (getInteger(e.target.value) * 5) / 100
                    );
                  }}
                  error={
                    formik.touched.indianDividend &&
                    !!formik.errors.indianDividend
                  }
                  helperText={
                    formik.touched.indianDividend &&
                    formik.errors.indianDividend
                  }
                  disabled={isDisabled || formik.isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CurrencyTextField
                  id="indian_TDS"
                  label="TDS"
                  fullWidth
                  value={formik.values.indian_TDS}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.indian_TDS && !!formik.errors.indian_TDS
                  }
                  helperText={
                    formik.touched.indian_TDS && formik.errors.indian_TDS
                  }
                  disabled={isDisabled || formik.isSubmitting}
                />
              </Grid>
            </Grid>
            {client &&
            (client.residentialStatus == '1' ||
              client.residentialStatus == 'OR') ? (
              <>
                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CurrencyTextField
                      id="foreignDividend"
                      label="Foreign Dividends"
                      fullWidth
                      value={formik.values.foreignDividend}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.foreignDividend &&
                        !!formik.errors.foreignDividend
                      }
                      helperText={
                        formik.touched.foreignDividend &&
                        formik.errors.foreignDividend
                      }
                      disabled={isDisabled || formik.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CurrencyTextField
                      id="foreign_FTC"
                      label="FTC"
                      fullWidth
                      value={formik.values.foreign_FTC}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.foreign_FTC &&
                        !!formik.errors.foreign_FTC
                      }
                      helperText={
                        formik.touched.foreign_FTC && formik.errors.foreign_FTC
                      }
                      disabled={isDisabled || formik.isSubmitting}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <SizedBox height={30} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="otherComment"
                  variant="outlined"
                  multiline={true}
                  label="Enter your comments/notes here"
                  name="otherComment"
                  rows={4}
                  value={formik.values.otherComment}
                  onChange={isDisabled || formik.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <SizedBox height={40} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}></Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isDisabled || formik.isSubmitting}
                    onClick={isDisabled || formik.handleSubmit}
                  >
                    Save and Next
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
};
export default Dividends;
