import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../services/api';
import DisplayFiles from '../../Common/displayFiles';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import SizedBox from '../../SizedBox';
import { Formik } from 'formik';

const CG_API_URL = process.env.REACT_APP_CAPITALGAINS_CONTROLLER;

export default function Mutual({ setSelectedSubTab, client, showToast,isDisable }) {
  const { id } = useParams();
  const tabName = 'Mutual';
  const { data, isLoading } = useQuery(
    ['CAPITALGAINS_Data', tabName],
    async () => {
      return API.get(CG_API_URL + `GetCGDetails/${id}?tabName=${tabName}`)
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  const EquityData = data
    ? {
        ...data,
      }
    : {
        //fileCAMSList: [],
        //fileFintechList: [],
        fileList: [],
        cgId: '00000000-0000-0000-0000-000000000000',
        comment: '',
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <Formik
      initialValues={EquityData}
      onSubmit={async (values, actions) => {
        API.post(CG_API_URL + 'AddCGDetails', {
          ...values,
          taxProfileId: id,
          tabName,
        }).then((response) => {
          showToast({
            message: 'Indian mutual funds data submitted successfully!',
            severity: 'success',
          });
          setSelectedSubTab(2);
        });
      }}
    >
      {(formikProps) => (
        <>
          <SizedBox height={10} width={10}></SizedBox>

          <Grid container spacing={1}>
            <Grid item xs={12} key={`${tabName}_CAMS`}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <DisplayFiles
                  {...{
                    formikProps,
                    type: `${tabName}_CAMS`,
                    maxSize: 1,
                    buttonText:
                      ((formikProps.values?.fileList || []).filter(
                        (s) => s.fileType === `${tabName}_CAMS`
                      )?.length || 0) === 0
                        ? 'Upload CAMS capital gains statement'
                        : 'Upload more documents',
                  }}
                ></DisplayFiles>
                <SizedBox width={10}></SizedBox>
                <Alert severity="info">
                  <AlertTitle>
                    Download the steps :{' '}
                    <a
                      target="_blank"
                      href={`${process.env.PUBLIC_URL}/documents/Process_Flow_Document_to_generate_Mutual_Funds_Statements_from_CAMS.pdf`}
                    >
                      CAMS statement
                    </a>
                  </AlertTitle>
                </Alert>
              </div>

              <SizedBox height={30}></SizedBox>
            </Grid>
            <Grid item xs={12} key={`${tabName}_Fintech`}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <DisplayFiles
                  {...{
                    formikProps,
                    type: `${tabName}_Fintech`,
                    maxSize: 1,
                    buttonText:
                      ((formikProps.values?.fileList || []).filter(
                        (s) => s.fileType === `${tabName}_Fintech`
                      )?.length || 0) === 0
                        ? 'Upload K Fintech/Karvy capital gains statement'
                        : '',
                  }}
                ></DisplayFiles>
                <SizedBox width={10}></SizedBox>
                <Alert severity="info">
                  <AlertTitle>
                    Go to the link below to fetch the statement{' '}
                    <a
                      href="https://mfs.kfintech.com/investor/General/CapitalGainsLossAccountStatement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      K Fintech
                    </a>
                  </AlertTitle>
                </Alert>
              </div>

              <SizedBox height={30}></SizedBox>
            </Grid>
            <Grid item xs={12} key={`${tabName}_Franklin`}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <DisplayFiles
                  {...{
                    formikProps,
                    type: `${tabName}_Franklin`,
                    maxSize: 1,
                    buttonText:
                      ((formikProps.values?.fileList || []).filter(
                        (s) => s.fileType === `${tabName}_Franklin`
                      )?.length || 0) === 0
                        ? 'Upload Franklin capital gains statement'
                        : '',
                  }}
                ></DisplayFiles>
                <SizedBox width={20}></SizedBox>
                <Alert severity="info">
                  <AlertTitle>
                    Go to the link below to fetch the statement{' '}
                    <a
                      href="https://accounts.franklintempletonindia.com/ngGuest/#/customerservices/instantservices/instantmailback"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Franklin
                    </a>
                  </AlertTitle>
                </Alert>
              </div>

              <SizedBox height={30}></SizedBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="Enter comments/ notes"
                    type="text"
                    name="comment"
                    minRows={11}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.comment}
                    error={
                      formikProps.touched.comment &&
                      !!formikProps.errors.comment
                    }
                    helperText={
                      formikProps.touched.comment && formikProps.errors.comment
                    }
                    disabled={isDisable || formikProps.isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6} md={6}></Grid> */}
                <Grid item xs={12} sm={6} md={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disabled={isDisable || formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
