import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Button, Chip, Typography } from "@material-ui/core";
import { Person as AccountIcon } from "@material-ui/icons";
import API from "../../services/api";
import { useUpdateSelectedClient } from "../../Common/useSelectedClient";
import ProfileStatus from "../../Common/ProfileStatus";
import SizedBox from "../SizedBox";

const TAX_API_URL = process.env.REACT_APP_TAXPROFILE_CONTROLLER;

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    height: 50,
    width: "100%",
    textAlign: "center",
    padding: 15,
    backgroundColor: "#edc274",
    marginRight: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#FFE600",
    },
  },
  disabledButton: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    height: 50,
    width: "100%",
    textAlign: "center",
    padding: 15,
    backgroundColor: "lightgray !important",
    marginRight: 20,
  },
  nameLink: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    marginRight: 20,
    "&:hover": {
      cursor: "pointer",
      fontWeight: "bold",
      textDecoration: "underline",
    },
  },
  // other classes here
}));

export default function DisplayFilingClient({ index, t, fy }) {
  let navigate = useNavigate();
  var classes = useStyles();
  const [isClicked, setIsClicked] = useState(false);
  const setClient = useUpdateSelectedClient();

  function redirectToFiling(profileId) {
    setIsClicked(true);
    API.get(TAX_API_URL + `GetIncomeTaxProfile/${profileId}?fy=${fy}`)
      .then((res) => {
        setClient({
          ...res.data,
        });
        setIsClicked(false);
        if (res.data.isResidentialAdded === false)
          navigate(`/incomeprofile/${res.data.userProfileGuid}?type=income`);
        else {
          if (
            res.data &&
            ProfileStatus[res.data.status].id >=
              ProfileStatus.TaxSummaryPrepared.id
          ) {
            navigate(`/income/filing/${res.data.taxProfileId}/summary`);
          } else {
            navigate(`/income/filing/${res.data.taxProfileId}`);
          }
        }
      })
      .catch((err) => {
        setIsClicked(false);
        console.log("Error occured while fetching profile");
      });
  }

  return (
    <Grid
      item
      spacing={2}
      xs={12}
      md={12}
      style={{
        color: "#fff",
        backgroundColor: "#8e99d5",
        padding: 15,
        borderRadius: 5,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Grid spacing={2}>
        <div style={{ float: "right" }}>
          <Chip
            size="small"
            label={t.accountType == "1" ? "Self" : "Family member"}
          />
          <IconButton
            size="small"
            aria-haspopup="true"
            color="inherit"
            title="View Profile"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => {
              //navigate(`/fixed-profile/${t.userProfileId}?callback=income`);
              navigate(`/incomeprofile/${t.userProfileId}?type=income`);
            }}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
          </IconButton>
        </div>
        <div>
          <div>
            <span
              className={classes.nameLink}
              onClick={(e) => {
                navigate(`/incomeprofile/${t.userProfileId}?type=income`);
              }}
            >
              {t.firstName} {t.lastName}
            </span>
          </div>
        </div>
      </Grid>

      <Grid container spacing={1} style={{ alignItems: "center" }}>
        <Grid item xs={4} md={4}>
          <Typography>
            Return Status:{" "}
            {ProfileStatus[t.status].id === ProfileStatus.TaxSummaryRejected.id
              ? "Query Raised"
              : ProfileStatus[t.status].name}
          </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <Button
            disabled={isClicked}
            className={classes.button}
            onClick={() => redirectToFiling(t.userProfileId)}
          >
            Tax Filing
          </Button>
        </Grid>
        {ProfileStatus[t.status].id >= ProfileStatus.InProcess.id && (
          <Grid item xs={3} md={3}>
            <Button
              disabled={isClicked}
              className={classes.button}
              onClick={() =>
                navigate(`/income/documents/${t.userProfileId}?type=income`)
              }
            >
              View/ Upload Documents
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
