import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../provider/auth';
import RequireAuth from '../components/RequireAuth';
import Dashboard from '../screens/Dashboard';
import Login from '../screens/Login';
import ForgotPassword from '../screens/ForgotPassword';
import AdvisorList from "../components/AdvisorList";
// import QuarterMaster from "./QuarterMaster";
import AdvisorDetails from "../components/AdvisorDetails";
import FileUpload from './Documents/DisplayTabWiseDocuments';
// import Home from "../screens/Home";

export default function AppRoutes() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/*"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        {/* <Route
          path="dashboard/*"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        /> */}

        <Route path="login" element={<Login />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        {/* <Route path="AdvisorList" element={<AdvisorList />} /> */}
        {/* <Route path="QuarterMaster" element={<QuarterMaster/>} /> */}
        <Route path="AdvisorList/*" element={<AdvisorList />} />
        <Route path="/advisordetails/:id/*" element={<AdvisorDetails />} />
        <Route path="File-upload/*" element={<FileUpload />} />
      </Routes>
    </AuthProvider>
  );
}
