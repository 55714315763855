import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../services/api';
import DisplayFiles from '../Common/displayFiles';
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import SizedBox from '../SizedBox';
import { Formik } from 'formik';
import HelpText from '../../Common/helpText';

const REACT_APP_FILINGINTEREST_CONTROLLER =
  process.env.REACT_APP_FILINGINTEREST_CONTROLLER;

export default function Interest({ setSelectedTab, client, showToast,isDisable }) {
  const { id } = useParams();
  const navigate = useNavigate();
  // const [fileList, setFileList] = useState([]);

  const { data, isLoading } = useQuery(
    ['FILINGINTEREST_Data',id],
    async () => {
      return API.get(
        REACT_APP_FILINGINTEREST_CONTROLLER + `GetFilingInterestDetails/${id}`
      )
        .then((res) => res.data)
        .catch((e) => console.log('API FAILED', e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );
  // useEffect(() => {
  //   if (data) {
  //     setFileList(data.fileList);
  //   }
  // }, [data]);

  const EsopData = data
    ? {
        ...data,
        //eTradeList: data.fileList.filter((s) => s.fileType === "Fidelity"),
        //yearEndList: data.fileList.filter((s) => s.fileType === "YearEnd"),
      }
    : {
        otherIncome: '',
        fileList: [],
        interestId: '00000000-0000-0000-0000-000000000000',
        comment: '',
        taxProfileId: id,
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        size={60}
      />
    );
  }

  return (
    <Formik
      initialValues={EsopData}
      onSubmit={async (values, actions) => {
        API.post(
          REACT_APP_FILINGINTEREST_CONTROLLER + 'AddFilingInterestDetails',
          {
            ...values,
            taxProfileId: id,
            // fileList,
          }
        ).then((response) => {
          showToast({
            message: 'Interest/Dividends/Others submitted successfully!',
            severity: 'success',
          });
          setSelectedTab(5);
          navigate(`/income/filing/${id}/esops`);
        });
      }}
    >
      {(formikProps) => (
        <>
          <SizedBox height={10} width={10}></SizedBox>
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>
                  We will pull the interest income and Indian dividend income
                  from 26AS and AIS.
                </AlertTitle>
              </Alert>
            </Grid>
          </Grid>
          <SizedBox height={40} width={10}></SizedBox>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                multiline={true}
                label="Commission/ freelancing/ director fee/ business income"
                type="text"
                name="otherIncome"
                minRows={8}
                placeholder="Please let us know if you have any additional income such as commission/freelancing/director fee/business income"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.otherIncome}
                error={
                  formikProps.touched.otherIncome &&
                  !!formikProps.errors.otherIncome
                }
                helperText={
                  formikProps.touched.otherIncome &&
                  formikProps.errors.otherIncome
                }
                disabled={isDisable || formikProps.isSubmitting}
                fullWidth
              />
              <HelpText>
                Note : In case of foreign income, refer next tab
              </HelpText>
              <SizedBox height={30}></SizedBox>
            </Grid>
            <Grid item xs={8}>
              <DisplayFiles
                {...{
                  // fileList,
                  // setFileList,
                  formikProps,
                  type: 'InterestDividend',
                  buttonText:
                    ((formikProps.values?.fileList || [])?.length || 0) === 0
                      ? 'Upload documents'
                      : 'Upload more documents',
                }}
              ></DisplayFiles>
              <SizedBox height={30}></SizedBox>
            </Grid>

            {/* <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="Enter details of any other income (for example, if you have substantial interest income on which there is no TDS)"
                    type="text"
                    name="comment"
                    minRows={11}
                    placeholder="Please let us know if you have any additional income such as commission/freelancing/director fee/business income"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.comment}
                    error={
                      formikProps.touched.comment &&
                      !!formikProps.errors.comment
                    }
                    helperText={
                      formikProps.touched.comment && formikProps.errors.comment
                    }
                    disabled={formikProps.isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
            </Grid> */}

            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}></Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disabled={isDisable || formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
