import React, { useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CG from "../../components/Tax/CG";
import Interest from "../../components/Tax/Interest";
import Property from "../../components/Tax/Property";
import { IconButton, Grid } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useToastDisplay } from "../../Common/useToastDisplay";
import { useSelectedClient } from "../../Common/useSelectedClient";
import { useAuth } from "../../provider/auth";
import Salary from "../../components/Tax/Salary";
import Deductions from "../../components/Tax/Deductions";
import Esops from "../../components/Tax/Esops";
import Summary from "../../components/Tax/Summary";
import Messages from "../../components/Tax/Messages";
import API from "../../services/api";
import Disclosures from "../../components/Tax/Disclosures";
import ProfileStatus from "../../Common/ProfileStatus";
import SizedBox from "../../components/SizedBox";
import { useQuery } from "react-query";
const taxProfileControllerUrl = process.env.REACT_APP_TAXPROFILE_CONTROLLER;

const useStyles = makeStyles((theme) => ({
  menu: {
    color: "#fff",
    borderRadius: 5,
    textDecoration: "none",
    margin: 10,
    textAlign: "center",
    backgroundColor: "#8e99d5",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#c5c8d9",
    },
    padding: theme.spacing(1),
  },
}));

export default function TaxFiling({ fy }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const client = useSelectedClient();
  const showToast = useToastDisplay();
  const auth = useAuth();
  const classes = useStyles();

  const queryKey = ["pageStatus", id, fy];

  const {
    data: pageStatusData,
    isLoading: isPageStatusLoading,
    isError: isPageStatusError,
  } = useQuery(
    queryKey,
    async () => {
      return API.get(taxProfileControllerUrl + `GetPageStatus/${id}?fy=${fy}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  console.log("data========================", pageStatusData);
  console.log("isLoading========================", isPageStatusLoading);
  console.log("fy=========", fy);
  console.log("id=========", id);

  let data, isLoading, isError;
  if (isPageStatusLoading) {
    data = null;
    isLoading = true;
    isError = false;
  } else if (isPageStatusError) {
    data = null;
    isLoading = false;
    isError = true;
  } else {
    data = pageStatusData;
    isLoading = false;
    isError = false;
  }

  const { status, isDisable } = pageStatusData || {};
  const tabList = [
    { id: 1, name: "Salary", url: `/income/filing/${id}` },
    {
      id: 2,
      name: "Properties",
      url: `/income/filing/${id}/properties`,
    },
    { id: 3, name: "Capital Gains", url: `/income/filing/${id}/cg/` },
    {
      id: 4,
      name: "Interest/Dividends/Others",
      url: `/income/filing/${id}/interest`,
    },
    { id: 5, name: "Foreign Investments", url: `/income/filing/${id}/esops` },
    { id: 6, name: "Deductions", url: `/income/filing/${id}/deductions` },
    {
      id: 7,
      name: 'Asset Disclosures',
      url: `/income/filing/${id}/disclosures`,
    },
    { id: 8, name: "Tax Summary", url: `/income/filing/${id}/summary` },
    { id: 9, name: "Messages", url: `/income/filing/${id}/messages` },
  ];
  const [selectedTab, setSelectedTab] = useState(
    tabList.find((t) => t.url === location.pathname)?.id ?? 1
  );

  return (
    <Grid container spacing={1}>
      <Grid item md={1}></Grid>
      <Grid item xs={11} sm={11} md={10}>
        <div style={{ marginTop: 16 }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              onClick={(e) => navigate("/income")}
            >
              <ArrowBack classes={{ root: classes.headerIcon }} />
            </IconButton>
            <h2>Financial Year {fy} </h2>
            <SizedBox width={10}></SizedBox>
            {auth.user.role !== "Client" && client && (
              <h2 style={{ textAlign: "center" }}>
                {" "}
                {client.firstName} {client.lastName} ({client.pan})
              </h2>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {tabList &&
              tabList.map((t, index) => {
                return (
                  <div
                    key={t.id}
                    className={classes.menu}
                    style={
                      selectedTab === t.id
                        ? { backgroundColor: "#edc274" }
                        : null
                    }
                    onClick={() => {
                      setSelectedTab(t.id);
                      navigate(t.url);
                    }}
                  >
                    {t.name}
                  </div>
                );
              })}
          </div>
        </div>

        <div style={{ padding: 10 }}>
          <Routes>
            <Route
              path="/"
              element={
                <Salary
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                  }}
                />
              }
            />
            <Route
              path="/esops"
              element={
                <Esops
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                  }}
                />
              }
            />
            <Route
              path="/deductions"
              element={
                <Deductions
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                  }}
                />
              }
            />

            <Route
              path="/properties"
              element={
                <Property
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                  }}
                />
              }
            />
            <Route
              path="/cg/*"
              element={
                <CG
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                  }}
                />
              }
            />
            <Route
              path="/interest"
              element={
                <Interest
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                    role: auth.user.role,
                  }}
                />
              }
            />
            <Route
              path="/disclosures/*"
              element={
                <Disclosures
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                  }}
                />
              }
            />
            <Route
              path="/summary"
              element={
                <Summary
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                  }}
                />
              }
            />
            <Route
              path="/messages"
              element={
                <Messages
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isDisable,
                    pageStatusData,
                  }}
                />
              }
            />
          </Routes>
        </div>
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
}
