import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
} from '@material-ui/core';
import SizedBox from './../SizedBox';
import CurrencyTextField from '../CurrencyTextField';
import { IconButton } from '@material-ui/core';
import { CloseOutlined, EditOutlined } from '@material-ui/icons';
import { getInteger } from '../../Common/helperMethods';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  column: {
    padding: 0,
    marginLeft: 0,
  },
}));

export default function CoOwner({ coOwnerList, setCoOwnerList, ownership }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedCoOwner, setSelectedCoOwner] = React.useState();

  const totalCoOwnerShip =
    getInteger(ownership) +
    getInteger(
      coOwnerList
        .map((item) => getInteger(item.ownerRatio))
        .reduce((prev, next) => prev + next, 0)
    );
  console.log('totalCoOwnerShip', totalCoOwnerShip);
  const initialValues = selectedCoOwner
    ? selectedCoOwner
    : {
        index: -1,
        name: '',
        pan: '',
        ownerRatio: 100 - totalCoOwnerShip, //- coOwnerList.sum((t) => t.ownerRatio),
        coOwnerId: '00000000-0000-0000-0000-000000000000',
      };

  return (
    <div>
      <Table style={{ paddingBlock: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.column}>
              <b>Sr.No</b>
            </TableCell>
            <TableCell className={classes.column}>
              <b>Co-owner Name</b>
            </TableCell>
            <TableCell className={classes.column}>
              <b>Co-owner PAN</b>
            </TableCell>
            <TableCell className={classes.column}>
              <b>Ownership Ratio</b>
            </TableCell>

            <TableCell className={classes.column}>
              <b>Action</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {coOwnerList &&
            coOwnerList.map((co, index) => {
              return (
                <TableRow key={index} style={{ height: 30 }}>
                  <TableCell className={classes.column}>{index + 1}</TableCell>
                  <TableCell className={classes.column}>{co.name}</TableCell>
                  <TableCell className={classes.column}>{co.pan}</TableCell>
                  <TableCell className={classes.column}>
                    {co.ownerRatio}
                  </TableCell>
                  <TableCell className={classes.column}>
                    <IconButton
                      aria-haspopup="true"
                      color="inherit"
                      aria-controls="profile-menu"
                      style={{ padding: 6 }}
                      onClick={() => {
                        const findOwner = coOwnerList.find(
                          (s, i) => i === index
                        );
                        setSelectedCoOwner({ ...findOwner, index });
                        setOpen(true);
                      }}
                    >
                      <EditOutlined />
                    </IconButton>

                    <IconButton
                      aria-haspopup="true"
                      color="inherit"
                      aria-controls="profile-menu"
                      style={{ padding: 6 }}
                      onClick={() =>
                        setCoOwnerList(
                          coOwnerList.filter((s, i) => i !== index)
                        )
                      }
                    >
                      <CloseOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <SizedBox height={15}></SizedBox>
      {totalCoOwnerShip < 100 && (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedCoOwner(null);
              setOpen(true);
            }}
          >
            Add Co-owner
          </Button>
        </div>
      )}

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={classes.paper}>
          <h2>Co-owner Details</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              name: Yup.string().required('Co-owner Name is a required field'),
              pan: Yup.string()
                .required('Co-owner PAN is a required Field')
                .min(10, 'Enter correct 10 digit PAN')
                .max(10, 'Enter correct 10 digit PAN')
                .matches(
                  /^[A-Za-z]{3}[PpHh][A-Za-z][0-9]{4}[a-zA-Z]/,
                  'Enter correct PAN'
                ),
              ownerRatio: Yup.number()
                .required('Co-owner ratio is a required field')
                .when('index', (index) => {
                  if (index === -1) {
                    return Yup.number().max(
                      100 - totalCoOwnerShip,
                      'Total ownership cannot be greater than 100'
                    );
                  }
                }),
            })}
            onSubmit={async (values) => {
              console.log('values', values);
              if (values.index === -1) setCoOwnerList([...coOwnerList, values]);
              else
                setCoOwnerList(
                  coOwnerList.map((t, index) => {
                    if (values.index === index) {
                      return values;
                    }
                    return t;
                  })
                );
              setOpen(false);
            }}
          >
            {(formikProps) => (
              <div style={{ width: '100%' }}>
                <TextField
                  id="name"
                  size="small"
                  variant="outlined"
                  label="Name"
                  type="text"
                  name="name"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.name}
                  error={formikProps.touched.name && !!formikProps.errors.name}
                  helperText={
                    formikProps.touched.name && formikProps.errors.name
                  }
                  disabled={formikProps.isSubmitting}
                  fullWidth
                />
                <SizedBox height={15}></SizedBox>
                <TextField
                  id="pan"
                  variant="outlined"
                  label="PAN"
                  size="small"
                  type="text"
                  name="pan"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.pan}
                  error={formikProps.touched.pan && !!formikProps.errors.pan}
                  helperText={formikProps.touched.pan && formikProps.errors.pan}
                  disabled={formikProps.isSubmitting}
                  fullWidth
                />
                <SizedBox height={15}></SizedBox>
                <CurrencyTextField
                  id="ownerRatio"
                  label="Ratio"
                  fullWidth
                  name="ownerRatio"
                  onChange={formikProps.handleChange}
                  maximumValue="99"
                  minimumValue="0"
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.ownerRatio}
                  error={
                    formikProps.touched.ownerRatio &&
                    !!formikProps.errors.ownerRatio
                  }
                  helperText={
                    formikProps.touched.ownerRatio &&
                    formikProps.errors.ownerRatio
                  }
                  disabled={
                    formikProps.isSubmitting 
                    //|| formikProps.values.index !== -1
                  }
                />
                <SizedBox height={20} width={20}></SizedBox>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    disabled={formikProps.isSubmitting}
                    color="primary"
                    onClick={formikProps.handleSubmit}
                  >
                    {formikProps.values.index === -1 ? 'Add' : 'Update'}
                  </Button>
                  <SizedBox height={20} width={20}></SizedBox>
                  <Button
                    variant="outlined"
                    disabled={formikProps.isSubmitting}
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}
