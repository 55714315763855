import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Button,
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Grid,
  Box,
} from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { CloseOutlined, EditOutlined } from "@material-ui/icons";
import API from "../../services/api";
import { Alert, AlertTitle } from "@material-ui/lab";
import SizedBox from "../SizedBox";
import AddProperty from "./AddProperty";

const Property_API_URL = process.env.REACT_APP_PROPERTY_CONTROLLER;

function Property({ setSelectedTab, client, showToast,isDisable }) {
  const { id } = useParams();
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const [propertyId, setPropertyId] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const { data, isLoading, refetch } = useQuery(
    ["ALL_Properties", id],
    async () => {
      return API.get(Property_API_URL + `GetAllProperties/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: true,
      cacheTime: 300,
      refetchOnWindowFocus: true,
    }
  );

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  if (isView) {
    return (
      <AddProperty
        {...{ setSelectedTab, client, showToast, propertyId, setIsView }}
      ></AddProperty>
    );
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Alert style={{ flex: 3 }} severity="info">
          <AlertTitle>
            Please provide the details of ALL the properties including
            self-occupied and commercial properties.
            <SizedBox height={10}></SizedBox>
            Exclude plots and under-construction properties as on 31st March,
            2023
          </AlertTitle>
        </Alert>
        <SizedBox width={20}></SizedBox>
        <div>
          <Button
            variant="outlined"
            color="primary"
            disabled={isDisable}
            onClick={() => {
              setPropertyId("00000000-0000-0000-0000-000000000000");
              setIsView(true);
            }}
          >
            Add Property
          </Button>
        </div>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Address</b>
            </TableCell>
            <TableCell>
              <b>Country</b>
            </TableCell>
            <TableCell>
              <b>View</b>
            </TableCell>
            <TableCell>
              <b>Delete</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((property, index) => (
              <TableRow key={index}>
                <TableCell>{property?.propertyName ?? ""}</TableCell>
                <TableCell>{property?.address ?? ""}</TableCell>
                <TableCell>{property?.country ?? ""}</TableCell>
                <TableCell>
                  <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    aria-controls="profile-menu"
                    style={{ padding: 6 }}
                    onClick={() => {
                      console.log("property.propertyId", property.propertyId);
                      setPropertyId(property.propertyId);
                      setIsView(true);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    aria-controls="profile-menu"
                    style={{ padding: 6 }}
                    onClick={() =>
                      API.get(
                        Property_API_URL +
                          `DeleteProperty/${property.propertyId}`
                      )
                        .then((res) => {
                          showToast({
                            message: "Property deleted successfully",
                            severity: "success",
                          });
                          refetch();
                        })
                        .catch((e) => console.log("API FAILED", e))
                    }
                  >
                    <CloseOutlined />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <SizedBox height={10}></SizedBox>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedTab(3);
                    navigate(`/income/filing/${id}/cg`);
                  }}
                >
                  Save and Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default Property;
