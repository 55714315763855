import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { Grid, Button } from '@material-ui/core';
import API from '../../services/api';
import { useToastDisplay } from '../../Common/useToastDisplay';

const LOGIN_API_URL = process.env.REACT_APP_LOGIN_CONTROLLER;

export default function Otp({
  isPasswordVisible,
  setIsPassWordVisible,
  text,
  userLoginId,
  setUserLoginId,
}) {
  const [code, setCode] = useState('');

  const handleChange = (code) => setCode(code);
  const showToast = useToastDisplay();
  //const [error, setError] = useState(null);
  // const errorDiv = error ? (
  //   <div style={{ color: 'red' }}>
  //     {/*<i class="material-icons error-icon">error_outline</i>*/}
  //     {error}
  //   </div>
  // ) : (
  //   ''
  // );

  const otpSubmitHandler = async () => {
    const Data = await API.get(LOGIN_API_URL + `VerifyOTP/${text}?OTP=${code}`)
      .then((res) => res.data)
      .catch((e) => console.log('API FAILED', e));

    setUserLoginId(Data?.loginId);

    if (Data?.isSuccess) setIsPassWordVisible(true);
    else
      showToast({
        message: Data.msg,
        severity: 'error',
      });
  };

  return (
    <Grid>
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <div
          style={{
            fontSize: 12,
            marginTop: 16,
            fontWeight: 600,
            color: '#363636',
            opacity: 1,
            display: 'flex',
          }}
        >
          Enter OTP :
        </div>
        <div style={{ marginTop: 16 }}>
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={6}
            // disabled={formikProps.isSubmitting}
            inputStyle={{
              border: '1px solid',
              borderRadius: '8px',
              width: '34px',
              height: '34px',
              fontSize: '18px',
              color: 'black',
              fontWeight: '400',
              caretColor: 'blue',
            }}
            separator={<span style={{ width: '8px' }}></span>}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          {/* <div>{errorDiv}</div> */}
          <Button
            variant="contained"
            onClick={
              otpSubmitHandler
              //setIsPassWordVisible(true)
            }
            style={{ width: '20%' }}
            //  disabled={formikProps.isSubmitting}
            color="primary"
          >
            Submit OTP
          </Button>
        </div>
      </div>
    </Grid>
  );
}
