export function getInteger(value) {
    try {
        if(value==null)
            return 0;
            
        return parseInt(value.toString().replace(",", "").replace(",", ""))
    }
    catch {
        return 0;
    }
}
