const StateList = [
  { code: "1", name: "Andaman and Nicobar Islands" },
  { code: "2", name: "Andhra Pradesh" },
  { code: "3", name: "Arunachal Pradesh" },
  { code: "4", name: "Assam" },
  { code: "5", name: "Bihar" },
  { code: "6", name: "Chandigarh" },
  { code: "7", name: "Dadra & Nagar Haveli" },
  { code: "8", name: "Daman & Diu" },
  { code: "9", name: "Delhi" },
  { code: "10", name: "Goa" },
  { code: "11", name: "Gujarat" },
  { code: "12", name: "Haryana " },
  { code: "13", name: "Himachal Pradesh" },
  { code: "14", name: "Jammu & Kashmir" },
  { code: "15", name: "Karnataka" },
  { code: "16", name: "Kerala" },
  { code: "17", name: "Lakhswadeep" },
  { code: "18", name: "Madhya Pradesh" },
  { code: "19", name: "Maharashtra" },
  { code: "20", name: "Manipur" },
  { code: "21", name: "Meghalaya" },
  { code: "22", name: "Mizoram" },
  { code: "23", name: "Nagaland" },
  { code: "24", name: "Orissa" },
  { code: "25", name: "Pondicherry" },
  { code: "26", name: "Punjab" },
  { code: "27", name: "Rajasthan" },
  { code: "28", name: "Sikkim" },
  { code: "29", name: "Tamilnadu" },
  { code: "30", name: "Tripura" },
  { code: "31", name: "Uttar Pradesh" },
  { code: "32", name: "West Bengal" },
  { code: "33", name: "Chhatisgarh" },
  { code: "34", name: "Uttarakhand" },
  { code: "35", name: "Jharkhand" },
  { code: "36", name: "Telangana" },
  { code: "37", name: "Ladakh" },
  { code: "99", name: "State outside India" },
];
export default StateList;
